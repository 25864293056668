import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CCardHeader } from "@coreui/react-pro";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
  const { title, label, chartBarData } = props || {};

  const [isAllDataZero, setIsAllDataZero] = useState(false);

  const checkData = (datas) => {
    let notAllDataZero = datas.some((data) => data !== 0);
    setIsAllDataZero(!notAllDataZero);
  };

  useEffect(() => {
    if (
      chartBarData &&
      chartBarData.data &&
      Array.isArray(chartBarData.data) &&
      chartBarData.data.length > 0
    ) {
      checkData(chartBarData.data);
    }
  }, [chartBarData]);

  return (
    <CCol xs={6}>
      <CCard className="mb-4">
        <CCardHeader>{title}</CCardHeader>
        <CCardBody>
          <Bar
            data={{
              labels: chartBarData.labels,
              datasets: [
                {
                  label,
                  // data: [65, 59, 80, 81, 56, 12, 39],
                  data: chartBarData.data,
                  backgroundColor: "#BB96D7",
                  hoverBackgroundColor: "#0155FF",
                },
              ],
            }}
            options={{
              responsive: true, // Makes the chart responsive to window resizing
              aspectRatio: 1,  // this would be a 1:1 aspect ratio
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true, // Enable tooltips
                  displayColors: false,
                },
              },
              scales: {
                x: {
                  beginAtZero: true, // Ensures the x-axis starts at zero
                  grid: {
                    display: false,
                    drawTicks: false,
                  },
                  ticks: {
                    font: {
                      size: 20, // Set font size for x-axis
                      weight: 500,
                    },
                  },
                },
                y: {
                  beginAtZero: true, // Ensures the y-axis starts at zero
                  grid: {
                    display: false,
                    drawBorder: false,
                    drawTicks: false,
                  },
                  ticks: {
                    font: {
                      size: 20, // Set font size for x-axis
                      weight: 500,
                    },
                  },
                  suggestedMin: isAllDataZero ? 0 : null,
                  suggestedMax: isAllDataZero ? 50 : null,
                },
              },
            }}
          />
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default BarChart;
