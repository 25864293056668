import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// reducer
import {
  SET_IS_INTERNAL,
  SET_USER_DETAIL,
  SET_USER_ROLE,
} from "../../store/actions";

// Cookies
import Cookies from "js-cookie";

// Apis
import {
  checkUserSession,
  removeUserOtpToken,
} from "../../apis/authentications";

const LoginGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("ut");
  const userOtpToken = Cookies.get("uto");

  const handleGetUsersProfile = async () => {
    try {
      const result = await checkUserSession();
      if (result && result.data) {
        const { data: { id, is_new_user, role } = {} } = result || {};
        dispatch(SET_USER_DETAIL(result.data));
        // Set user role
        dispatch(SET_USER_ROLE(role?.name));

        if (role && role.id && +role.id !== 3) {
          dispatch(SET_IS_INTERNAL(true));
          if (+role.id === 1 || +role.id === 2) {
            navigate("/internal/dashboard");
          }
          if (+role.id === 4) {
            if (is_new_user) {
              navigate(`/internal/mentors/${id}`);
            } else {
              navigate("/internal/bookings");
            }
          }
        } else {
          dispatch(SET_IS_INTERNAL(false));
          if (is_new_user) {
            navigate("/profile");
          } else {
            navigate("/");
          }
        }
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.log(err, "error line 60 LoginGuard.js <<");
      navigate("/login");
    }
  };

  const handleRemoveUserOtpToken = async () => {
    try {
      await removeUserOtpToken();
    } catch (err) {
      console.log(err, "error line 69 Loginguard.js <<");
    }
  };

  useEffect(() => {
    if (token) {
      handleGetUsersProfile();
    } else {
      navigate("/login");
    }
  }, [token]);

  useEffect(() => {
    if (userOtpToken) {
      handleRemoveUserOtpToken();
    }
  }, [userOtpToken]);

  if (!token) {
    navigate("/login");
  } else {
    return children;
  }
};

export default LoginGuard;
