import Axios from "../../helpers/axios";

export const getAllMentors = () => {
  return Axios.get("/users/all/mentors");
};

export const getAllTimeMentorSchedules = (params) => {
  return Axios.get(
    `/mentors/get-schedules-all-time/topics`,
    params ? { params } : null
  );
};
