import React from "react";
import { Views } from "react-big-calendar";
import moment from "moment";
import AgendaEvent from "./Agenda/AgendaEvent";

const WebinarCalendarEvent = ({
  event,
  title,
  currentView,
  toggleWebinarDetails,
}) => {
  return (
    <div
      onClick={() => toggleWebinarDetails(event)}
      style={{
        fontSize: currentView === Views.DAY ? 16 : 12,
        height: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "no-wrap",
      }}
    >
      {currentView === Views.MONTH ? (
        <AgendaEvent
          event={event}
          title={title}
          toggleWebinarDetails={toggleWebinarDetails}
        />
      ) : (
        //   <div>test</div>
        //   <>
        //     {!event.allDay &&
        //       (currentView === Views.MONTH ? (
        //         moment(event.start).format("HH:mm")
        //       ) : (
        //         <>
        //           {moment(event.start).format("HH:mm")} -{" "}
        //           {moment(event.end).format("HH:mm")}
        //         </>
        //       ))}{" "}
        //     <span
        //       style={{
        //         fontWeight: "bold",
        //         margin: "0 0 5px 0",
        //       }}
        //     >
        //       {event.title}
        //     </span>
        //   </>
        <>
          <span
            style={{
              fontWeight: "bold",
              margin: "0 0 5px 0",
            }}
          >
            {event.title}{" "}
          </span>
          {!event.allDay &&
            (currentView === Views.MONTH ? (
              moment(event.start).format("HH:mm")
            ) : (
              <>
                {moment(event.start).format("HH:mm")} -{" "}
                {moment(event.end).format("HH:mm")}
              </>
            ))}
        </>
      )}
    </div>
  );
};

export default WebinarCalendarEvent;
