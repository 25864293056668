import React from "react";
import Select from "react-select";

const InputDropdown = (props) => {
  const {
    options,
    styles,
    state,
    setter,
    functionSetter,
    value,
    placeholder,
    disabled,
  } = props;

  return (
    <Select
      value={value}
      placeholder={placeholder}
      onChange={(value) => functionSetter(value, state, setter)}
      options={options}
      isDisabled={disabled}
      styles={{
        control: (provided, state) => ({
          ...provided,
          ...styles,
        }),
      }}
    />
  );
};

export default InputDropdown;
