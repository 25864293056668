import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

// images & icons
import add_new_mentor_icon from "../assets/icons/add_new_mentor_icon.png";
import filter_icon from "../assets/icons/filter_icon.svg";

// Apis
import { getAllMentors } from "../apis/clientApis/mentors";
import { getAllTopics } from "../apis/topics";
// import { changeMentorStatus } from "../apis/internalApis/mentors";

// helpers
import haveSameValue from "../helpers/have-same-value";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import FilterModal from "../components/FilterModal";

// sweetalert
import Swal from "sweetalert2";

const InternalMentors = () => {
  const navigate = useNavigate();

  const filterKeyInitialState = { topic: [] };

  const userDetail = useSelector((state) => state.userDetail);
  const userRole = useSelector((state) => state.userRole);
  const searchKey = useSelector((state) => state.searchKey);

  const [isLoading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [mentors, setMentors] = useState([]);

  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);
  const [filterKey, setFilterKey] = useState(filterKeyInitialState);

  const initialDataState =
    mentors && mentors.length > 0
      ? mentors
          .map(
            ({
              id,
              first_name,
              last_name,
              phone_number,
              mentor_status,
              createdAt,
              mentor_id,
              email,
              topics,
            }) => {
              const mentor_name = first_name + " " + last_name;
              const date = new Date(createdAt).toLocaleString();
              return {
                id,
                mentor_id,
                mentor_name,
                email_address: email,
                phone_number,
                mentor_status,
                registered_date: date,
                topics,
              };
            }
          )
          .sort(function (a, b) {
            return new Date(b.registered_date) - new Date(a.registered_date);
          })
      : [];

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const handleFetchMentors = async () => {
    setLoading(true);
    const result = await getAllMentors();
    if (result && result.data) {
      let arrMentors = result.data;
      if (userRole === "Mentor") {
        arrMentors = arrMentors.filter(
          (mentor) => userDetail.email === mentor.email
        );
      }
      setMentors(arrMentors);
    }
    setLoading(false);
  };

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
    setLoading(false);
  };

  // Change router to view mentor details
  function handleViewDetails(d) {
    navigate(`/internal/mentors/${d["id"]}`);
  }

  // Change route to Add mentor
  function handleAddMentor() {
    navigate(`/internal/mentors/add`);
  }

  // Pagination - 1
  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  const handleFormatFirstFetchPaginatedData = (mentors, searchKey) => {
    let initialDataState = mentors
      .map(
        ({
          id,
          first_name,
          last_name,
          phone_number,
          mentor_status,
          createdAt,
          mentor_id,
          email,
        }) => {
          const mentor_name = first_name + " " + last_name;
          const date = new Date(createdAt).toLocaleString();
          return {
            id,
            mentor_id,
            mentor_name,
            email_address: email,
            phone_number,
            mentor_status,
            registered_date: date,
          };
        }
      )
      .sort(function (a, b) {
        return new Date(b.registered_date) - new Date(a.registered_date);
      });
    if (searchKey) {
      initialDataState = initialDataState.filter((data) => {
        const { mentor_id, mentor_name, email_address, phone_number } =
          data || {};
        let lowercaseSearchkey = searchKey.toLowerCase();
        let mentorId = mentor_id.toLowerCase();
        let mentorName = mentor_name.toLowerCase();
        let emailAddress = email_address.toLowerCase();
        let phoneNumber = phone_number.toLowerCase();
        if (
          mentorId.includes(lowercaseSearchkey) ||
          mentorName.includes(lowercaseSearchkey) ||
          emailAddress.includes(lowercaseSearchkey) ||
          phoneNumber.includes(lowercaseSearchkey)
        ) {
          return data;
        }
      });
    }
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  function handleOnFilter(type, name, value) {
    setFilterChanged(true);
    switch (type) {
      case "topic":
        if (value) {
          setFilterKey((prev) => ({ ...prev, topic: [...prev.topic, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            topic: prev.topic.filter((d) => d !== name),
          }));
        }
        break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
  }

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        const { topics } = d || {};
        let isValid = false;
        if (topics && Array.isArray(topics) && topics.length > 0) {
          let topicIds = topics.map((topic) => topic.id.toString());
          if (filterKey.topic.length > 0) {
            isValid = haveSameValue(filterKey.topic, topicIds);
          }
        }
        return isValid;
      });
      setData(
        filteredData.map(
          ({
            id,
            phone_number,
            mentor_status,
            mentor_id,
            email_address,
            mentor_name,
            registered_date,
          }) => {
            return {
              id,
              mentor_id,
              mentor_name,
              email_address,
              phone_number,
              mentor_status,
              registered_date,
            };
          }
        )
      );
    } else {
      setData(
        initialDataState.map(
          ({
            id,
            phone_number,
            mentor_status,
            mentor_id,
            email_address,
            mentor_name,
            registered_date,
          }) => {
            return {
              id,
              mentor_id,
              mentor_name,
              email_address,
              phone_number,
              mentor_status,
              registered_date,
            };
          }
        )
      );
    }
    setFilterChanged(false);
  }

  const handleOnToggleMentorStatus = async (data) => {
    const { id, mentor_status } = data || {};
    // const result = await changeMentorStatus(id, { mentor_status });
    // if (result && result.data && result.data.success && result.data.message) {
    //   Swal.fire({
    //     customClass: {
    //       popup: "mentor-popup",
    //     },
    //     imageUrl:
    //       "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
    //     position: "top-end",
    //     text: result.data.message,
    //     width: "20em",
    //     heightAuto: "0.5em",
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    //   handleFetchMentors();
    //   handleFormatFirstFetchPaginatedData(mentors);
    // }
  };

  useEffect(() => {
    handleFetchMentors();
    handleFetchTopics();
  }, []);

  useEffect(() => {
    if (mentors && Array.isArray(mentors) && mentors.length > 0) {
      handleFormatFirstFetchPaginatedData(mentors);
    }
  }, [mentors]);

  // Pagination - 2
  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFormatFirstFetchPaginatedData(mentors);
    } else {
      handleFormatFirstFetchPaginatedData(mentors, searchKey);
    }
  };

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <OuterContainer>
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        <InnerContainer>
          <MentorTableHeader>
            <ButtonContainer>
              <FilterSortButton onClick={handleOnClickFilterButton}>
                <img
                  src={filter_icon}
                  alt="filter_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Filter
              </FilterSortButton>
            </ButtonContainer>
            <AddMentorContainer>
              <AddMentorButton onClick={handleAddMentor}>
                <AddMentorText>Add new mentor</AddMentorText>
                <AddMentorImage
                  src={add_new_mentor_icon}
                  alt="add_new_mentor_icon"
                />
              </AddMentorButton>
            </AddMentorContainer>
          </MentorTableHeader>
          <FilterModal
            show={isFilterModalOpened.toString()}
            handleClose={() => {
              setFilterModalOpened(false);
              setFilterChanged(false);
            }}
            topics={topics}
            filterKey={filterKey}
            handleOnFilter={handleOnFilter}
            isFilterChanged={isFilterChanged}
            filterTopic={true}
          />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            itemOffset={itemOffset + 1}
            endOffset={itemOffset + perPage}
            totalItems={initialDataState.length}
            hide={data.length <= perPage}
          >
            <TableInternalComponent
              table_data={paginatedData}
              topics={topics}
              table_name="Mentors"
              onClickRow={handleViewDetails}
              handleOnToggle={handleOnToggleMentorStatus}
            />
          </Pagination>
        </InnerContainer>
      </OuterContainer>
    </div>
  );
};

export default InternalMentors;

const OuterContainer = styled.div`
  padding-left: 290px;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
  text-align: left;
`;

const AddMentorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AddMentorButton = styled.button`
  display: flex;
  background: #e5e5e5;
  border-radius: 3px;
  border: transparent;
  padding: 0.5em 1em;
  transition: 0.2s;
  &:hover {
    border: 1px solid #00bb99;
  }
`;

const AddMentorText = styled.span`
  margin-right: 0.5em;
`;

const AddMentorImage = styled.img`
  max-width: 25px;
`;

const MentorTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;
