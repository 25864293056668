import React from "react";
import styled from "styled-components";

function SortModal({ show, handleClose, handleOnSort, sortKey, user_detail }) {
  return (
    <>
      <Modal show={show} user_detail={user_detail}>
        <ButtonGroup>
          <Button
            checked={sortKey.newest}
            color="#AAAAAA"
            onClick={() => {
              handleOnSort("newest");
            }}
          >
            Newest
          </Button>
          <Button
            checked={sortKey.oldest}
            color="#00BB99"
            onClick={(e) => {
              handleOnSort("oldest");
            }}
          >
            Oldest
          </Button>
        </ButtonGroup>
      </Modal>
      <ModalOverlay show={show} onClick={handleClose}></ModalOverlay>
    </>
  );
}

export default SortModal;

const Modal = styled.div`
  position: absolute;
  background-color: #fff;
  width: 120px;
  min-height: 70px;
  border: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "1px solid #2c65f7"
      : "1px solid #00bb99"};
  border-radius: 10px;
  margin-top: 10px;
  display: ${({ show }) => (show === "true" ? "inline-block" : "none")};
  padding: 24px 20px;
  z-index: 10000;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  transition: all 150ms ease;
`;

const ModalOverlay = styled.div`
  display: ${({ show }) => (show === "true" ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: #0f172a;
  z-index: 9999;
  opacity: ${({ show }) => (show === "true" ? "0.5" : "0")};
  transition: all 150ms ease;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  color: black;
  font-size: 12px;
  font-weight: ${({ checked }) => (checked ? "700" : "400")};
  padding: 4px 0;
  margin-top: -4px;
  user-select: none;
  :hover {
    filter: brightness(0.8);
  }
`;
