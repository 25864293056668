export const createCityOptions = (cities) => {
  const newCities = cities.map((city) => {
    const objCity = {
      label: city,
      value: city,
    };
    return objCity;
  });
  if (newCities && Array.isArray(newCities) && newCities.length > 0) {
    return newCities;
  }
};
