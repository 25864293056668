import Axios from "../helpers/axios";

export const registerUser = (body) => {
  return Axios.post(`/users/register`, body);
};

export const verifyRegisteredUser = (body) => {
  return Axios.post(`/users/verify`, body);
};

export const loginUser = (body) => {
  return Axios.post("/users/login", body);
};

export const getOtpCountdown = () => {
  return Axios.post("/users/otp/countdown");
};

export const resendOtp = () => {
  return Axios.post("/users/otp/resend");
};

export const verifyOtp = (body) => {
  return Axios.post("/users/otp/verify", body);
};

export const checkUserSession = () => {
  return Axios.post("/users/check-session");
};

export const logoutUser = () => {
  return Axios.post("/users/signout");
};

export const updateUserDetail = (body, user_id) => {
  return Axios.post(`/users/update/details/${user_id}`, body);
};

export const forgetPasswordRequest = (body) => {
  return Axios.post(`/users/reset-password/send`, body);
};

export const verifyResetPasswordCode = (body) => {
  return Axios.post(`/users/reset-password/verify`, body);
};

export const resetPassword = (body) => {
  return Axios.post(`/users/reset-password/reset`, body);
};

export const updatePasswordFromProfile = (body) => {
  return Axios.post(`/users/update/password`, body);
};

export const updateUserImageUrl = (body, user_id) => {
  return Axios.put(`/users/update/image_url/${user_id}`, body);
};

export const deleteUser = (user_id) => {
  return Axios.delete(`/users/delete/${user_id}`);
};

export const updateEmailFromProfile = (body) => {
  return Axios.post(`/users/update/email`, body);
};

export const removeUserOtpToken = () => {
  return Axios.get("/users/remove/otp/token");
};
