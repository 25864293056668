import React, { useState } from "react";
import styled from "styled-components";

// images & icons
import eye_icon from "../assets/icons/eye_icon.svg";
import empty_session_1_icon from "../assets/icons/empty_session_1_icon.svg";
import deleteIcon from "../assets/icons/deleteIcon.png";

// utils
import { dateFormat, humanize } from "../utils/common";

// components
import FeedbackModalComponent from "./FeedbackModalComponent";
import StatusComponent from "./StatusComponent";
import Checkbox from "./input/Checkbox";
import Toggler from "./input/Toggler";

// Styles
import "../styles/tableComponent.css";

// helpers
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

function TableInternalComponent({
  table_data,
  topics,
  onClickRow,
  setTableID,
  is_editable,
  is_deleteable,
  onClickEdit,
  onClickDelete,
  table_name,
  handleOnToggle,
  handleCheckboxChange,
  handleOpenModalApproval,
}) {
  const data =
    table_name === "Users" || table_name === "Webinars"
      ? table_data
      : table_data && Array.isArray(table_data) && table_data.length > 0
      ? table_data.map((d) => {
          topics.map((topic) => {
            d.topic_id === topic.id && (d.topic_id = topic.title);
          });
          delete d.user_id;
          delete d.createdAt;
          return d;
        })
      : [];

  const tableHead =
    table_data &&
    Array.isArray(table_data) &&
    table_data.length > 0 &&
    Object.keys(table_data[0]).map((key) => {
      let data = [];

      switch (key) {
        case "booking_id":
          data = "Booking ID";
          break;
        case "topic_id":
          data = "Description";
          break;
        case "booking_created":
          data = "Booking created";
          break;
        case "scheduled_date":
          data = "Session date/time";
          break;
        case "feedback":
          data = "Feedback";
          break;
        case "assignedMentor":
          data = "Mentor Name";
          break;
        case "mentor_status":
          data = "Current Status";
          break;
        case "mentor_id":
          data = "Mentor ID";
          break;
        case "course_id":
          data = "Course ID";
          break;
        case "course_name":
          data = "Course name";
          break;
        case "course_type":
          data = "Course type";
          break;
        case "course_length":
          data = "Course length";
          break;
        case "total_enrollment":
          data = "Total enrollment";
          break;
        case "username":
          data = "User name";
          break;
        case "roleName":
          data = "Role";
          break;
        case "is_verified":
          data = "Verified";
          break;
        case "last_sign_in":
          data = "Last sign in";
          break;
        case "created_on":
          data = "Created on";
          break;
        case "id":
          data = "";
          break;
        default:
          data = key;
          break;
      }
      return humanize(data);
    });

  const [dataForFeedback, setDataForFeedback] = useState({});
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  function handleOnClickFeedback(key, value, d) {
    setFeedbackModalOpen(true);
    setDataForFeedback(d);
  }

  function handleCloseFeedbackModal() {
    setFeedbackModalOpen(false);
  }

  function handleOnClickCompleteYourData(booking_id) {}

  function handleAllCheckboxChange(row, value, isAll) {
    setChecked(!checked);
    handleCheckboxChange(row, value, isAll);
  }

  function formatAMPM(date) {
    let hours = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const tableHeadData = [
    "Booking ID",
    "Description",
    "Session date/time",
    "Duration",
    "User Name",
    "Status",
  ];

  // useEffect(() => {
  //    data.map((data, index) => {
  //       Object.entries(data).map(([key, value], index) => {
  //          // console.log(key, value)
  //       })
  //    })
  // }, [data])

  return (
    <div>
      <Table table_data={data} table_name={table_name}>
        <TableHead>
          <TableRow>
            {table_name &&
            // (table_name.toLowerCase() === "courses" ||
            //   table_name.toLowerCase() ===
            //     "studentaddedcoursetotheircourselist")
            table_name.toLowerCase() === "webinardetail" ? (
              <TableHeadData>
                <Checkbox
                  checked={checked}
                  onClick={() =>
                    handleCheckboxChange
                      ? handleAllCheckboxChange(null, !checked, true)
                      : null
                  }
                />
              </TableHeadData>
            ) : null}
            {/* hide sementara karna belum ada functionallity nya */}
            {tableHead
              ? tableHead.map((head, index) => {
                  if (
                    (table_name &&
                      table_name.toLowerCase() === "courses" &&
                      head.toLowerCase() === "updatedat") ||
                    (table_name &&
                      table_name.toLowerCase() === "webinardetail" &&
                      head.toLowerCase() === "checked") ||
                    (table_name &&
                      table_name.toLowerCase() === "internalbooking" &&
                      head.toLowerCase() === "isopenapproval")
                  ) {
                    return null;
                  } else {
                    return <TableHeadData key={index}>{head}</TableHeadData>;
                  }
                })
              : tableHeadData.map((head, index) => (
                  <TableHeadData key={index}>{head}</TableHeadData>
                ))}
          </TableRow>
        </TableHead>
        {data && Array.isArray(data) && data.length > 0 && (
          <TableBody table_name={table_name}>
            {data?.map((d, index) => {
              return (
                <TrTable
                  key={index}
                  type="body"
                  role={d && d.roleName}
                  table_name={table_name}
                  className={
                    table_name && table_name === "Users" && !d.roleName
                      ? "tableComponentTrTagUserUnassginedRole"
                      : "tableComponentTrTag"
                  }
                >
                  {/* <TableHeadData>
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    onClick={handleCheckboxChange}
                  />
                </TableHeadData> */}
                  {/* hide sementara karna belum ada functionallity nya */}
                  {table_name &&
                  // (table_name.toLowerCase() === "courses" ||
                  //   table_name.toLowerCase() ===
                  //     "studentaddedcoursetotheircourselist")
                  table_name === "webinarDetail" ? (
                    <TableHeadData>
                      <Checkbox
                        checked={d["checked"]}
                        onClick={() =>
                          handleCheckboxChange
                            ? handleCheckboxChange(
                                d,
                                !d["checked"],
                                false,
                                setChecked
                              )
                            : null
                        }
                      />
                    </TableHeadData>
                  ) : null}
                  {Object.entries(d).map(([key, value], ind) => {
                    return (
                      <TableRowData head={key} key={ind}>
                        <DataValueContainer head={key} table_value={value}>
                          {value || value === false ? ( // BUG 02062022 - Can't return value with result false (boolean)
                            key === "id" ||
                            key === "isOpenApproval" ? null : key ===
                              "booking_id" ? (
                              <DataValue
                                onClick={() => onClickRow(d, key, value)}
                              >
                                {value}
                              </DataValue>
                            ) : key === "topic_id" ||
                              key === "title" ||
                              key === "course_name" ||
                              key === "course_type" ? (
                              <DataValue
                                onClick={() => onClickRow(d, key, value)}
                              >
                                {capitalizeFirstLetter(`${value}`)}
                              </DataValue>
                            ) : key === "scheduled_date" ? (
                              <ColumnContainer
                                onClick={() => onClickRow(d, key, value)}
                              >
                                <DataValue>{dateFormat(value)}</DataValue>
                                {/* <DataValue>{formatAMPM(value)}</DataValue> */}
                              </ColumnContainer>
                            ) : key === "assignedMentor" &&
                              d.assignedMentor === "Not available" &&
                              d.status !== "Declined" &&
                              d.status !== "Cancelled" ? (
                              <StatusComponent
                                status={"Awaiting Mentor Assignment"}
                                borderRadius="40px"
                                fontSize="14px"
                                handleOnClickCompleteYourData={
                                  handleOnClickCompleteYourData
                                }
                                onClickStatusRow={() =>
                                  setTableID(d, key, value)
                                }
                                handleOpenModalApproval={() =>
                                  handleOpenModalApproval(d)
                                }
                                isOpenModalApproval={d.isOpenApproval}
                                disableClick
                                bgColor={"#FFF2DE"}
                              />
                            ) : key === "assignedMentor" &&
                              d.assignedMentor === "Not available" &&
                              (d.status === "Declined" ||
                                d.status === "Cancelled") ? (
                              <DataValue
                                onClick={() => onClickRow(d, key, value)}
                              >
                                {value}
                              </DataValue>
                            ) : key === "duration" ? (
                              <DataValue
                                onClick={() => onClickRow(d, key, value)}
                              >
                                {value}
                              </DataValue>
                            ) : key === "feedback" ? (
                              <div
                                onClick={() =>
                                  handleOnClickFeedback(key, value, d)
                                }
                                style={{ display: "flex", gap: "0.25rem" }}
                              >
                                <img
                                  src={eye_icon}
                                  alt="eye_icon"
                                  width={15}
                                  height={15}
                                />
                                <div>View</div>
                              </div>
                            ) : key === "booking_created" ? (
                              <ColumnContainer
                                onClick={() => onClickRow(d, key, value)}
                              >
                                <DataValue>{dateFormat(value)}</DataValue>
                                {/* <DataValue>{formatAMPM(value)}</DataValue> */}
                              </ColumnContainer>
                            ) : key === "status" ? (
                              <StatusComponent
                                status={value}
                                borderRadius="40px"
                                fontSize="14px"
                                handleOnClickCompleteYourData={
                                  handleOnClickCompleteYourData
                                }
                                onClickStatusRow={() =>
                                  setTableID(d, key, value)
                                }
                                handleOpenModalApproval={() =>
                                  handleOpenModalApproval(d)
                                }
                                isOpenModalApproval={d.isOpenApproval}
                              />
                            ) : key === "mentor_status" ||
                              key === "is_active" ||
                              key === "is_verified" ? (
                              <Toggler
                                isChecked={value}
                                handleOnToggle={() => handleOnToggle(d)}
                                id={d.id}
                                table_name={table_name}
                              />
                            ) : key === "registered_date" ||
                              key === "created_on" ||
                              key === "last_sign_in" ||
                              key === "webinar_schedule_date" ? (
                              <ColumnContainer>
                                <DataValue>{dateFormat(value)}</DataValue>
                                {/* <DataValue>{formatAMPM(value)}</DataValue> */}
                              </ColumnContainer>
                            ) : (key.toLowerCase() === "updatedat" &&
                                table_name &&
                                table_name.toLowerCase() === "courses") ||
                              (key.toLowerCase() === "checked" &&
                                table_name &&
                                table_name.toLowerCase() ===
                                  "webinardetail") ? null : (
                              <DataValue
                                onClick={() => onClickRow(d, key, value)}
                                table_name={table_name}
                              >
                                {value}
                              </DataValue>
                            )
                          ) : (
                            <DataValue
                              onClick={() => onClickRow(d, key, value)}
                            >
                              {table_name &&
                              table_name ===
                                "studentAddedCourseToTheirCourseList" &&
                              key === "last_activity"
                                ? "Not yet watched"
                                : "Not available"}
                            </DataValue>
                          )}
                        </DataValueContainer>
                      </TableRowData>
                    );
                  })}
                  <TableRowData table_name={table_name}>
                    <DataValueContainer
                      is_deleteable={is_deleteable}
                      is_editable={is_editable}
                    >
                      {table_name === "Mentors" ||
                        table_name === "Topics" ||
                        (table_name === "Webinars" && (
                          <DataViewDetails onClick={() => onClickRow(d)}>
                            View Details
                          </DataViewDetails>
                        ))}
                      {is_editable && (
                        <DataViewDetails onClick={() => onClickEdit(d)}>
                          Edit
                        </DataViewDetails>
                      )}
                      {is_deleteable && (
                        <img
                          src={deleteIcon}
                          onClick={() => onClickDelete(d)}
                          style={{ width: "15px", height: "auto" }}
                        />
                      )}
                    </DataValueContainer>
                  </TableRowData>
                </TrTable>
              );
            })}
          </TableBody>
        )}
      </Table>
      {data && data.length <= 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <img
            src={empty_session_1_icon}
            alt="empty_session_1_icon"
            style={{
              objectFit: "scale-down",
              width: "300px",
              height: "auto",
            }}
          />
          <h4 style={{ fontSize: "22px" }}>There is no data.</h4>
        </div>
      )}
      <FeedbackModalComponent
        show={isFeedbackModalOpen}
        handleClose={handleCloseFeedbackModal}
        booking_id={dataForFeedback["booking_id"]}
        mentor_name={dataForFeedback["assignedMentor"]}
        topic={dataForFeedback["topic_id"]}
        body={[dataForFeedback["feedback"]]}
      />
    </div>
  );
}

export default TableInternalComponent;

const Table = styled.table`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  // height: ${({ table_data }) => (table_data.length < 6 ? "75vh" : "")};
  text-align: left;
`;
const TableHead = styled.thead`
  color: #00bb99;
  border-bottom: 1px solid #00bb99;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  :hover {
    background-color: ${({ type }) => type === "body" && "#EEEEEE"};
    font-weight: 700;
  }
  cursor: pointer;
`;

const TableHeadData = styled.th`
  padding: 1.25rem 0rem;
  vertical-align: top;
`;

const TableRowData = styled.td`
  vertical-align: top;
  padding: 0.75rem 0.25rem;
  // padding: 0.75rem 0;
`;

const DataValue = styled.div`
  transition: 0.3s ease;
  padding: 0.5em 0;
  width: ${({ table_name }) =>
    table_name && table_name === "Topics" ? "15em" : "10em"};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DataValueContainer = styled.div`
  display: flex;
  justify-content: ${({ head }) => head === "Status" && "center"};
  align-items: center;
  padding: ${({ head }) => head === "Status" && "0.5rem 0.75rem"};
  border-radius: ${({ head }) => head === "Status" && "50px"};
  color: ${({ head }) => head === "Status" && "white"};
  cursor: ${({ table_value }) => table_value === "View" && "pointer"};
  gap: ${({ table_value, is_editable, is_deleteable }) =>
    table_value === "View"
      ? "9px"
      : is_editable || is_deleteable
      ? "30px"
      : ""};
  &:hover ${DataValue} {
    font-weight: 700;
  }
`;

const DataViewDetails = styled.div`
  transition: 0.3s ease;
  padding: 0.5em 0;
  color: #4285f4;
  transition: 0.2s;
  &:hover {
    font-weight: 700;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TrTable = styled.tr``;
