import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import styled from "styled-components";

// react-otp-input
import OtpInput from "react-otp-input";

// Images & Icon
import locked_icon from "../assets/icons/locked_icon.png";

// Components
import LoginRegisterContainer from "../components/LoginRegisterContainer";

// Styles
import "../styles/loginVerification.css";

// cookies
import Cookies from "js-cookie";

// apis
import { getOtpCountdown, resendOtp, verifyOtp } from "../apis/authentications";

// reducers
import { SET_USER_DETAIL } from "../store/actions";

const LoginVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState("00:00");
  const [otpError, setOtpError] = useState(false);
  const [isCountdownLoading, setCountdownLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userLoginData, setUserLoginData] = useState(false);
  const [otpAllowResendTime, setOtpAllowResendTime] = useState("");

  const innerWidth = window.innerWidth;

  const handleChangeOTP = (otp) => {
    setOtp(otp);
    setOtpError(false);
  };

  const usertokenOtp = Cookies.get("uto");

  async function handleSubmit() {
    if (otp) {
      setIsSubmitting(true);
      try {
        setLoading(true);
        const result = await verifyOtp({ otp });
        if (result && result.data) {
          setLoading(false);
          Cookies.remove("uto");
          const {
            data: { user: { role_id, is_new_user } = {}, user } = {},
          } = result || {};
          dispatch(SET_USER_DETAIL(user));
          setSuccess("OTP has been verified successfully");
          if (role_id && +role_id !== 3) {
            navigate("/internal/bookings");
          } else {
            if (is_new_user) {
              navigate("/profile");
            } else {
              navigate("/");
            }
          }
        }
      } catch (error) {
        console.log(error, "error line 76 loginVerification.js <<");
        setLoading(false);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message.toString());
        }
        setOtpError(true);
        setIsSubmitting(false);
      }
    } else {
      setError(`Please enter OTP`);
      setOtpError(true);
    }
  }

  const startTimer = (endTime, setCountdown) => {
    if (endTime >= 0) {
      let timer =
        endTime > new Date().getTime()
          ? (endTime - new Date().getTime()) / 1000
          : 0;
      let minutes, seconds;
      if (Boolean(timer)) {
        setInterval(function () {
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          setCountdown(minutes + ":" + seconds);
          if (--timer < 0) {
            timer = 0;
            setCountdown(minutes + ":" + seconds);
          }
        }, 1000);
      }
    }
  };

  async function handleResend() {
    setCountdownLoading(true);
    setLoading(true);
    if (new Date().getTime() > new Date(otpAllowResendTime).getTime()) {
      try {
        const result = await resendOtp();
        if (
          result &&
          result.data &&
          result.data.otp_allow_resend_date &&
          userLoginData
        ) {
          setSuccess("OTP has been sent to your email");
          startTimer(
            new Date(result.data.otp_allow_resend_date).getTime(),
            setCountdown
          );
        }
        setCountdownLoading(false);
        setLoading(false);
      } catch (error) {
        console.log(error, "error line 141 loginVerification.js <<");
        setSuccess("");
        setError(error.response.data.message.toString());
        setCountdownLoading(false);
        setLoading(false);
      }
    } else {
      setCountdownLoading(false);
      setLoading(false);
      setError(`Please wait ${countdown} minutes to resend OTP`);
    }
  }

  const getUserLoginData = async () => {
    setLoading(true);
    const user = await getOtpCountdown();

    if (user) {
      setUserLoginData(user.data);
      setOtpAllowResendTime(user.data.user_otp.otp_allow_resend_date);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (usertokenOtp && !userLoginData) {
      getUserLoginData();
    }
  }, [usertokenOtp]);

  useEffect(() => {
    if (
      userLoginData &&
      otpAllowResendTime &&
      new Date(otpAllowResendTime).getTime() >= 0
    ) {
      startTimer(new Date(otpAllowResendTime).getTime(), setCountdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginData, otpAllowResendTime]);

  return (
    <LoginRegisterContainer
      isLoading={isLoading}
      error={error}
      success={success}
    >
      <div className="loginVerificationMainContainer">
        <div className="loginVerificationLockedIconContainer">
          <img src={locked_icon} alt="css_logo" />
        </div>
        <div
          style={{
            fontSize: innerWidth && innerWidth < 768 ? "30px" : "36px",
            fontWeight: "bold",
            color: "#00BB99",
          }}
        >
          Enter your OTP Code
        </div>
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              fontSize: innerWidth && innerWidth < 768 ? "19px" : "22px",
              fontWeight: "bold",
            }}
          >
            We have sent a verification code to your email
          </div>
          {userLoginData ? (
            <div
              style={{
                fontSize: innerWidth && innerWidth < 768 ? "16px" : "18px",
                margin: "0",
              }}
            >
              Please input code sent to{` ${userLoginData.email}`}
            </div>
          ) : null}
        </div>
        <div className="loginVerificationOtpContainer">
          <OtpInput
            value={otp}
            onChange={handleChangeOTP}
            numInputs={6}
            otpError={otpError}
            inputStyle={{
              margin: innerWidth && innerWidth < 768 ? "0 5px" : "0 8px",
              width: innerWidth && innerWidth < 768 ? "30px" : "36px",
              height: innerWidth && innerWidth < 768 ? "40px" : "50px",
              border: otpError ? "2px solid #EB4335" : "2px solid #00BB99",
              borderRadius: "10px",
              fontWeight: "bold",
            }}
          />
          <p
            style={{
              fontSize: innerWidth && innerWidth < 768 ? "13px" : "14px",
              margin: "0",
              opacity: isCountdownLoading ? "0.5" : "1",
            }}
          >
            {countdown}
          </p>
        </div>
        {otpError && (
          <p
            style={{
              color: "#EB4335",
              fontSize: innerWidth && innerWidth < 768 ? "13px" : "14px",
              margin: "0",
              opacity: isCountdownLoading ? "0.5" : "1",
            }}
          >
            Verification failed. Please try again.
          </p>
        )}
        {otpAllowResendTime ? (
          <Body2>
            Haven&apos;t received OTP code? Click
            <SpanButton
              onClick={handleResend}
              disabled={
                new Date().getTime() <= new Date(otpAllowResendTime).getTime()
              }
            >
              {" "}
              Resend
            </SpanButton>
          </Body2>
        ) : null}
        <button
          onClick={handleSubmit}
          disabled={!otp || isSubmitting}
          style={{
            fontSize: innerWidth && innerWidth < 768 ? "16px" : "18px",
            fontWeight: "bold",
            color: "white",
            background: "#00BB99",
            border: "#00BB99",
            borderRadius: "40px",
            width: "40%",
            filter: "drop-shadow(0 25px 25px rgb(0 0 0 / 0.15))",
            minWidth: "160px",
            minHeight: "40px",
            padding: "0.5rem 1rem",
            [":hover"]: {
              background: "#00BB99",
              cursor: !otp || isSubmitting ? "pointer" : "",
              opacity: !otp || isSubmitting ? "0.5" : "0.8",
            },
            opacity: !otp || isSubmitting ? "0.5" : "1",
          }}
        >
          Submit
        </button>
      </div>
    </LoginRegisterContainer>
  );
};

export default LoginVerification;

const Body2 = styled.p`
  font-size: 14px;
  margin: 0;
  opacity: ${({ loading }) => (loading ? "0.5" : "1")};
`;

const SpanButton = styled.span`
  font-size: 14px;
  margin: 0;
  color: ${({ disabled }) => (disabled ? "#AAAAAA" : "#00BB99")};
  font-weight: bold;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => !disabled && "pointer"};
  :hover {
    cursor: ${({ disabled }) => !disabled && "pointer"};
    text-decoration: ${({ disabled }) => !disabled && "underline"};
  }
`;
