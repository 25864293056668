import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import capitalizeFirstLetter from "../../helpers/capitalize_first_letter";

function ItemSlider({ childrens, name }) {
  const data = childrens.map(({ id, title, topic_ccs_id }) => {
    return {
      id: topic_ccs_id ? topic_ccs_id : "CCS-T008",
      title: title,
    };
  });

  return (
    <Slider
      // dots={true}
      infinite={true}
      speed={500}
      slidesToShow={childrens.length >= 3 ? 3 : childrens.length}
      slidesToScroll={childrens.length >= 3 ? 3 : childrens.length}
    >
      {data.map((value, index) => {
        return (
          name === "topics" && (
            <TopicsContainer key={index}>
              <TopicsItemContainer>
                <TopicsItem>
                  <TopicsId>{value.id}</TopicsId>
                </TopicsItem>
                <TopicsItem>
                  <strong>{capitalizeFirstLetter(value.title)}</strong>
                </TopicsItem>
              </TopicsItemContainer>
            </TopicsContainer>
          )
        );
      })}
    </Slider>
  );
}

export default ItemSlider;

const TopicsContainer = styled.div``;

const TopicsItemContainer = styled.div`
  max-width: 400px;
  min-height: 120px;
  border: 1px solid #00bb99;
  border-radius: 10px;
  margin: 1em 1em 1em 0;
`;

const TopicsItem = styled.div`
  margin: 1em;
`;

const TopicsId = styled.span`
  font-weight: 700;
  color: #00bb99;
`;
