import Axios from "../helpers/axios";

export const getAllIndustries = () => {
  return Axios.get("/industries");
};

export const getAllCountries = () => {
  return Axios.get("/countries");
};

export const getAllOccupations = () => {
  return Axios.get("/occupations");
};

export const getAllDegrees = () => {
  return Axios.get("/degrees");
};

export const getStatesByCountryName = (body) => {
  return Axios.post("/countries/api/states/by-country-name", body);
};

export const getCityByCountryNameAndStateName = (body) => {
  return Axios.post(
    "/countries/api/states/by-country-name-and-state-name",
    body
  );
};
