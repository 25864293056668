import { useState } from "react";
import styled from "styled-components";
import add_tag_icon from "../../assets/icons/add_tag_icon.png";
import capitalizeFirstLetter from "../../helpers/capitalize_first_letter";

function TagsInput({
  // eg: const [tags, handleTags] = useState([])
  tags,
  handleTags,
  disabled,
  showInputBorder = true,
  showSubmit = true,
  hidePlusBtn = false,
}) {
  const [insertedTag, setInsertedTag] = useState(null);

  // Remove tags by index
  function removeTag(index) {
    handleTags(
      tags.filter((el, i) => {
        return i !== index;
      })
    );
  }

  // Handle input tag
  function handleChange(e) {
    setInsertedTag(e.target.value.toLowerCase());
  }

  // Handle submit tag
  function handleSubmit(e) {
    // Tags must not empty
    const pattern = /^(?!\s*$).+/;
    if (!pattern.test(insertedTag)) return;
    handleTags([...tags, insertedTag]);

    // Reset input
    if (insertedTag !== "") {
      setInsertedTag("");
      document.getElementById("tags").value = "";
    }
  }

  function handleKeyDown(e) {
    // If user did not press enter key, return
    if (e.key !== "Enter") return;
    e.preventDefault();

    // Get the value of the input
    const value = e.target.value.toLowerCase();

    // If the value is empty, return
    if (!value.trim()) return;

    // Add the value to the tags array
    handleTags([...tags, value]);

    // Clear the input
    e.target.value = "";
  }

  return (
    <TagsContainer disabled={disabled} showInputBorder={showInputBorder}>
      {tags.map((tag, index) => (
        <TagsItem key={index}>
          <span>{capitalizeFirstLetter(tag)}</span>
          {!disabled && (
            <TagsItemClose onClick={() => removeTag(index)}>
              &times;
            </TagsItemClose>
          )}
        </TagsItem>
      ))}
      <div>
        {showSubmit && (
          <Input
            id="tags"
            type="text"
            placeholder="Add tag"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
        {!hidePlusBtn ? (
          <ImageContainer onClick={handleSubmit}>
            <img
              src={add_tag_icon}
              alt="add_tag_icon"
              style={{
                width: "10px",
                height: "10px",
                objectFit: "scale-down",
              }}
            />
          </ImageContainer>
        ) : null}
      </div>
    </TagsContainer>
  );
}

export default TagsInput;

const TagsContainer = styled.div`
  border: ${({ showInputBorder }) =>
    showInputBorder ? "2px solid #00BB99" : "none"};
  padding: 0.7em 0.2em;
  border-radius: 10px;
  min-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  background: ${({ disabled }) =>
    disabled ? "rgba(0, 187, 153, 0.1)" : "none"};
`;

const TagsItem = styled.div`
  display: inline-block;
  padding: 0.3em 0.75em;
  border-radius: 40px;
  background: ${({ disabled }) => (disabled ? "#6ad7c3" : "#00bb99")};
  border: 1px solid #00bb99;
  color: #ffffff;
`;

const TagsItemClose = styled.span`
  height: 20px;
  width: 20px;
  color: #ffffff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
`;

const Input = styled.input`
  flex-grow: 1;
  background: #fafafa;
  padding: 0.3em 2em 0.3em 0.75em;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  max-width: 180px;
`;

const ImageContainer = styled.span`
  margin-left: -25px;
  cursor: pointer;
  background: transparent;
`;
