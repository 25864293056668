import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import styled from "styled-components";

// Apis
import { getUserDetail, updateUserRole } from "../apis/internalApis/users";
import { getAllRoles } from "../apis/internalApis/roles";
import {
  getAllCountries,
  getCityByCountryNameAndStateName,
  getStatesByCountryName,
} from "../apis/general";
import {
  logoutUser,
  updateUserDetail,
  updateUserImageUrl,
  updateEmailFromProfile,
  updatePasswordFromProfile,
} from "../apis/authentications";

// Helpers
import { validatePhoneNumber } from "../helpers/validate-phone-number";
import { createCountriesOptions } from "../helpers/get-country-options";
import { createStateOptions } from "../helpers/get-state-options";
import { createCityOptions } from "../helpers/get-city-options";

// Lib
import fontSize from "../lib/styling/font_size";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// sweetalert
import Swal from "sweetalert2";

// Images & Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";
import ccs_mentor_icon from "../assets/icons/ccs_mentor_icon.png";
import email_password_update_success from "../assets/icons/email_password_update_success.png";

// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// reducers
import { SET_USER_DETAIL } from "../store/actions";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import RadioButton from "../components/input/RadioButton";
import ReactDatePicker from "../components/input/DatePicker";
import ModalComponent from "../components/ModalComponent";
import InputText from "../components/input/InputText";
import InputDropdown from "../components/input/InputDropdown";
import GreenButton from "../components/button/GreenButton";
import Tooltip from "../components/tooltip/Tooltip";
import AlertComponent from "../components/AlertComponent";

const InternalUserDetail = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLoginDetail = useSelector((state) => state.userDetail);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    clearErrors,
    setError,
  } = useForm({ mode: "all" });

  const formOptions = {
    first_name: {
      required: "First name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    last_name: {
      required: "Last name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    // email: {
    //   required: "Email address is required",
    //   pattern: {
    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    //     message: "email format is invalid, please try again..",
    //   },
    // },
    gender: {
      required: "Gender is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    address: {
      required: "Address is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    postal_code: {
      required: "Postal Code is required",
      pattern: {
        value: /^\d+$/,
        message: "Invalid postal code",
      },
      maxLength: {
        value: 5,
        message: "Can't be longer than 5 characters",
      },
    },
    // city: {
    //   required: "City is required",
    //   maxLength: {
    //     value: 255,
    //     message: "Can't be longer than 255 characters",
    //   },
    // },
    // province: {
    //   required: "State / Province is required",
    //   maxLength: {
    //     value: 255,
    //     message: "Can't be longer than 255 characters",
    //   },
    // },
    place_of_birth: {
      required: "Place of birth is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    company_name: {
      required: "Company name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    date_of_birth: {
      required: "Date of birth is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
  };

  const [isLoading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isModal, setModal] = useState(false);
  const [isModalCancel, setIsModalCancel] = useState(false);

  const [roleOptions, setRoleOptions] = useState([]);

  const [assignedRole, setAssignedRole] = useState(null);
  const [provinceValue, setProvinceValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);

  // phone number state
  const [isOpenPhoneDropdown, setIsOpenPhoneDropdown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    icon: null,
    phone_code: "",
    country_id: "",
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [success, setSuccess] = useState("");
  const [error, setErrorMsg] = useState("");

  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [isChangingEmail, setChangingEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");
  const [isChangingPassword, setChangingPassword] = useState(false);

  const handleFetchUserDetail = async (user_id) => {
    setLoading(true);
    const result = await getUserDetail(user_id);
    if (result && result.data) {
      const userDetailData = result.data;

      const { user_details } = userDetailData || {};

      let countryIcon;
      let number;
      let phone_code;
      let country_id;
      let label;
      let code;
      let id;

      if (user_details && user_details.country && user_details.country.id) {
        id = user_details.country.id;
      }
      if (user_details && user_details.country && user_details.country.code) {
        code = user_details.country.code;
        countryIcon = icon[`cif${user_details.country.code}`];
      }
      if (user_details && user_details.phone_number) {
        number = user_details.phone_number;
      }
      if (
        user_details &&
        user_details.country &&
        user_details.country.phone_code
      ) {
        phone_code = user_details.country.phone_code;
      }
      if (user_details && user_details.country && user_details.country.id) {
        country_id = user_details.country.id;
      }
      if (user_details && user_details.country && user_details.country.name) {
        label = user_details.country.name;
        await handleGetStateOptions(user_details.country.name);
        if (user_details && user_details.province) {
          setProvinceValue({
            value: user_details.province,
            label: user_details.province,
          });
          await handleGetCityOptions(
            user_details.country.name,
            user_details.province
          );
          if (user_details && user_details.city) {
            setCityValue({
              value: user_details.city,
              label: user_details.city,
            });
          }
        }
      }

      for (const key in user_details) {
        setValue(key, user_details[key]);
      }

      if (userDetailData && userDetailData.email) {
        setValue("email_address", userDetailData.email);
      }

      setPhoneNumber({
        ...phoneNumber,
        icon: countryIcon,
        phone_code,
        number,
        country_id,
        value: { label, value: code, phone_code, id },
      });
      setAssignedRole(
        (userDetailData && userDetailData.role && userDetailData.role.name) ||
          ""
      );
      setUserDetail(userDetailData);

      fetchAllCountries();
    }
    setLoading(false);
  };

  const handleGetStateOptions = async (countryName) => {
    const result = await getStatesByCountryName({ country: countryName });
    if (result && result.data) {
      const { data: { states } = {} } = result.data || {};
      if (states && Array.isArray(states) && states.length > 0) {
        setStateOptions(createStateOptions(states));
      }
    }
  };

  const handleGetCityOptions = async (countryName, stateName) => {
    const result = await getCityByCountryNameAndStateName({
      country: countryName,
      state: stateName,
    });
    if (result && result.data) {
      const { data } = result.data || {};
      if (data && Array.isArray(data) && data.length > 0) {
        setCityOptions(createCityOptions(data));
      }
    }
  };

  // Handle change user role
  async function handleChangeUserRole() {
    setLoading(true);
    try {
      let finalRoleValue = roleOptions.filter(
        (roleOpt) => roleOpt.name === assignedRole
      );
      const response = await updateUserRole(user_id, {
        role_id: finalRoleValue[0].id,
        new_role_assigned: finalRoleValue[0].name,
      });
      if (response && response.data) {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "User role has been changed",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
        setAssignedRole(finalRoleValue[0].name);
        if (finalRoleValue[0].name === "Mentor") {
          navigate(`/internal/mentors/${response.data.updatedUser.id}`);
        }
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Internal server error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err, "error line 352 internalUserDetail.js <<");
    } finally {
      setLoading(false);
      setModal(false);
    }
  }

  // Handle dropdown change
  function handleChangeDropdown(value, key, setter) {
    if (key && key === "assignedRole") {
      setAssignedRole(value);
      setModal(true);
    } else {
      if (key && key === "province") {
        setCityValue(null);
        const countryName = phoneNumber.value.label;
        const provinceName = value.label;
        handleGetCityOptions(countryName, provinceName);
      }
      setter(value);
    }
  }

  // Handle secondary modal button (No)
  function handleCancel(e) {
    setAssignedRole(userDetail.role.name);
    setModal(false);
  }

  const handleFetchRoleOptions = async () => {
    const result = await getAllRoles();
    if (result && result.data) {
      let finalRoles = result.data;
      if (
        userLoginDetail &&
        userLoginDetail.role &&
        userLoginDetail.role.id &&
        +userLoginDetail.role.id === 2
      ) {
        finalRoles = finalRoles.filter((role) => +role.id !== 1);
      }
      setRoleOptions(finalRoles);
    }
  };

  const fetchAllCountries = async () => {
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data));
      }
    }
  };

  async function logout() {
    try {
      setLoading(true);
      const result = await logoutUser();
      if (result && result.data && result.data.success) {
        setLoading(false);
        dispatch(SET_USER_DETAIL(null));
        navigate("/login");
      }
    } catch (error) {
      console.log(error, "error line 417 internalUserDetail.js <<");
      setLoading(false);
      showAlert(error.response.data.message, "error");
    }
  }

  function handleUpdateEmail() {
    Swal.fire({
      title: "Please wait",
      text: "We are processing your request",
      allowOutsideClick: false,
      didOpen: () => {
        setLoading(true);
        updateEmailFromProfile({
          prev_email: userDetail?.email,
          new_email: newEmailAddress,
        })
          .then(() => {
            setLoading(false);
            Swal.fire({
              text: "You have successfully changed your email",
              imageUrl: email_password_update_success,
              imageHeight: 185,
              imageWidth: 255,
              showCloseButton: true,
            }).then(() => {
              logout();
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              showCloseButton: true,
            });
          });
      },
    });
  }

  function checkRepeatPassword() {
    if (password && repeatPassword && password === repeatPassword) {
      return true;
    } else {
      return false;
    }
  }

  function handleUpdatePassword() {
    if (checkRepeatPassword()) {
      Swal.fire({
        title: "Please wait",
        text: "We are processing your request",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          updatePasswordFromProfile({ password })
            .then(() => {
              Swal.fire({
                text: "You have successfully changed the password",
                imageUrl: email_password_update_success,
                imageHeight: 185,
                imageWidth: 255,
                showCloseButton: true,
              }).then(() => {
                setPassword("");
                setRepeatPassword("");
                setChangingPassword(false);
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
              });
            });
        },
      });
    } else {
      showAlert("Password did not match", "error");
    }
  }

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    clearErrors("phone_number");

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };

  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const {
      value: { code, phone_code, id },
      label = {},
    } = val || {};

    let countryIcon = "";
    if (code) {
      countryIcon = icon[`cif${code}`];
    }

    const countryName = label;
    setProvinceValue(null);
    setCityValue(null);
    setCityOptions([]);
    handleGetStateOptions(countryName);

    setterPhoneNumber({
      ...phoneNumberState,
      icon: countryIcon,
      phone_code: phone_code,
      country_id: id,
      value: { label, value: val.value },
    });
    clearErrors("country_id");
    setIsOpenPhoneDropdown(false);
  };

  function showAlert(text, type) {
    if (type === "success") {
      setErrorMsg("");
      setSuccess(text);
    } else {
      setSuccess("");
      setErrorMsg(text);
    }
  }

  const handleUpdateProfile = async (data) => {
    setLoading(true);
    try {
      if (
        phoneNumber &&
        phoneNumber.country_id &&
        phoneNumber.number &&
        phoneNumber.phone_code
      ) {
        let isValidPhoneNumber = validatePhoneNumber(phoneNumber.number);

        if (isValidPhoneNumber) {
          if (provinceValue && provinceValue.value) {
            if (cityValue && cityValue.value) {
              const finalData = {
                ...data,
                country_id: phoneNumber.country_id,
                phone_number: phoneNumber.number,
                province: provinceValue.value,
                city: cityValue.value,
              };

              const res = await updateUserDetail(finalData, user_id);
              if (res && res.data) {
                Swal.fire({
                  customClass: {
                    popup: "mentor-popup",
                  },
                  imageUrl:
                    "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
                  position: "top-end",
                  text: "Profile updated successfully",
                  width: "20em",
                  heightAuto: "0.5em",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setIsEditingProfile(false);
              }
            } else {
              setError("city", { message: "City is required" });
            }
          } else {
            setError("province", { message: "State / Province is required" });
          }
        } else {
          setError("phone_number", { message: "Invalid phone number" });
        }
      } else {
        if (!phoneNumber.country_id) {
          setError("country_id", { message: "Country is required" });
        }
        if (!phoneNumber.number) {
          setError("phone_number", { message: "Phone number is required" });
        }
      }
    } catch (error) {
      console.log(error, "error line 624 internalUserDetail.js <<");
      showAlert(error.response.data.message);
    }
    setLoading(false);
  };

  const handleError = (errors) => {};

  const handleOnClickImageProfile = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    const result = await updateUserImageUrl(formData, user_id);
    if (result && result.data) {
      let userDetails = {
        ...userDetail.user_details,
        image_url: result.data.image_url,
      };
      setUserDetail({ ...userDetail, user_details: userDetails });
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        icon: "success",
        position: "top-end",
        text: "User profile picture has been updated",
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    handleFetchRoleOptions();
  }, []);

  useEffect(() => {
    if (user_id) {
      handleFetchUserDetail(user_id);
    }
  }, [user_id]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <AlertComponent
        isShown={error ? true : false}
        variant="danger"
        text={error}
      />
      <AlertComponent
        isShown={success ? true : false}
        variant="success"
        text={success}
      />
      {isLoading ? (
        <SpinnerComponent
          isShown={isLoading}
          styles={{
            right: 0,
            left: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "290px",
          }}
        />
      ) : (
        <>
          {userDetail ? (
            <OuterContainer>
              <InnerContainer>
                <Row>
                  <BackNavigation onClick={() => navigate(-1)}>
                    <img
                      src={arrow_left_icon_dark}
                      alt="arrow_left_icon_dark"
                      height={15}
                      width={15}
                      style={{
                        objectFit: "scale-down",
                        objectPosition: "left bottom",
                      }}
                    />
                    {`    Back`}
                  </BackNavigation>
                </Row>
                <PageTitle>User details</PageTitle>
                <Row>
                  <Col>
                    <MentorProfileContainer>
                      <MentorProfilePicture
                        src={
                          userDetail &&
                          userDetail.user_details &&
                          userDetail.user_details.image_url
                            ? userDetail.user_details.image_url
                            : ccs_mentor_icon
                        }
                        alt="user profile picture"
                        onClick={() =>
                          document
                            .querySelector(`.input-field-user-image`)
                            .click()
                        }
                      />
                      <input
                        type="file"
                        accept="image/*"
                        className={`input-field-user-image`}
                        hidden
                        onChange={({ target: { files } }) =>
                          handleOnClickImageProfile(files)
                        }
                      />
                      <MentorIdentification>
                        {userDetail && userDetail.user_details ? (
                          <MentorName>
                            {userDetail.user_details.first_name}{" "}
                            {userDetail.user_details.last_name}
                          </MentorName>
                        ) : null}
                        <MentorIdTitle>User ID</MentorIdTitle>
                        <MentorId>{userDetail.user_ccs_id}</MentorId>
                      </MentorIdentification>
                    </MentorProfileContainer>
                  </Col>
                  {userLoginDetail &&
                  userLoginDetail.role &&
                  userLoginDetail.role.id &&
                  userDetail &&
                  userDetail.role &&
                  userDetail.role.id &&
                  ((+userLoginDetail.role.id === +userDetail.role.id &&
                    +userLoginDetail.id !== +userDetail.id) ||
                    (+userLoginDetail.role.id === 2 &&
                      +userDetail.role.id === 1)) ? null : (
                    <Col>
                      <InputContainer>
                        <InputLabel>Role/Position</InputLabel>
                        <CustomDropdown
                          value={assignedRole || ""}
                          placeholder="Choose one role/position"
                          options={roleOptions}
                          selectedKey="name"
                          action={(value) =>
                            handleChangeDropdown(value, "assignedRole")
                          }
                          inputWidth="250px"
                          itemWidth="250px"
                          disabled={userDetail && userDetail.role}
                        />
                      </InputContainer>
                    </Col>
                  )}
                </Row>
                <MentorInvisibleLine />
                {isEditingProfile ||
                (userLoginDetail &&
                  userLoginDetail.role &&
                  userLoginDetail.role.id &&
                  userDetail &&
                  userDetail.role &&
                  userDetail.role.id &&
                  ((+userLoginDetail.role.id === +userDetail.role.id &&
                    +userLoginDetail.id !== +userDetail.id) ||
                    (+userLoginDetail.role.id === 2 &&
                      +userDetail.role.id === 1))) ? null : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <GreenButton
                      action={() => setIsEditingProfile(true)}
                      width="130px"
                      height="30px"
                      textSize={fontSize.title3}
                      text="Edit Profile"
                      bgcolor="#00bb99"
                    />
                  </div>
                )}
                <form onSubmit={handleSubmit(handleUpdateProfile, handleError)}>
                  <Row>
                    <Col>
                      <FlexRow>
                        <InputContainer>
                          <InputLabel>Gender</InputLabel>
                          <Controller
                            control={control}
                            name="gender"
                            rules={formOptions.gender}
                            render={({ field: { onChange, value } }) => (
                              <RadioButton
                                name="gender"
                                data={["male", "female"]}
                                state={value}
                                handleChange={onChange}
                                isFilled={Boolean(getValues("gender"))}
                                disabled={!isEditingProfile}
                              />
                            )}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.gender && errors.gender.message}
                          </small>
                        </InputContainer>
                      </FlexRow>
                      <FlexRow>
                        <InputContainer>
                          <InputLabel>First Name</InputLabel>
                          <InputMentor
                            id="first_name"
                            name="first_name"
                            autoComplete="off"
                            type="text"
                            disabled={!isEditingProfile}
                            placeholder="Input first name here"
                            {...register("first_name", formOptions.first_name)}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.first_name && errors.first_name.message}
                          </small>
                        </InputContainer>
                        <InputContainer>
                          <InputLabel>Last Name</InputLabel>
                          <InputMentor
                            id="last_name"
                            name="last_name"
                            autoComplete="off"
                            type="text"
                            disabled={!isEditingProfile}
                            placeholder="Input last name here"
                            {...register("last_name", formOptions.last_name)}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.last_name && errors.last_name.message}
                          </small>
                        </InputContainer>
                      </FlexRow>
                      {/* <InputContainer>
                    <InputLabel>Email Address</InputLabel>
                    <InputMentor
                      disabled={!isEditingProfile}
                      placeholder="e.g: ccs@mail.com"
                      {...register("email", formOptions.email)}
                    />
                    <small
                      className="text-danger"
                      style={{
                        display: "flex",
                        textAlign: "start",
                        userSelect: "none",
                      }}
                    >
                      {errors?.email && errors.email.message}
                    </small>
                  </InputContainer> */}
                      <InputContainer>
                        <InputLabel>Phone Number</InputLabel>
                        <div className="inputPhoneBox">
                          <div
                            className="inputPhoneCode"
                            onClick={() =>
                              !!isEditingProfile
                                ? setIsOpenPhoneDropdown(!isOpenPhoneDropdown)
                                : null
                            }
                            style={{
                              border:
                                errors &&
                                errors.country_id &&
                                !phoneNumber.country_id
                                  ? "2px solid #EB4335"
                                  : !isEditingProfile
                                  ? "2px solid #9BDDCC"
                                  : "2px solid #00bb99",
                            }}
                          >
                            {phoneNumber && phoneNumber.icon ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CIcon icon={phoneNumber.icon} size="xxl" />
                              </div>
                            ) : (
                              "Country"
                            )}
                          </div>
                          <InputText
                            placeholder={"Fill in phone number"}
                            background={
                              errors && errors.phone_number
                                ? "#FFFFFF"
                                : !isEditingProfile
                                ? "#F5FBFA"
                                : "transparent"
                            }
                            name={"phoneNumberMyProfile"}
                            border={
                              errors && errors.phone_number
                                ? "2px solid #EB4335"
                                : !isEditingProfile
                                ? "2px solid #9BDDCC"
                                : "2px solid #00bb99"
                            }
                            disabled={!isEditingProfile}
                            functionSetter={handleOnChangePhoneNumber}
                            phoneNumberState={phoneNumber}
                            setterPhoneNumber={setPhoneNumber}
                            className={"userFormInputStyle"}
                            fontColor={
                              !isEditingProfile ? "#7f7f7f" : "#000000"
                            }
                            value={
                              phoneNumber &&
                              phoneNumber.number &&
                              phoneNumber.phone_code
                                ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                                : phoneNumber &&
                                  phoneNumber.phone_code &&
                                  !phoneNumber.number
                                ? `(${phoneNumber.phone_code}) `
                                : phoneNumber &&
                                  phoneNumber.number &&
                                  !phoneNumber.phone_code
                                ? phoneNumber.number
                                : ""
                            }
                          />
                        </div>
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          {!phoneNumber.country_id &&
                          !phoneNumber.number &&
                          errors?.country_id &&
                          errors.country_id.message &&
                          errors?.phone_number &&
                          errors.phone_number.message
                            ? "Country and Phone Number is required"
                            : (!phoneNumber.country_id &&
                                errors?.country_id &&
                                errors.country_id.message) ||
                              (errors?.phone_number &&
                                errors.phone_number.message)}
                        </small>
                        <div
                          className="inputContainer"
                          style={{ marginTop: "0" }}
                        >
                          <div>
                            {isOpenPhoneDropdown ? (
                              <InputDropdown
                                options={countryOptions}
                                styles={{
                                  marginTop: "1em",
                                  border: "2px solid #00BB99",
                                  background: "transparent",
                                  borderRadius: "10px",
                                  height: "48px",
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                state={phoneNumber}
                                setter={setPhoneNumber}
                                functionSetter={handleChangePhoneNumberDropdown}
                              />
                            ) : null}
                          </div>
                        </div>
                      </InputContainer>
                      <FlexRow>
                        <InputContainer>
                          <InputLabel>Country</InputLabel>
                          <InputDropdown
                            options={countryOptions}
                            styles={{
                              border:
                                errors &&
                                errors.country_id &&
                                !phoneNumber.country_id
                                  ? "2px solid #EB4335"
                                  : !isEditingProfile
                                  ? "2px solid #9BDDCC"
                                  : "2px solid #00bb99",
                              background:
                                errors && errors.country_id
                                  ? "#FFFFFF"
                                  : !isEditingProfile
                                  ? "#F5FBFA"
                                  : "transparent",
                              borderRadius: "10px",
                              height: "48px",
                              cursor: "pointer",
                              width: "100%",
                            }}
                            disabled={!isEditingProfile}
                            state={phoneNumber}
                            setter={setPhoneNumber}
                            functionSetter={handleChangePhoneNumberDropdown}
                            value={
                              phoneNumber &&
                              phoneNumber.value &&
                              phoneNumber.value.label
                                ? phoneNumber && phoneNumber.value
                                : null
                            }
                          />
                          <small
                            className="text-danger"
                            style={{
                              userSelect: "none",
                              display: "flex",
                              textAlign: "start",
                            }}
                          >
                            {!phoneNumber.country_id &&
                              errors?.country_id &&
                              errors.country_id.message}
                          </small>
                        </InputContainer>
                        <InputContainer>
                          <InputLabel>State / Province</InputLabel>
                          <InputDropdown
                            options={stateOptions}
                            styles={{
                              border:
                                errors && errors.province && !provinceValue
                                  ? "2px solid #EB4335"
                                  : !isEditingProfile
                                  ? "2px solid #9BDDCC"
                                  : "2px solid #00bb99",
                              background:
                                errors && errors.province
                                  ? "#FFFFFF"
                                  : !isEditingProfile
                                  ? "#F5FBFA"
                                  : "transparent",
                              borderRadius: "10px",
                              height: "48px",
                              cursor: "pointer",
                              width: "100%",
                            }}
                            disabled={!isEditingProfile}
                            setter={setProvinceValue}
                            state={"province"}
                            functionSetter={handleChangeDropdown}
                            value={provinceValue}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.province && errors.province.message}
                          </small>
                        </InputContainer>
                      </FlexRow>
                      <FlexRow>
                        <InputContainer>
                          <InputLabel>City</InputLabel>
                          <InputDropdown
                            options={cityOptions}
                            styles={{
                              border:
                                errors && errors.city && !cityValue
                                  ? "2px solid #EB4335"
                                  : !isEditingProfile
                                  ? "2px solid #9BDDCC"
                                  : "2px solid #00bb99",
                              background:
                                errors && errors.city
                                  ? "#FFFFFF"
                                  : !isEditingProfile
                                  ? "#F5FBFA"
                                  : "transparent",
                              borderRadius: "10px",
                              height: "48px",
                              cursor: "pointer",
                              width: "100%",
                            }}
                            disabled={!isEditingProfile}
                            setter={setCityValue}
                            state={"city"}
                            functionSetter={handleChangeDropdown}
                            value={cityValue}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.city && errors.city.message}
                          </small>
                        </InputContainer>
                        <InputContainer>
                          <InputLabel>Postal Code</InputLabel>
                          <InputMentor
                            id="postal_code"
                            name="postal_code"
                            autoComplete="off"
                            type="text"
                            disabled={!isEditingProfile}
                            placeholder="Input your postal code here"
                            {...register(
                              "postal_code",
                              formOptions.postal_code
                            )}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.postal_code && errors.postal_code.message}
                          </small>
                        </InputContainer>
                      </FlexRow>
                      {userDetail.id === userLoginDetail.id ? (
                        <>
                          <InputContainer>
                            <div className="userFormInputGroup">
                              <Form.Group>
                                <div className="userFormInputGroupFlexContainer">
                                  <div
                                    className="userFormInputLabel"
                                    style={{
                                      fontSize: fontSize.title2,
                                    }}
                                  >
                                    {isChangingEmail
                                      ? "New email address"
                                      : "Email"}
                                  </div>
                                  {isChangingEmail ? (
                                    <CancelText
                                      onClick={() => setChangingEmail(false)}
                                    >
                                      Cancel
                                    </CancelText>
                                  ) : (
                                    <InputAction
                                      onClick={() =>
                                        isEditingProfile
                                          ? setChangingEmail(true)
                                          : null
                                      }
                                      disabled={!isEditingProfile}
                                    >
                                      Change
                                    </InputAction>
                                  )}
                                </div>
                                {isChangingEmail ? (
                                  <div className="userFormInputGroupFlexContainer">
                                    <input
                                      style={{
                                        // width: smallScreen ? "100%" : "60%",
                                        width: "60%",
                                        background: "#FFFFFF",
                                        border: "2px solid #00BB99",
                                      }}
                                      value={newEmailAddress}
                                      onChange={(e) =>
                                        setNewEmailAddress(e.target.value)
                                      }
                                      type="email"
                                      placeholder="e.g: john@example.com"
                                      className="userFormInputStyle"
                                    />
                                    {newEmailAddress !== "" ? (
                                      <GreenButton
                                        action={handleUpdateEmail}
                                        width="130px"
                                        height="30px"
                                        textSize={fontSize.title3}
                                        text="Update"
                                        bgcolor={
                                          newEmailAddress
                                            ? "#00BB99"
                                            : "#AAAAAA"
                                        }
                                      />
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="userFormInputGroupFlexContainer">
                                    <div
                                      style={{
                                        fontSize: fontSize.title3,
                                        fontWeight: "400",
                                      }}
                                    >
                                      Your email address is
                                      <b> {userDetail?.email}</b>
                                    </div>
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <div className="userFormInputGroup">
                              <Form.Group>
                                <div className="userFormInputGroupFlexContainer">
                                  <div
                                    className="userFormInputLabel"
                                    style={{
                                      fontSize: fontSize.title2,
                                    }}
                                  >
                                    {isChangingPassword ? (
                                      <>
                                        <span>New password</span>
                                        <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
                                      </>
                                    ) : (
                                      <span>Password</span>
                                    )}
                                  </div>
                                  {isChangingPassword ? (
                                    <CancelText
                                      onClick={() => setChangingPassword(false)}
                                    >
                                      Cancel
                                    </CancelText>
                                  ) : (
                                    <InputAction
                                      onClick={() =>
                                        isEditingProfile
                                          ? setChangingPassword(true)
                                          : null
                                      }
                                      disabled={!isEditingProfile}
                                    >
                                      Change
                                    </InputAction>
                                  )}
                                </div>
                                {isChangingPassword ? (
                                  <div>
                                    <div
                                      className="userFormInputGroupFlexContainer"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          height: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <input
                                          style={{
                                            width: "100%",
                                            background: "#FFFFFF",
                                            border: "2px solid #00BB99",
                                          }}
                                          value={password}
                                          onChange={(e) =>
                                            setPassword(e.target.value)
                                          }
                                          type={passwordType}
                                          placeholder="New password"
                                          className="userFormInputStyle"
                                        />

                                        <input
                                          autoComplete="off"
                                          style={{ display: "none" }}
                                          id="show1"
                                          type="checkbox"
                                          checked={passwordType === "text"}
                                          onChange={() =>
                                            setPasswordType((prev) =>
                                              prev === "password"
                                                ? "text"
                                                : "password"
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor="show1"
                                          style={{
                                            position: "absolute",
                                            right: 0,
                                            margin: "0 12px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {passwordType === "password" ? (
                                            <AiFillEye size={25} />
                                          ) : (
                                            <AiFillEyeInvisible size={25} />
                                          )}
                                        </label>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          marginTop:
                                            !repeatPassword ||
                                            checkRepeatPassword()
                                              ? null
                                              : "1.3em",
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <input
                                            style={{
                                              width: "100%",
                                              background: "#FFFFFF",
                                              border: "2px solid #00BB99",
                                            }}
                                            value={repeatPassword}
                                            onChange={(e) =>
                                              setRepeatPassword(e.target.value)
                                            }
                                            type={repeatPasswordType}
                                            placeholder="Repeat password"
                                            className="userFormInputStyle"
                                          />
                                          <input
                                            autoComplete="off"
                                            style={{ display: "none" }}
                                            id="show2"
                                            type="checkbox"
                                            checked={
                                              repeatPasswordType === "text"
                                            }
                                            onChange={() =>
                                              setRepeatPasswordType((prev) =>
                                                prev === "password"
                                                  ? "text"
                                                  : "password"
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor="show2"
                                            style={{
                                              position: "absolute",
                                              right: 0,
                                              margin: "0 12px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {repeatPasswordType ===
                                            "password" ? (
                                              <AiFillEye size={25} />
                                            ) : (
                                              <AiFillEyeInvisible size={25} />
                                            )}
                                          </label>
                                        </div>
                                        <small
                                          className="text-danger"
                                          style={{
                                            userSelect: "none",
                                            display: "flex",
                                            textAlign: "left",
                                          }}
                                        >
                                          {!repeatPassword ||
                                          checkRepeatPassword()
                                            ? null
                                            : "Password not match"}
                                        </small>
                                      </div>
                                    </div>
                                    <GreenButton
                                      action={handleUpdatePassword}
                                      width="130px"
                                      height="30px"
                                      textSize={fontSize.title3}
                                      text="Update"
                                      bgcolor={
                                        password && repeatPassword
                                          ? "#00BB99"
                                          : "#AAAAAA"
                                      }
                                    />
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>
                          </InputContainer>
                        </>
                      ) : null}
                    </Col>
                    <Col>
                      {userDetail.id !== userLoginDetail.id && (
                        <InputContainer>
                          <InputLabel>Email address</InputLabel>
                          <InputMentor
                            id="email_address"
                            name="email_address"
                            placeholder="Please fill in your email address"
                            {...register(
                              "email_address",
                              formOptions.email_address
                            )}
                            disabled={true}
                          />
                        </InputContainer>
                      )}
                      <InputContainer>
                        <InputLabel>Address</InputLabel>
                        <TextArea
                          rows={5}
                          id="address"
                          name="address"
                          autoComplete="off"
                          error={errors?.address}
                          type="text"
                          placeholder="e.g: Sudirman street block 88 unit S"
                          isFilled={Boolean(getValues("address"))}
                          disabled={!isEditingProfile}
                          {...register("address", formOptions.address)}
                        />
                        <small
                          className="text-danger"
                          style={{
                            display: "flex",
                            textAlign: "start",
                            userSelect: "none",
                          }}
                        >
                          {errors?.address && errors.address.message}
                        </small>
                      </InputContainer>
                      <FlexRow style={{ marginTop: "-0.5em" }}>
                        <InputContainer>
                          <InputLabel>Place of Birth</InputLabel>
                          <InputMentor
                            id="place_of_birth"
                            name="place_of_birth"
                            autoComplete="off"
                            type="text"
                            disabled={!isEditingProfile}
                            placeholder="Input your place of birth here"
                            {...register(
                              "place_of_birth",
                              formOptions.place_of_birth
                            )}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.place_of_birth &&
                              errors.place_of_birth.message}
                          </small>
                        </InputContainer>
                        <InputContainer>
                          <InputLabel>Date of Birth</InputLabel>
                          <Controller
                            control={control}
                            name="date_of_birth"
                            rules={formOptions.date_of_birth}
                            render={({ field: { onChange, value } }) => (
                              <ReactDatePicker
                                disabled={!isEditingProfile}
                                date={value}
                                handleDate={onChange}
                                format="dd/MM/yyyy"
                                error={errors?.date_of_birth}
                              />
                            )}
                          />
                          <small
                            className="text-danger"
                            style={{
                              display: "flex",
                              textAlign: "start",
                              userSelect: "none",
                            }}
                          >
                            {errors?.date_of_birth &&
                              errors.date_of_birth.message}
                          </small>
                        </InputContainer>
                      </FlexRow>
                      <InputContainer>
                        <InputLabel>Company / Organization</InputLabel>
                        <InputMentor
                          id="company_name"
                          name="company_name"
                          autoComplete="off"
                          type="text"
                          disabled={!isEditingProfile}
                          placeholder="Input your company / organization here"
                          {...register(
                            "company_name",
                            formOptions.company_name
                          )}
                        />
                        <small
                          className="text-danger"
                          style={{
                            display: "flex",
                            textAlign: "start",
                            userSelect: "none",
                          }}
                        >
                          {errors?.company_name && errors.company_name.message}
                        </small>
                      </InputContainer>
                    </Col>
                  </Row>
                  <ModalComponent
                    show={isModal}
                    body={[
                      <p style={{ fontWeight: "700" }} key={0}>
                        Do you want to save changes?
                      </p>,
                    ]}
                    buttonPrimary="Yes"
                    buttonSecondary="No"
                    onPrimaryClick={() => handleChangeUserRole()}
                    onSecondaryClick={handleCancel}
                    handleClose={() => setModal(false)}
                  />
                  {isEditingProfile && (
                    <div
                      className="userFormBtnMainContainer"
                      style={{ marginTop: "2em", width: "97.5%" }}
                    >
                      <button
                        type="reset"
                        className="userFormBtnCancel"
                        onClick={() => setIsModalCancel(true)}
                      >
                        Cancel
                      </button>
                      <button
                        // disabled={isSubmitting}
                        type="submit"
                        className={
                          isSubmitting
                            ? "userFormBtnSaveDisabled"
                            : "userFormBtnSave"
                        }
                        style={{}}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </form>
                <ModalComponent
                  show={isModalCancel}
                  body={[
                    <p style={{ fontWeight: "700" }} key={0}>
                      Are you sure want to cancel?
                    </p>,
                  ]}
                  buttonPrimary="Yes"
                  buttonSecondary="No"
                  onPrimaryClick={() => {
                    clearErrors([
                      "phone_number",
                      "country_id",
                      "province",
                      "city",
                      "first_name",
                      "last_name",
                      "gender",
                      "address",
                      "postal_code",
                      "place_of_birth",
                      "company_name",
                      "date_of_birth",
                    ]);
                    handleFetchUserDetail(user_id);
                    setIsEditingProfile(false);
                    setIsModalCancel(false);
                  }}
                  onSecondaryClick={() => setIsModalCancel(false)}
                />
              </InnerContainer>
            </OuterContainer>
          ) : null}
        </>
      )}
    </div>
  );
};

export default InternalUserDetail;

const OuterContainer = styled.div`
  padding-left: 290px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const BackNavigation = styled.p`
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
  margin-bottom: 1em;
`;

const MentorProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`;

const MentorProfilePicture = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
`;

const MentorIdentification = styled.div`
  margin-left: 1em;
`;

const MentorName = styled.p`
  margin-bottom: 0.3em;
  font-weight: 700;
  font-size: 25px;
`;

const MentorIdTitle = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: #00bb99;
`;

const MentorId = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: 0px;
  padding: 2px;
  min-width: 900px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "normal"};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "none")};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "nowrap")};
`;

const InputContainer = styled.div`
  width: -webkit-fill-available; // Important
  margin-right: 2em;
  margin-top: 1em;
  flex-grow: 1; // Important
`;

const InputLabel = styled.p`
  font-weight: 700;
  margin-bottom: 0.3em;
`;

const InputMentor = styled.input`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
  &:disabled {
    opacity: 0.5;
    background: rgba(0, 187, 153, 0.1);
  }
`;

const TextArea = styled.textarea`
  background: ${({ disabled, user_detail }) =>
    disabled
      ? `${
          user_detail &&
          user_detail.role &&
          user_detail.role.id &&
          +user_detail.role.id === 3
            ? "#e7eeff"
            : "#F5FBFA"
        }`
      : "#FFFFFF"};
  width: 100%;
  color: ${({ disabled }) => (disabled ? "#7F7F7F" : "#000000")};
  border: ${({ disabled, user_detail }) =>
    disabled
      ? "2px solid #9BDDCC"
      : `2px solid ${
          user_detail &&
          user_detail.role &&
          user_detail.role.id &&
          +user_detail.role.id === 3
            ? "#2c65f7"
            : "#00BB99"
        }`};
  padding: 10px 15px;
  border-radius: 10px;
  height: 142px;
  &:focus {
    outline: none;
    border-width: 3px;
  }
`;

const CancelText = styled.div`
  font-size: ${fontSize.title4};
  text-align: center;
  color: #404040;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const InputAction = styled.div`
  font-size: ${fontSize.title4};
  color: ${({ disabled }) => (disabled ? "#aaaaaa" : "#00bb99")};
  font-weight: 500;
  color: ${({ disabled }) => (disabled ? "" : "pointer")};
  user-select: none;
  &:hover {
    text-decoration: ${({ disabled }) => (disabled ? "" : "underline")};
    font-weight: ${({ disabled }) => (disabled ? "" : "bold")};
  }
`;
