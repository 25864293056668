import React from "react";
import { Views } from "react-big-calendar";
import moment from "moment";
import "../../../styles/BigCalendar.css";

const AgendaEvent = ({
  event,
  title,
  toggleWebinarDetails,
  currentView,
  style,
  customContentStyle,
}) => {
  return (
    <div
      onClick={() => toggleWebinarDetails(event)}
      className="agenda-event"
      style={style}
    >
      {customContentStyle ? (
        <div style={customContentStyle}>
          <span style={{ fontWeight: "bold", margin: "0 0 5px 0" }}>
            {title}
          </span>
          {!event.allDay && (
            <>
              <br />
              {currentView === Views.MONTH ? (
                moment(event.start).format("HH:mm")
              ) : (
                <>
                  {moment(event.start).format("HH:mm")} -{" "}
                  {moment(event.end).format("HH:mm")}
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <span style={{ fontWeight: "bold", margin: "0 0 5px 0" }}>
            {title}
          </span>
          {!event.allDay && (
            <>
              <br />
              {currentView === Views.MONTH ? (
                moment(event.start).format("HH:mm")
              ) : (
                <>
                  {moment(event.start).format("HH:mm")} -{" "}
                  {moment(event.end).format("HH:mm")}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AgendaEvent;
