import React from "react";
import { Views } from "react-big-calendar";
import moment from "moment";

import DateLabel from "./DateLabel";
import ArrowLeft from "../../assets/icons/ArrowLeftIcon";
import ArrowRight from "../../assets/icons/ArrowRightIcon";

const WebinarCalendarToolbar = ({
  date,
  onView,
  onNavigate,
  currentView,
  setCurrentView,
}) => {
  const handleViewClick = (view) => {
    setCurrentView(view);
    onView(view);

    // if (view === Views.WEEK || view === Views.DAY) {
    //    const elements = document.getElementsByClassName("rbc-event");
    //    const firstElement = elements[0];
    //    firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
    // }
  };

  return (
    <div>
      <div className="toolbar">
        <div className="toolbar-left">
          <button onClick={() => onNavigate("PREV")}>
            <ArrowLeft />
          </button>
          <button onClick={() => onNavigate("NEXT")}>
            <ArrowRight />
          </button>
          <h2 className="date-label">
            {currentView === Views.DAY
              ? moment(date).format("DD MMMM, YYYY")
              : moment(date).format("MMMM, YYYY")}
          </h2>
        </div>

        <form className="toolbar-right">
          <input
            className="radio-custom"
            type="radio"
            id="agenda"
            name="view"
            value="agenda"
            style={{ display: "none" }}
          />
          <label
            onClick={() => handleViewClick(Views.AGENDA)}
            htmlFor="agenda"
            style={{
              cursor: "pointer",
              background:
                currentView === Views.AGENDA ? "#002B73" : "transparent",
              color: currentView === Views.AGENDA ? "white" : "black",
            }}
          >
            Weekly
          </label>
          <input
            className="radio-custom"
            type="radio"
            id="monthly"
            name="view"
            value="month"
            style={{ display: "none" }}
          />
          <label
            onClick={() => handleViewClick(Views.MONTH)}
            htmlFor="monthly"
            style={{
              cursor: "pointer",
              background:
                currentView === Views.MONTH ? "#002B73" : "transparent",
              color: currentView === Views.MONTH ? "white" : "black",
            }}
          >
            Monthly
          </label>
        </form>
      </div>
      {currentView === Views.DAY && <DateLabel date={date} />}
    </div>
  );
};

export default WebinarCalendarToolbar;
