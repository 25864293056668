export const createCountriesOptions = (countries) => {
  const newCountries = countries.map((country) => {
    const { name } = country;
    const objCountry = {
      label: name,
      value: country,
    };
    return objCountry;
  });
  if (newCountries && Array.isArray(newCountries) && newCountries.length > 0) {
    return newCountries;
  }
};
