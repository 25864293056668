import { useState, useEffect, useMemo } from "react";
import { Navigate } from "react-big-calendar";
import PropTypes from "prop-types";
import AgendaEvent from "./Agenda/AgendaEvent";
import WeeklyHeader from "./WeeklyHeader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import font_size from "../../lib/styling/font_size";
import { useMediaQuery } from "react-responsive";

function CustomAgendaView({
  date,
  localizer,
  events,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  const navigate = useNavigate();
  const currRange = useMemo(
    () => CustomAgendaView.range(date, { localizer }),
    [date, localizer]
  );

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [currentEvents, setCurrentEvents] = useState([]);

  const handleCheckInRange = (currRange, events) => {
    let finalCurrRange = currRange;
    finalCurrRange = finalCurrRange.map((date) => {
      return moment(date).format("DD MM YYYY");
    });
    let groupedDataObject = events;
    groupedDataObject = groupedDataObject
      .filter((event) =>
        finalCurrRange.includes(
          moment(new Date(event.webinar_schedule_date)).format("DD MM YYYY")
        )
      )
      .sort((a, b) => {
        return (
          new Date(a.webinar_schedule_date) - new Date(b.webinar_schedule_date)
        );
      })
      .reduce((acc, obj) => {
        const { webinar_schedule_date } = obj || {};
        let date = moment(webinar_schedule_date).format("DD MM YYYY");

        // Use the property you want to group by, e.g., 'category'
        const key = date;

        // If the key doesn't exist in the accumulator, create an empty array
        if (!acc[key]) {
          acc[key] = [];
        }

        // Push the object to the array of the respective key
        acc[key].push(obj);

        return acc;
      }, {});

    let tempId = 1;

    let groupedDataArray = Object.keys(groupedDataObject).map((key) => {
      let objExpected = {
        webinar_schedule_date:
          groupedDataObject[key][0]["webinar_schedule_date"],
        items: groupedDataObject[key],
        groupedDataArrayId: tempId,
      };
      tempId++;
      return objExpected;
    });
    groupedDataArray = groupedDataArray.map((data) => {
      const { items } = data || {};
      if (items && Array.isArray(items) && items.length > 5) {
        data = {
          ...data,
          isMoreThanFiveEvent: true,
          isShowMore: false,
        };
      }
      return data;
    });
    setCurrentEvents(groupedDataArray);
  };

  const toggleWebinarDetails = (event) => {
    navigate(`/webinars/${event.id}`);
  };

  const handleShowMoreEvent = (groupedDataArrayId) => {
    let finalEvents = currentEvents;
    finalEvents = finalEvents.map((event) => {
      if (groupedDataArrayId === event.groupedDataArrayId) {
        event = {
          ...event,
          isShowMore: !event.isShowMore,
        };
      }
      return event;
    });
    setCurrentEvents(finalEvents);
  };

  const handleStudentRegisterWebinar = async (webinar_id) => {
    navigate(`/webinars/${webinar_id}/register`);
  };

  useEffect(() => {
    if (currRange && Array.isArray(currRange) && currRange.length > 0) {
      handleCheckInRange(currRange, events);
    }
  }, [currRange]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          gap: "5em",
          marginBottom: "2em",
        }}
      >
        {/* <div
          style={{
            background: "red",
            display: "flex",
            width: "75%",
            justifyContent: "space-between",
          }}
        >
          {currRange.map((date, idx) => {
            return <WeeklyHeader key={idx} date={date} />;
          })}
        </div> */}
        {smallScreen || mediumScreen ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <WeeklyHeader date={currRange[0]} />
            <div style={{ fontSize: "70px" }}> - </div>
            <WeeklyHeader date={currRange[currRange.length - 1]} />
          </div>
        ) : (
          <>
            {currRange.map((date, idx) => {
              return <WeeklyHeader key={idx} date={date} />;
            })}
          </>
        )}
      </div>
      {currentEvents && currentEvents.length > 0 ? (
        currentEvents.map((event, idx) => {
          const {
            webinar_schedule_date,
            items,
            isMoreThanFiveEvent,
            isShowMore,
            groupedDataArrayId,
          } = event || {};
          return (
            <div key={idx} style={{ marginBottom: "3em" }}>
              <WeeklyHeader
                key={idx}
                date={new Date(webinar_schedule_date)}
                showMonth
              />
              <div style={{ width: "100%" }}>
                {items && Array.isArray(items) && items.length > 0
                  ? items.map((item, itemIdx) => {
                      if (isMoreThanFiveEvent) {
                        if (!isShowMore) {
                          if (itemIdx + 1 <= 5) {
                            return (
                              <div
                                key={itemIdx}
                                className="eachWebinarCustomAgendaViewContainer"
                              >
                                <AgendaEvent
                                  event={item}
                                  title={item.title}
                                  toggleWebinarDetails={toggleWebinarDetails}
                                  style={{
                                    marginTop: "0.8em",
                                    width: smallScreen ? "60%" : "80%",
                                    textAlign: "left",
                                  }}
                                  customContentStyle={{ marginLeft: "0.5em" }}
                                />
                                <div
                                  className="registerToWebinarContainer"
                                  style={{
                                    fontSize: smallScreen
                                      ? font_size.title3
                                      : font_size.title2,
                                  }}
                                  onClick={() =>
                                    handleStudentRegisterWebinar(item.id)
                                  }
                                >
                                  <div>Register now</div>
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        } else {
                          return (
                            <div
                              key={itemIdx}
                              className="eachWebinarCustomAgendaViewContainer"
                            >
                              <AgendaEvent
                                event={item}
                                title={item.title}
                                toggleWebinarDetails={toggleWebinarDetails}
                                style={{
                                  marginTop: "0.8em",
                                  width: smallScreen ? "60%" : "80%",
                                  textAlign: "left",
                                }}
                                customContentStyle={{ marginLeft: "0.5em" }}
                              />
                              <div
                                className="registerToWebinarContainer"
                                style={{
                                  fontSize: smallScreen
                                    ? font_size.title3
                                    : font_size.title2,
                                }}
                                onClick={() =>
                                  handleStudentRegisterWebinar(item.id)
                                }
                              >
                                <div>Register now</div>
                              </div>
                            </div>
                          );
                        }
                      } else {
                        return (
                          <div
                            key={itemIdx}
                            className="eachWebinarCustomAgendaViewContainer"
                          >
                            <AgendaEvent
                              event={item}
                              title={item.title}
                              toggleWebinarDetails={toggleWebinarDetails}
                              style={{
                                marginTop: "0.8em",
                                width: smallScreen ? "60%" : "80%",
                                textAlign: "left",
                              }}
                              customContentStyle={{ marginLeft: "0.5em" }}
                            />
                            <div
                              className="registerToWebinarContainer"
                              style={{
                                fontSize: smallScreen
                                  ? font_size.title3
                                  : font_size.title2,
                              }}
                              onClick={() =>
                                handleStudentRegisterWebinar(item.id)
                              }
                            >
                              <div>Register now</div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : null}
              </div>
              {isMoreThanFiveEvent ? (
                <div
                  className="showMoreEventStyle"
                  onClick={() => handleShowMoreEvent(groupedDataArrayId)}
                >
                  {isShowMore ? "Show less" : "Show more"}
                </div>
              ) : null}
            </div>
          );
        })
      ) : (
        <div style={{ paddingTop: "2em" }}>There is no event</div>
      )}
    </div>
  );
}

CustomAgendaView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CustomAgendaView.range = (date, { localizer }) => {
  const start = localizer.add(localizer.startOf(date, "week"), 1, "day");
  const end = localizer.add(start, 6, "day");

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CustomAgendaView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -7, "day");

    case Navigate.NEXT:
      return localizer.add(date, 7, "day");

    default:
      return date;
  }
};

CustomAgendaView.title = (date, { localizer }) => {
  const [start, ...rest] = CustomAgendaView.range(date, { localizer });
  return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
};

export default CustomAgendaView;
