import React from "react";

// Styles
import "../styles/LoginRegisterContainer.css";

// Icons & Images
import ccsLogoLight from "../assets/icons/ccs_logo_light.png";

// Bootstrap
import Card from "react-bootstrap/Card";

// Components
import AlertComponent from "./AlertComponent";
import SpinnerComponent from "./SpinnerComponent";

// react-responsive
import { useMediaQuery } from "react-responsive";

const LoginRegisterContainer = ({ children, isLoading, error, success }) => {
  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className="loginRegisterContainer">
      <SpinnerComponent
        isShown={isLoading}
        styles={{
          right: "20px",
          top: "20px",
        }}
      />
      <div className="ccsLogoLightMainContainer">
        <div className="ccsLogoLightContainer">
          <img src={ccsLogoLight} alt="css_logo" />
        </div>
      </div>

      <div className="loginRegisterAlertMainContainer">
        <Card
          style={{
            width: smallScreen ? "90vw" : "55vw",
            minHeight: "75vh",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "32px 0",
          }}
        >
          <AlertComponent
            isShown={success || error ? true : false}
            variant={success ? "success" : "danger"}
            text={success ? success : error}
          />
          {children}
        </Card>
      </div>
    </div>
  );
};

export default LoginRegisterContainer;
