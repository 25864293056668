import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import FilterModal from "../components/FilterModal";
import SortModal from "../components/SortModal";
import BigCalendar from "../components/Calendars/BigCalendar";
import LoadingModalMobile from "../components/LoadingModalMobile";

// Styles
import "../styles/courses.css";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Images & Icons
import empty_session_2_icon from "../assets/icons/empty_session_2_icon.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import filter_icon from "../assets/icons/filter_icon.svg";
import course_length_icon from "../assets/icons/course_length_icon.svg";

// Apis
import {
  getAllWebinars,
  getAllStudentWebinars,
} from "../apis/clientApis/webinars";

// Helpers
import { device } from "../helpers/device";
import { dateFormat } from "../utils/common";
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

// react-responsive
import { useMediaQuery } from "react-responsive";

// moment.js
import moment from "moment";

const Webinars = () => {
  const navigate = useNavigate();

  const userDetail = useSelector((state) => state.userDetail);
  const searchKey = useSelector((state) => state.searchKey);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [webinars, setWebinars] = useState([]);
  const [studentWebinars, setStudentWebinars] = useState([]);
  const [studentWebinarIds, setStudentWebinarIds] = useState([]);

  const handleFetchAllWebinars = async (finalStudentWebinarIds, searchKey) => {
    setIsLoading(true);
    const result = await getAllWebinars();
    if (result && result.data) {
      let finalWebinars = result.data;
      finalWebinars =
        finalWebinars &&
        Array.isArray(finalWebinars) &&
        finalWebinars.length > 0
          ? finalWebinars.map((webinar) => {
              const { id, webinar_schedule_date } = webinar || {};
              webinar = {
                ...webinar,
                // start: moment(new Date(webinar_schedule_date)).toDate(),
                // end: moment(new Date(webinar_schedule_date)).toDate(),
                start: new Date(webinar_schedule_date),
                end: new Date(webinar_schedule_date),
                added_to_users_webinar: finalStudentWebinarIds
                  ? finalStudentWebinarIds.includes(id)
                  : studentWebinarIds.includes(id),
              };
              return webinar;
            })
          : [];
      if (searchKey) {
        finalWebinars = finalWebinars.filter((data) => {
          const { title } = data || {};
          let lowercaseSearchkey = searchKey.toLowerCase();
          let titleToLowerCase = title.toLowerCase();
          if (titleToLowerCase.includes(lowercaseSearchkey)) {
            return data;
          }
        });
      }
      setWebinars(finalWebinars);
      setIsLoading(false);
    }
  };

  const handleFetchStudentWebinars = async () => {
    setIsLoading(true);
    const result = await getAllStudentWebinars();
    if (result && result.data) {
      let finalStudentWebinars = result.data;

      let finalStudentWebinarIds = [];
      let studentCoursesResponse = result.data;

      for (let scr = 0; scr < studentCoursesResponse.length; scr++) {
        const { webinar_id } = studentCoursesResponse[scr];
        finalStudentWebinarIds.push(webinar_id);
      }

      setStudentWebinarIds(finalStudentWebinarIds);
      setStudentWebinars(finalStudentWebinars);
      handleFetchAllWebinars(finalStudentWebinarIds);
    }
  };

  const handleStudentRegisterWebinar = async (webinar_id) => {
    navigate(`/webinars/${webinar_id}/register`);
  };

  const [isSortModalOpened, setSortModalOpened] = useState(false);
  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);
  const [data, setData] = useState([]);

  const initialDataState = webinars
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        webinar_id,
        topic_id,
        scheduled_date,
        mentor,
        duration,
        status,
        user_id,
        createdAt,
      }) => {
        const assignedMentor = mentor
          ? mentor?.user_details?.first_name +
            " " +
            mentor?.user_details?.last_name
          : "Not available";
        return {
          webinar_id,
          topic_id,
          scheduled_date,
          assignedMentor,
          duration,
          status,
          user_id,
          createdAt,
        };
      }
    )
    .filter((d) => d.status !== "Completed");

  const statusList = [
    "Confirmed",
    "Waiting for Confirmation",
    "Complete your data",
    "Cancelled",
    "In review",
    "Confirm your schedule",
  ];

  const filterKeyInitialState = {
    status: [],
    // topic: [],
    dateTime: {},
    // mentor: [],
  };

  const sortKeyInitialState = {
    newest: false,
    oldest: false,
  };

  const [filterKey, setFilterKey] = useState(filterKeyInitialState);
  const [sortKey, setSortKey] = useState(sortKeyInitialState);

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  function handleOnFilter(type, name, value) {
    setFilterChanged(true);
    switch (type) {
      case "status":
        if (value) {
          setFilterKey((prev) => ({ ...prev, status: [...prev.status, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            status: prev.status.filter((d) => d !== name),
          }));
        }
        break;
      //   case "topic":
      //     if (value) {
      //       setFilterKey((prev) => ({ ...prev, topic: [...prev.topic, name] }));
      //     } else {
      //       setFilterKey((prev) => ({
      //         ...prev,
      //         topic: prev.topic.filter((d) => d !== name),
      //       }));
      //     }
      //     break;
      case "date-time":
        if (value) {
          setFilterKey((prev) => ({ ...prev, dateTime: value }));
        }
        break;
      //   case "mentor":
      //     if (value) {
      //       setFilterKey((prev) => ({ ...prev, mentor: [...prev.mentor, name] }));
      //     } else {
      //       setFilterKey((prev) => ({
      //         ...prev,
      //         mentor: prev.mentor.filter((d) => d !== name),
      //       }));
      //     }
      //     break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
  }

  function handleOnSort(type) {
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
  }

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        let isValid = true;
        if (filterKey.status.length > 0) {
          isValid = filterKey.status.includes(d.status);
        }
        // if (filterKey.topic.length > 0) {
        //   isValid = filterKey.topic.includes(d.topic_id.toString());
        // }
        // if (filterKey.mentor.length > 0) {
        //   isValid = filterKey.mentor.includes(d.assignedMentor);
        // }
        if (filterKey.dateTime.from && filterKey.dateTime.to) {
          let date = moment(new Date(d.scheduled_date));
          let from = moment(new Date(filterKey.dateTime.from));
          let to = moment(new Date(filterKey.dateTime.to)).add(1, "days");

          isValid = moment(date).isBetween(from, to);
        }
        return isValid;
      });
      setData(filteredData);
    } else {
      setData(initialDataState);
    }
    setFilterChanged(false);
  }

  useEffect(() => {
    handleFetchStudentWebinars();
  }, []);

  useEffect(() => {
    handleFetchAllWebinars(studentWebinarIds, searchKey);
  }, [searchKey]);

  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <>
          {!isLoading &&
          webinars &&
          Array.isArray(webinars) &&
          webinars.length > 0 ? (
            <WebinarContainer>
              <WebinarInnerContainer>
                <div style={{ paddingBottom: "5em" }}>
                  <BigCalendar events={webinars} />
                </div>
                <Col
                  style={{
                    // marginTop: "130px",
                    textAlign: "left",
                    marginBottom: "3vh",
                  }}
                >
                  <PageTitle>All Webinars</PageTitle>
                </Col>
                <Col
                  style={{
                    textAlign: "left",
                    marginBottom: smallScreen ? "2vh" : "1vw",
                  }}
                >
                  <ButtonContainer>
                    <FilterSortButton onClick={handleOnClickSortButton}>
                      <img
                        src={sort_icon}
                        alt="sort_icon"
                        height={15}
                        width={15}
                        style={{ objectFit: "scale-down" }}
                      />
                      Sort
                    </FilterSortButton>
                    <FilterSortButton onClick={handleOnClickFilterButton}>
                      <img
                        src={filter_icon}
                        alt="filter_icon"
                        height={15}
                        width={15}
                        style={{ objectFit: "scale-down" }}
                      />
                      Filter
                    </FilterSortButton>
                  </ButtonContainer>
                  <FilterModal
                    show={isFilterModalOpened.toString()}
                    handleClose={() => {
                      setFilterModalOpened(false);
                      setFilterChanged(false);
                    }}
                    statusList={statusList}
                    filterKey={filterKey}
                    handleOnFilter={handleOnFilter}
                    dateTimeButtonList={["This Week", "This Month"]}
                    isFilterChanged={isFilterChanged}
                    user_detail={userDetail}
                    filterStatus={true}
                    filterDateTime={true}
                    smallScreen={smallScreen}
                  />
                  <SortModal
                    show={isSortModalOpened.toString()}
                    handleClose={() => {
                      setSortModalOpened(false);
                    }}
                    handleOnSort={handleOnSort}
                    sortKey={sortKey}
                    user_detail={userDetail}
                  />
                </Col>
                {smallScreen ? (
                  <>
                    <div>
                      {webinars.map((webinar, idx) => {
                        const {
                          id,
                          title,
                          image_cover_url,
                          webinar_schedule_date,
                          added_to_users_webinar,
                        } = webinar || {};
                        let finalWebinarSchedule = webinar_schedule_date;
                        if (finalWebinarSchedule) {
                          finalWebinarSchedule = new Date(
                            finalWebinarSchedule
                          ).toLocaleString();
                        }
                        return (
                          <EachMobileDataMainContainer key={id}>
                            <EachMobileDataContainer>
                              <div
                                className="courseImageContainer"
                                style={{ marginBottom: "0.5em" }}
                                onClick={() => navigate(`/webinars/${id}`)}
                              >
                                <img
                                  src={image_cover_url}
                                  alt="webinar image"
                                />
                              </div>
                              <div
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                  marginBottom: "0.5em",
                                }}
                                onClick={() => navigate(`/webinars/${id}`)}
                              >
                                {capitalizeFirstLetter(title)}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0.5em",
                                  alignItems: "center",
                                }}
                                onClick={() => navigate(`/webinars/${id}`)}
                              >
                                <img
                                  src={course_length_icon}
                                  alt="course length icon"
                                  style={{
                                    width: "20px",
                                    height: "auto",
                                  }}
                                />
                                <div style={{ fontSize: "15px" }}>
                                  {webinar_schedule_date
                                    ? dateFormat(webinar_schedule_date)
                                    : "Not available"}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  className="buttonAddToMyCourseContainer"
                                  style={{
                                    backgroundColor: added_to_users_webinar
                                      ? "#cccccc"
                                      : "#2c65f7",
                                  }}
                                  onClick={() =>
                                    added_to_users_webinar
                                      ? null
                                      : handleStudentRegisterWebinar(id)
                                  }
                                >
                                  <div>
                                    {added_to_users_webinar
                                      ? "Registered"
                                      : "Register now"}
                                  </div>
                                </div>
                              </div>
                            </EachMobileDataContainer>
                          </EachMobileDataMainContainer>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className="studentCoursesCardContainer">
                    {webinars.map((webinar, idx) => {
                      const {
                        id,
                        title,
                        image_cover_url,
                        webinar_schedule_date,
                        added_to_users_webinar,
                      } = webinar || {};
                      let finalWebinarSchedule = webinar_schedule_date;
                      if (finalWebinarSchedule) {
                        finalWebinarSchedule = new Date(
                          finalWebinarSchedule
                        ).toLocaleString();
                      }
                      return (
                        <div key={idx} className="courseCardContainer">
                          <div
                            onClick={() => navigate(`/webinars/${id}`)}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="courseTitleStyle"
                              style={{ marginBottom: "1em" }}
                            >
                              {capitalizeFirstLetter(title)}
                            </div>
                            <div
                              className="courseImageContainer"
                              style={{ marginBottom: "1em" }}
                            >
                              <img src={image_cover_url} alt="course image" />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "0.5em",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={course_length_icon}
                                alt="course length icon"
                                style={{
                                  width: "20px",
                                  height: "auto",
                                }}
                              />
                              <div className="courseLengthStyle">
                                {webinar_schedule_date
                                  ? dateFormat(webinar_schedule_date)
                                  : "Not available"}
                              </div>
                            </div>
                          </div>
                          <div
                            className="buttonAddToMyCourseContainer"
                            style={{
                              backgroundColor: added_to_users_webinar
                                ? "#cccccc"
                                : "#2c65f7",
                            }}
                            onClick={() =>
                              added_to_users_webinar
                                ? null
                                : handleStudentRegisterWebinar(id)
                            }
                          >
                            <div>
                              {added_to_users_webinar
                                ? "Registered"
                                : "Register now"}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </WebinarInnerContainer>
            </WebinarContainer>
          ) : !isLoading ? (
            <div>
              <div
                className="sessionHistoryInnerContainerEmptySession"
                style={{ padding: "0" }}
              >
                <img src={empty_session_2_icon} alt="empty_session_2_icon" />
                <h4 style={{ fontSize: "22px" }}>No webinar yet</h4>
                {/* <p style={{ fontSize: "18px" }}>
              Only completed booking session will appear here
            </p> */}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Webinars;

const WebinarContainer = styled.div`
  @media ${device.laptopM} {
    padding-left: 290px;
  }
  @media ${device.laptopL} {
    padding-left: 290px;
  }
`;

const WebinarInnerContainer = styled.div`
  padding: 90px 8vw 90px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const PageTitle = styled.h1`
  font-size: 30px;
  color: #2c65f7;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

const EachMobileDataMainContainer = styled.div`
  border-radius: 10px;
  width: 100%;
  border: 1px solid #2c65f7;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
`;

const EachMobileDataContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
