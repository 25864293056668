import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// react-bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// sweetalert
import Swal from "sweetalert2";

// Apis
import { getWebinarDetail } from "../apis/clientApis/webinars";
import {
  sendWebinarCertificate,
  uploadWebinarAttachments,
} from "../apis/internalApis/webinars";

// Images and Icons
import course_length_icon from "../assets/icons/course_length_icon.svg";
import empty_session_2_icon from "../assets/icons/empty_session_2_icon.svg";

// components
import SpinnerComponent from "../components/SpinnerComponent";
import HeaderComponent from "../components/HeaderComponent";
import LoadingModalMobile from "../components/LoadingModalMobile";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import ModalComponent from "../components/ModalComponent";

// react-responsive
import { useMediaQuery } from "react-responsive";

// Helpers
import { device } from "../helpers/device";
import { dateFormat } from "../utils/common";
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

const InternalWebinarDetail = () => {
  const navigate = useNavigate();
  const { webinar_id } = useParams();

  const userDetail = useSelector((state) => state.userDetail);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [webinarDetail, setWebinarDetail] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [checkedStudents, setCheckedStudents] = useState([]);
  const [
    isOpenModalConfirmSendCertificate,
    setIsOpenModalConfirmSendCertificate,
  ] = useState(false);

  const [studentWebinars, setStudentWebinars] = useState([]);
  const [perPage, setPerPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const handleGetWebinarDetail = async (webinar_id) => {
    setLoading(true);
    const result = await getWebinarDetail(webinar_id);
    if (result && result.data) {
      const { data } = result || {};
      setWebinarDetail(data);
      const studentWebinars = data.StudentWebinars || [];
      setStudentWebinars(studentWebinars);
    }
    setLoading(false);
  };

  let initialDataState = studentWebinars
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        id,
        first_name,
        last_name,
        email_address,
        phone_number,
        createdAt,
      }) => {
        let username = `${first_name} ${last_name}`;
        return {
          id,
          username,
          email_address,
          phone_number,
          registered_date: createdAt,
          checked: false,
        };
      }
    );

  const handleFormatFirstFetchPaginatedData = (studentWebinars) => {
    let initialDataState = studentWebinars
      .sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      })
      .map(
        ({
          id,
          first_name,
          last_name,
          email_address,
          phone_number,
          createdAt,
          checked,
        }) => {
          let username = `${first_name} ${last_name}`;
          return {
            id,
            username,
            email_address,
            phone_number,
            registered_date: createdAt,
            checked: checked ? checked : false,
          };
        }
      );
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  function handleOnClickRow(d, key, value) {
    navigate(`/internal/users/${d["id"]}`);
  }

  const handleSendCertificate = async () => {
    setLoading(true);
    const body = { recepients: checkedStudents };
    const response = await sendWebinarCertificate(body, webinar_id);
    if (response && response.data && response.data.id) {
      setIsOpenModalConfirmSendCertificate(false);
      Swal.fire({
        imageUrl:
          "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
        position: "top-end",
        text: `Webinar certificate sent`,
        width: "20em",
        showConfirmButton: false,
        timer: 1500,
      });
      setWebinarDetail(response.data);
    }
    setLoading(false);
  };

  const handleOnInputChange = async (files, flagger) => {
    setLoading(true);
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("option", flagger);
    const response = await uploadWebinarAttachments(formData, webinar_id);
    if (response && response.data && response.data.id) {
      Swal.fire({
        imageUrl:
          "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
        position: "top-end",
        text: `Webinar ${
          flagger === "webinar_signature_url" ? "signature" : "material"
        } uploaded`,
        width: "20em",
        showConfirmButton: false,
        timer: 1500,
      });
      setWebinarDetail(response.data);
    }
    setLoading(false);
  };

  const handleDownloadWebinarAttachments = (url) => {
    window.open(url, "_blank");
  };

  const openModalSendWebinarCertificate = (value) => {
    if (webinarDetail) {
      if (webinarDetail.webinar_material_url) {
        if (webinarDetail.webinar_signature_url) {
          if (value) {
            let finalCheckedStudents = studentWebinars;
            if (
              studentWebinars &&
              Array.isArray(studentWebinars) &&
              studentWebinars.length > 0
            ) {
              finalCheckedStudents = finalCheckedStudents.filter(
                (student) => student.checked
              );
              if (
                finalCheckedStudents &&
                Array.isArray(finalCheckedStudents) &&
                finalCheckedStudents.length > 0
              ) {
                setCheckedStudents(finalCheckedStudents);
                setIsOpenModalConfirmSendCertificate(value);
              } else {
                // show please check desired student to send certificate
                Swal.fire({
                  customClass: {
                    popup: "mentor-popup",
                  },
                  position: "top-end",
                  icon: "info",
                  title: "Please select student(s) first",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }
          }
        } else {
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            position: "top-end",
            icon: "info",
            title: "Please upload webinar signature first",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          position: "top-end",
          icon: "info",
          title: "Please upload webinar material first",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const handleCheckboxChange = (row, value, isAll, setAllChecked) => {
    let finalStudentWebinars = studentWebinars;

    finalStudentWebinars = finalStudentWebinars.map((dataState) => {
      if (isAll) {
        dataState.checked = value;
      } else {
        if (dataState && dataState.id === row.id) {
          dataState.checked = value;
        }
      }
      return dataState;
    });

    if (row) {
      let isThereUnChecked = finalStudentWebinars.some(
        (student_webinar) => !student_webinar.checked
      );

      if (isThereUnChecked) {
        setAllChecked(false);
      } else {
        setAllChecked(true);
      }
    }

    setStudentWebinars(finalStudentWebinars);
  };

  useEffect(() => {
    if (webinar_id) {
      handleGetWebinarDetail(webinar_id);
    }
  }, [webinar_id]);

  useEffect(() => {
    if (
      studentWebinars &&
      Array.isArray(studentWebinars) &&
      studentWebinars.length > 0
    ) {
      handleFormatFirstFetchPaginatedData(studentWebinars);
    }
  }, [studentWebinars]);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <OuterContainer>
          <WebinarInnerContainer>
            {!isLoading && webinarDetail ? (
              <>
                <Row>
                  <Col
                    style={{
                      marginBottom: smallScreen ? "" : "2vh",
                      gap: "10vw",
                      marginLeft: smallScreen ? "" : "20vh",
                      marginRight: smallScreen ? "" : "20vh",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4vh",
                      }}
                    >
                      <img
                        src={webinarDetail?.image_cover_url}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                          borderRadius: "30px",
                        }}
                        alt="courseImage"
                      />
                    </div>
                  </Col>
                </Row>
                <Col style={{ marginBottom: "3em" }}>
                  <TextTitle>
                    {capitalizeFirstLetter(webinarDetail?.title)}
                  </TextTitle>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5em",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={course_length_icon}
                      alt="course length icon"
                      style={{
                        width: "20px",
                        height: "auto",
                      }}
                    />
                    <div style={{ fontSize: "15px" }}>
                      {webinarDetail && webinarDetail.webinar_schedule_date
                        ? dateFormat(webinarDetail.webinar_schedule_date)
                        : "Not available"}
                    </div>
                  </div>
                </Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: smallScreen ? "column" : "row",
                    alignItems: smallScreen ? "start" : "center",
                    gap: smallScreen ? "1em" : "2em",
                    marginBottom: "3em",
                  }}
                >
                  <Label user_detail={userDetail}>Webinar materials</Label>
                  {webinarDetail && webinarDetail.webinar_material_url ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#00BB99",
                        borderRadius: "20px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        padding: smallScreen ? "1vh 5vw" : "1vh 1vw",
                        cursor: "pointer",
                        fontSize: smallScreen ? "14px" : "16px",
                      }}
                      onClick={() =>
                        handleDownloadWebinarAttachments(
                          webinarDetail && webinarDetail.webinar_material_url
                        )
                      }
                    >
                      <div>Available to download</div>
                    </div>
                  ) : null}
                  <div
                    style={{
                      // backgroundColor: added_to_users_webinar
                      //   ? "#cccccc"
                      //   : "#2c65f7",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#00BB99",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      padding: smallScreen ? "1vh 5vw" : "1vh 1vw",
                      cursor: "pointer",
                      fontSize: smallScreen ? "14px" : "16px",
                    }}
                    onClick={() =>
                      document
                        .querySelector(`.input-field-webinar-material-url`)
                        .click()
                    }
                  >
                    <div>
                      {webinarDetail && webinarDetail.webinar_material_url
                        ? "Change webinar material"
                        : "Upload material"}
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="application/pdf"
                  className={`input-field-webinar-material-url`}
                  hidden
                  onChange={({ target: { files } }) =>
                    handleOnInputChange(files, "webinar_material_url")
                  }
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: smallScreen ? "column" : "row",
                    alignItems: smallScreen ? "start" : "center",
                    gap: smallScreen ? "1em" : "2em",
                    marginBottom: "3em",
                  }}
                >
                  <Label user_detail={userDetail}>
                    Upload webinar signature
                  </Label>
                  {webinarDetail && webinarDetail.webinar_signature_url ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#00BB99",
                        borderRadius: "20px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        padding: smallScreen ? "1vh 5vw" : "1vh 1vw",
                        cursor: "pointer",
                        fontSize: smallScreen ? "14px" : "16px",
                      }}
                      onClick={() =>
                        handleDownloadWebinarAttachments(
                          webinarDetail && webinarDetail.webinar_signature_url
                        )
                      }
                    >
                      <div>Available to download</div>
                    </div>
                  ) : null}
                  <div
                    style={{
                      // backgroundColor: added_to_users_webinar
                      //   ? "#cccccc"
                      //   : "#2c65f7",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#00BB99",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      padding: smallScreen ? "1vh 5vw" : "1vh 1vw",
                      cursor: "pointer",
                      fontSize: smallScreen ? "14px" : "16px",
                    }}
                    onClick={() =>
                      document
                        .querySelector(`.input-field-webinar-signature-url`)
                        .click()
                    }
                  >
                    <div>
                      {webinarDetail && webinarDetail.webinar_signature_url
                        ? "Change signature"
                        : "Upload signature"}
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  className={`input-field-webinar-signature-url`}
                  hidden
                  onChange={({ target: { files } }) =>
                    handleOnInputChange(files, "webinar_signature_url")
                  }
                />
                <Row
                  style={{
                    marginTop: "2em",
                    marginBottom: "2em",
                    backgroundColor: "#f3f3f3",
                  }}
                >
                  <Col style={{ margin: "1.5em" }}>
                    <TextTitle>
                      Students who registered to this webinar
                    </TextTitle>
                    {paginatedData &&
                    Array.isArray(paginatedData) &&
                    paginatedData.length > 0 ? (
                      // <Pagination
                      //   handlePageClick={handlePageClick}
                      //   pageCount={pageCount}
                      //   itemOffset={itemOffset + 1}
                      //   endOffset={itemOffset + perPage}
                      //   totalItems={initialDataState.length}
                      // >
                      //   <TableInternalComponent
                      //     table_data={paginatedData}
                      //     topics={[]}
                      //     onClickRow={handleOnClickRow}
                      //     handleCheckboxChange={handleCheckboxChange}
                      //     table_name="webinarDetail"
                      //   />
                      // </Pagination>
                      <TableInternalComponent
                        table_data={paginatedData}
                        topics={[]}
                        onClickRow={handleOnClickRow}
                        handleCheckboxChange={handleCheckboxChange}
                        table_name="webinarDetail"
                      />
                    ) : (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={empty_session_2_icon}
                            alt="empty_session_2_icon"
                          />
                          <h4 style={{ fontSize: "22px" }}>
                            No student registered to this webinar yet
                          </h4>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: smallScreen ? "column" : "row",
                    alignItems: smallScreen ? "start" : "center",
                    justifyContent: "flex-end",
                    gap: smallScreen ? "1em" : "2em",
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        webinarDetail && webinarDetail.certificate_sent
                          ? "#AAAAAA"
                          : "#00BB99",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      padding: smallScreen ? "1vh 5vw" : "1vh 1vw",
                      cursor: "pointer",
                      fontSize: smallScreen ? "14px" : "16px",
                    }}
                    onClick={() => openModalSendWebinarCertificate(true)}
                  >
                    <div>
                      {webinarDetail && webinarDetail.certificate_sent
                        ? "Certificate sent to participant(s)"
                        : "Send certificate to participant(s)"}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <ModalComponent
              show={isOpenModalConfirmSendCertificate}
              body={[
                <p style={{ fontWeight: "700" }} key={0}>
                  Are you sure want process this action?
                </p>,
              ]}
              buttonPrimary="Yes"
              buttonSecondary="No"
              onPrimaryClick={() => handleSendCertificate()}
              onSecondaryClick={() =>
                setIsOpenModalConfirmSendCertificate(false)
              }
              handleClose={() => setIsOpenModalConfirmSendCertificate(false)}
            />
          </WebinarInnerContainer>
        </OuterContainer>
      )}
    </div>
  );
};

export default InternalWebinarDetail;

const OuterContainer = styled.div`
  text-align: left;
  @media ${device.laptopM} {
    padding-left: 290px;
  }
  @media ${device.laptopL} {
    padding-left: 290px;
  }
`;

const WebinarInnerContainer = styled.div`
  padding: 80px 8vw 130px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 130px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 130px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const Label = styled.h5`
  font-size: 20px;
  color: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00bb99"};
`;

const TextTitle = styled.div`
  font-size: 25px;
  color: black;
  font-weight: bold;
`;

const ButtonHandlerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonHandler = styled.button`
  font-size: 14px;
  padding: 0.3em 2em;
  color: white;
  border: none;
  background: ${({ color }) => (color ? color : "none")};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  margin-left: 1em;
`;
