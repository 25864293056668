import React from "react";

// images & icons
import close_icon_grey from "../assets/icons/close_icon_grey.png";
import uploading_video_icon from "../assets/icons/uploading_video_icon.svg";

import "../styles/uploadVideoProgressBar.css";

const UploadingVideoProgressBar = (props) => {
  const {
    videoToView,
    cancelUploadVideo,
    isModalUploadToServer,
    handleUploadFileToServer,
    index,
    loadingUploadFile,
  } = props || {};

  return (
    <div className="uploadingVideoProgressBarMainContainer">
      <div className="videoIconAndNameAndSizeAndCancelBtnContainer">
        <div className="videoIconAndNameAndSizeContainer">
          <div className="videoIconContainer">
            <img src={uploading_video_icon} alt="uploading_video_icon" />
          </div>
          {videoToView && videoToView.fileName && videoToView.fileSize ? (
            <div className="videoNameAndSizeContainer">
              <div className="videoName">{videoToView.fileName}</div>
              <div className="videoSize">{`${videoToView.fileSize} MB`}</div>
            </div>
          ) : null}
        </div>
        {/* {videoToView &&
        videoToView.filePercentageLoaded &&
        videoToView.filePercentageLoaded.toString() !== "100" &&
        !videoToView.isCanceled ? (
          <div>
            <img
              src={close_icon_grey}
              alt="cancel upload"
              onClick={() => cancelUploadVideo()}
              style={{ cursor: "pointer" }}
            />
          </div>
        ) : null} */}
        {isModalUploadToServer &&
        videoToView &&
        videoToView.isUploaded === true &&
        !videoToView.filePercentageLoaded ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-2vh",
            }}
          >
            <button
              disabled
              style={{
                display: "flex",
                background: "#aaaaaa",
                borderRadius: "7px",
                border: "transparent",
                padding: "0.5em 1em",
                transition: "0.2s",
              }}
            >
              <div style={{ color: "#ffffff" }}>Uploaded</div>
            </button>
          </div>
        ) : null}
        {/* {isModalUploadToServer && videoToView && videoToView.isCanceled ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-2vh",
            }}
          >
            <button
              style={{
                display: "flex",
                background: "#cbcbcb",
                borderRadius: "7px",
                border: "transparent",
                padding: "0.5em 1em",
                transition: "0.2s",
                cursor: "pointer",
              }}
              disabled={true}
            >
              <div style={{ color: "#000000" }}>Upload canceled</div>
            </button>
          </div>
        ) : null} */}
        {isModalUploadToServer &&
        videoToView &&
        videoToView.isUploaded === false &&
        !videoToView.filePercentageLoaded ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-2vh",
            }}
          >
            <button
              // onClick={() => handleCheckAllAccordionInputs()}
              onClick={() => handleUploadFileToServer(index)}
              style={{
                display: "flex",
                background: "#00bb99",
                borderRadius: "7px",
                border: "transparent",
                padding: "0.5em 1em",
                transition: "0.2s",
              }}
            >
              <div style={{ color: "#ffffff" }}>Upload</div>
            </button>
          </div>
        ) : null}
      </div>
      {/* {videoToView && videoToView.filePercentageLoaded && !loadingUploadFile ? (
        <>
          <div className="videoUploadProgressContainer">
            <div
              style={{
                height: "15px",
                width: `${videoToView.filePercentageLoaded}%`,
                borderRadius: "10px",
                backgroundColor: "#007c7c",
              }}
            ></div>
          </div>
          <div>{`${videoToView.filePercentageLoaded || "0"}% Uploaded`}</div>
        </>
      ) : null} */}
    </div>
  );
};

export default UploadingVideoProgressBar;
