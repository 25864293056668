import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// react-bootstrap
import Form from "react-bootstrap/Form";

// Styles
import "../styles/register.css";

// Icons & Images
import google_icon from "../assets/icons/google_icon.svg";
import mail_big_icon from "../assets/icons/mail_big_icon.svg";

// React Icons / ai
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// Constants
import { EMAIL_REGEX, PASSWORD_REGEX } from "../constants";

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Apis
import { registerUser, removeUserOtpToken } from "../apis/authentications";
import { getAllCountries } from "../apis/general";

// Lib
import fontSize from "../lib/styling/font_size";

// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// Helpers
import { createCountriesOptions } from "../helpers/get-country-options";
import { validatePhoneNumber } from "../helpers/validate-phone-number";

// Cookies
import Cookies from "js-cookie";

// Components
import LoginRegisterContainer from "../components/LoginRegisterContainer";
import RadioButton from "../components/input/RadioButton";
import InputText from "../components/input/InputText";
import InputDropdown from "../components/input/InputDropdown";

const Register = () => {
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);

  const innerWidth = window.innerWidth;

  const userOtpToken = Cookies.get("uto");

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitting },
    clearErrors,
    setError,
  } = useForm({ mode: "onChange" });
  const handleError = (errors) => {};

  const [email, setEmail] = useState("");
  const [isSuccessRegister, setSuccessRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrorMsg] = useState("");
  const [success, setSuccess] = useState("");
  const [captchaCode, setCaptchaCode] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");

  // phone number state
  const [isOpenPhoneDropdown, setIsOpenPhoneDropdown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    icon: null,
    phone_code: "",
    country_id: "",
  });
  const [countryOptions, setCountryOptions] = useState([]);

  const onReCAPTCHAChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }
    setCaptchaCode(captchaCode);
  };

  const formOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value: EMAIL_REGEX,
        message: "Invalid email address",
      },
    },
    password: {
      required: "Password is required",

      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Please enter your password at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      },
    },
    repeat_password: {
      required: "Password is required",

      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Please enter your password at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      },
    },
    first_name: {
      required: "First name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    last_name: {
      required: "Last name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    // phone_number: {
    //   required: "Phone number is required",
    //   pattern: {
    //     value: /^\d+$/,
    //     message: "Invalid phone number",
    //   },
    //   maxLength: {
    //     value: 16,
    //     message: "Can't be longer than 16 characters",
    //   },
    // },
    gender: {
      required: "Gender is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
  };

  async function handleRegistration(data) {
    if (
      data.email &&
      data.password &&
      data.repeat_password &&
      captchaCode &&
      data.first_name &&
      data.last_name &&
      // data.phone_number &&
      data.gender
    ) {
      setIsLoading(true);
      if (
        data.password &&
        data.repeat_password &&
        data.password === data.repeat_password
      ) {
        try {
          setEmail(data.email);
          if (
            phoneNumber &&
            phoneNumber.country_id &&
            phoneNumber.number &&
            phoneNumber.phone_code
          ) {
            let isValidPhoneNumber = validatePhoneNumber(phoneNumber.number);

            if (isValidPhoneNumber) {
              const body = {
                email: data.email,
                password: data.password,
                first_name: data.first_name,
                last_name: data.last_name,
                country_id: phoneNumber.country_id,
                phone_number: phoneNumber.number,
                gender: data.gender,
                captcha: captchaCode,
              };

              const response = await registerUser(body);
              if (response && response.data) {
                setIsLoading(false);
                setSuccess("Register success");
                setSuccessRegister(true);
              }
            } else {
              setError("phone_number", { message: "Invalid phone number" });
            }
          } else {
            if (!phoneNumber.country_id) {
              setError("country_id", { message: "Country is required" });
            }
            if (!phoneNumber.number) {
              setError("phone_number", { message: "Phone number is required" });
            }
          }
        } catch (err) {
          console.log(err, "error line 210 register.js <<");
          setIsLoading(false);
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            setErrorMsg(
              err.response.data.message.toString().replace(",", ", ")
            );
          }
          recaptchaRef?.current?.reset();
          setCaptchaCode(null);
        }
      } else {
        setErrorMsg("Password did not match");
        setIsLoading(false);
        recaptchaRef?.current?.reset();
        setCaptchaCode(null);
      }
    }
  }

  const renderForm = () => {
    return (
      <Form
        onSubmit={handleSubmit(handleRegistration, handleError)}
        className="registerFormMainContainer"
        style={{ marginTop: error || success ? "2em" : "" }}
      >
        <div className="registerWelcomeText">
          Schedule a free coaching session
        </div>
        <div>Get 1-on-1 mentorship with our Sustainability Expert</div>
        <button className="loginGoogleBtnContainer">
          <img src={google_icon} alt="google_logo" height={25} width={25} />
          Log in with Google
        </button>
        <div>or</div>
        <Form.Group
          style={{ width: "80%", display: "flex", flexDirection: "column" }}
        >
          <Form.Label
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
            }}
            htmlFor="email"
          >
            Email address
          </Form.Label>
          <Form.Control
            id="email"
            type="email"
            name="email"
            placeholder="yourname@mail.com"
            error={errors?.email}
            autoComplete="off"
            {...register("email", formOptions.email)}
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: errors && errors.email ? "#EB4335" : "#00BB99",
              backgroundColor:
                errors && errors.email ? "#FFE1E1" : "transparent",
              borderRadius: "10px",
              [":focus"]: {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: errors && errors.email ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.email ? "#FFE1E1" : "transparent",
              },
              display: "inline-block",
            }}
          />
          <small className="text-danger" style={{ textAlign: "left" }}>
            {errors?.email && errors.email.message}
          </small>
        </Form.Group>
        <Form.Group
          style={{ width: "80%", display: "flex", flexDirection: "column" }}
        >
          <Form.Label
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
              display: "inline-flex",
            }}
            htmlFor="password"
          >
            Password
            {/* <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" /> */}
          </Form.Label>
          <div
            style={{
              position: "relative",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Control
              id="password"
              type={passwordType}
              name="password"
              placeholder="••••••••••••••"
              error={errors?.password}
              autoComplete="off"
              {...register("password", formOptions.password)}
              style={{
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: errors && errors.password ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.password ? "#FFE1E1" : "transparent",
                borderRadius: "10px",
                [":focus"]: {
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor:
                    errors && errors.password ? "#EB4335" : "#00BB99",
                  backgroundColor:
                    errors && errors.password ? "#FFE1E1" : "transparent",
                },
                display: "inline-block",
              }}
            />
            <input
              autoComplete="off"
              style={{ display: "none" }}
              id="show1"
              type="checkbox"
              checked={passwordType === "text"}
              onChange={() =>
                setPasswordType((prev) =>
                  prev === "password" ? "text" : "password"
                )
              }
            />
            <label
              htmlFor="show1"
              style={{
                position: "absolute",
                right: 0,
                margin: "0 12px",
                cursor: "pointer",
              }}
            >
              {passwordType === "password" ? (
                <AiFillEye size={25} />
              ) : (
                <AiFillEyeInvisible size={25} />
              )}
            </label>
          </div>
          <small
            className="text-danger"
            style={{ userSelect: "none", textAlign: "left" }}
          >
            {errors?.password && (
              <>
                {errors.password.message}
                <br />
              </>
            )}
          </small>
        </Form.Group>
        <Form.Group
          style={{ width: "80%", display: "flex", flexDirection: "column" }}
        >
          <Form.Label
            htmlFor="repeat_password"
            style={{
              fontWeight: "bold",
              color: "black",
              display: "inline-flex",
              textAlign: "left",
            }}
          >
            Repeat Password
            {/* <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" /> */}
          </Form.Label>
          <div
            style={{
              position: "relative",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Control
              id="repeat_password"
              type={repeatPasswordType}
              name="repeat_password"
              placeholder="••••••••••••••"
              error={errors?.repeat_password}
              autoComplete="off"
              {...register("repeat_password", formOptions.repeat_password)}
              style={{
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor:
                  errors && errors.repeat_password ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.repeat_password ? "#FFE1E1" : "transparent",
                borderRadius: "10px",
                [":focus"]: {
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor:
                    errors && errors.repeat_password ? "#EB4335" : "#00BB99",
                  backgroundColor:
                    errors && errors.repeat_password
                      ? "#FFE1E1"
                      : "transparent",
                },
                display: "inline-block",
              }}
            />
            <input
              autoComplete="off"
              style={{ display: "none" }}
              id="show2"
              type="checkbox"
              checked={repeatPasswordType === "text"}
              onChange={() =>
                setRepeatPasswordType((prev) =>
                  prev === "password" ? "text" : "password"
                )
              }
            />
            <label
              htmlFor="show2"
              style={{
                position: "absolute",
                right: 0,
                margin: "0 12px",
                cursor: "pointer",
              }}
            >
              {repeatPasswordType === "password" ? (
                <AiFillEye size={25} />
              ) : (
                <AiFillEyeInvisible size={25} />
              )}
            </label>
          </div>
          <small
            className="text-danger"
            style={{ userSelect: "none", textAlign: "left" }}
          >
            {errors?.repeat_password && (
              <>
                {errors.repeat_password.message}
                <br />
              </>
            )}
          </small>
        </Form.Group>
        <Form.Group
          style={{ width: "80%", display: "flex", flexDirection: "column" }}
        >
          <Form.Label
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
            }}
            htmlFor="first_name"
          >
            First name
          </Form.Label>
          <Form.Control
            id="first_name"
            type="first_name"
            name="first_name"
            placeholder="Your first name"
            error={errors?.first_name}
            autoComplete="off"
            {...register("first_name", formOptions.first_name)}
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: errors && errors.first_name ? "#EB4335" : "#00BB99",
              backgroundColor:
                errors && errors.first_name ? "#FFE1E1" : "transparent",
              borderRadius: "10px",
              [":focus"]: {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor:
                  errors && errors.first_name ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.first_name ? "#FFE1E1" : "transparent",
              },
              display: "inline-block",
            }}
          />
          <small className="text-danger" style={{ textAlign: "left" }}>
            {errors?.first_name && errors.first_name.message}
          </small>
        </Form.Group>
        <Form.Group
          style={{ width: "80%", display: "flex", flexDirection: "column" }}
        >
          <Form.Label
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
            }}
            htmlFor="last_name"
          >
            Last name
          </Form.Label>
          <Form.Control
            id="last_name"
            type="last_name"
            name="last_name"
            placeholder="Your last name"
            error={errors?.last_name}
            autoComplete="off"
            {...register("last_name", formOptions.last_name)}
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: errors && errors.last_name ? "#EB4335" : "#00BB99",
              backgroundColor:
                errors && errors.last_name ? "#FFE1E1" : "transparent",
              borderRadius: "10px",
              [":focus"]: {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: errors && errors.last_name ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.last_name ? "#FFE1E1" : "transparent",
              },
              display: "inline-block",
            }}
          />
          <small className="text-danger" style={{ textAlign: "left" }}>
            {errors?.last_name && errors.last_name.message}
          </small>
        </Form.Group>
        <Form.Group
          style={{ width: "80%", display: "flex", flexDirection: "column" }}
        >
          <Form.Label
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
            }}
            htmlFor="phone_number"
          >
            Phone number
          </Form.Label>
          {/* <Form.Control
            id="phone_number"
            type="phone_number"
            name="phone_number"
            placeholder="Your phone number"
            error={errors?.phone_number}
            autoComplete="off"
            {...register("phone_number", formOptions.phone_number)}
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor:
                errors && errors.phone_number ? "#EB4335" : "#00BB99",
              backgroundColor:
                errors && errors.phone_number ? "#FFE1E1" : "transparent",
              borderRadius: "10px",
              [":focus"]: {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor:
                  errors && errors.phone_number ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.phone_number ? "#FFE1E1" : "transparent",
              },
              display: "inline-block",
            }}
          /> */}
          {/* <small className="text-danger" style={{ textAlign: "left" }}>
            {errors?.phone_number && errors.phone_number.message}
          </small> */}
          <div className="inputPhoneBox">
            <div
              className="inputPhoneCode"
              onClick={() => setIsOpenPhoneDropdown(!isOpenPhoneDropdown)}
              style={{
                border:
                  errors && errors.country_id && !phoneNumber.country_id
                    ? "2px solid #EB4335"
                    : "2px solid #00BB99",
              }}
            >
              {phoneNumber && phoneNumber.icon ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CIcon icon={phoneNumber.icon} size="xxl" />
                </div>
              ) : (
                "Country"
              )}
            </div>
            <InputText
              placeholder={"Fill in phone number"}
              background={
                errors && errors.phone_number ? "#FFE1E1" : "transparent"
              }
              name={"phoneNumberMyProfile"}
              border={
                errors && errors.phone_number
                  ? "2px solid #EB4335"
                  : "2px solid #00BB99"
              }
              functionSetter={handleOnChangePhoneNumber}
              phoneNumberState={phoneNumber}
              setterPhoneNumber={setPhoneNumber}
              className={"userFormInputStyle"}
              value={
                phoneNumber && phoneNumber.number && phoneNumber.phone_code
                  ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                  : phoneNumber && phoneNumber.phone_code
                  ? `(${phoneNumber.phone_code}) `
                  : phoneNumber && phoneNumber.number && !phoneNumber.phone_code
                  ? phoneNumber.number
                  : ""
              }
            />
          </div>
          <small
            className="text-danger"
            style={{
              userSelect: "none",
              display: "flex",
              textAlign: "start",
            }}
          >
            {!phoneNumber.country_id &&
            !phoneNumber.number &&
            errors?.country_id &&
            errors.country_id.message &&
            errors?.phone_number &&
            errors.phone_number.message
              ? "Country and Phone Number is required"
              : (!phoneNumber.country_id &&
                  errors?.country_id &&
                  errors.country_id.message) ||
                (errors?.phone_number && errors.phone_number.message)}
          </small>
          <div
            className="inputContainer"
            style={{ marginTop: "0", textAlign: "left" }}
          >
            <div>
              {isOpenPhoneDropdown ? (
                <InputDropdown
                  options={countryOptions}
                  styles={{
                    marginTop: "1em",
                    border: "2px solid #00BB99",
                    background: "transparent",
                    borderRadius: "10px",
                    height: "48px",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  state={phoneNumber}
                  setter={setPhoneNumber}
                  functionSetter={handleChangePhoneNumberDropdown}
                />
              ) : null}
            </div>
          </div>
        </Form.Group>
        <Form.Group
          style={{ width: "80%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="userFormInputLabel"
            style={{
              fontSize: fontSize.title2,
            }}
          >
            Gender
          </div>
          <Controller
            control={control}
            name="gender"
            rules={formOptions.gender}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name="gender"
                data={["male", "female"]}
                state={value}
                handleChange={onChange}
                isFilled={Boolean(getValues("gender"))}
              />
            )}
          />
          <small
            className="text-danger"
            style={{ userSelect: "none", textAlign: "left" }}
          >
            {errors?.gender && errors.gender.message}
          </small>
        </Form.Group>
        <div
          style={{
            fontSize: "10px",
            width: "80%",
            textAlign: innerWidth && innerWidth < 768 ? "left" : "",
          }}
        >
          By signing up, I agree to Combined Clinics Sustainability{" "}
          <span
            onClick={() => navigate(`/term-of-services`)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            Terms of Services
          </span>{" "}
          and{" "}
          <span
            onClick={() => navigate(`/privacy-policy`)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            Privacy Policy
          </span>
          . This page is protected by reCAPTCHA and is subject to Google&apos;s
          Terms of Service and Privacy Policy.
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: innerWidth < 768 ? "80%" : "",
          }}
        >
          <div
            style={{
              transform: innerWidth < 768 ? "scale(0.8)" : "scale(1)",
              transformOrigin: "0 0",
              width: innerWidth < 768 ? "100%" : "",
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
              onChange={onReCAPTCHAChange}
            />
          </div>
        </div>
        <button
          disabled={
            isLoading ||
            !isDirty ||
            // !isValid ||
            isSubmitting ||
            !captchaCode
          }
          type="submit"
          className="loginButton"
          style={{
            [":hover"]: {
              background: "#00BB99",
              cursor:
                isLoading ||
                !isDirty ||
                // !isValid ||
                isSubmitting ||
                !captchaCode
                  ? ""
                  : "pointer",
              opacity:
                isLoading ||
                !isDirty ||
                // !isValid ||
                isSubmitting ||
                !captchaCode
                  ? "0.5"
                  : "0.8",
            },
            opacity:
              isLoading ||
              !isDirty ||
              // !isValid ||
              isSubmitting ||
              !captchaCode
                ? "0.5"
                : "1",
          }}
        >
          Let&apos;s get started
        </button>
        <div onClick={() => navigate("/login")} style={{ cursor: "pointer" }}>
          <a
            style={{
              fontSize: "12px",
              color: "black",
              [":hover"]: {
                color: "blue",
              },
            }}
          >
            Already have an account?{" "}
            <span style={{ fontWeight: "bold" }}>Log in </span>
          </a>
        </div>
        <div
          style={{
            fontSize: 12,
            color: "black",
          }}
        >
          This site is protected by reCAPTCHA
        </div>
      </Form>
    );
  };

  const renderSuccessRegister = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <img src={mail_big_icon} alt="google_logo" height={220} width={210} />
        <div style={{ fontSize: "36px", color: "#00BB99" }}>
          Please wait a moment for account validation
        </div>
        <div style={{ fontSize: "22px", marginTop: "3vh" }}>
          Our admin is now reviewing your account.
        </div>
        <div style={{ fontSize: "18px", marginTop: "1.5vh" }}>
          You will receive an email very soon. Please do check your email inbox
          periodically.
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3vh",
          }}
        >
          {/* <button
            disabled={isLoading}
            onClick={() => navigate("/")}
            variant="secondary"
            className="registerResendEmailBtn"
          >
            Resend email
          </button> */}
          <div
            disabled={isLoading}
            onClick={() => navigate("/")}
            variant="secondary"
            className="registerContactSupportBtn"
          >
            Contact Support
          </div>
        </div>
      </div>
    );
  };

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    clearErrors("phone_number");

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };

  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const {
      value: { code, phone_code, id },
      label = {},
    } = val || {};

    let countryIcon = "";
    if (code) {
      countryIcon = icon[`cif${code}`];
    }
    setterPhoneNumber({
      ...phoneNumberState,
      icon: countryIcon,
      phone_code: phone_code,
      country_id: id,
      value: { label, value: val.value },
    });
    clearErrors("country_id");
    setIsOpenPhoneDropdown(false);
  };

  const fetchAllCountries = async () => {
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data));
      }
    }
  };

  const handleRemoveUserOtpToken = async () => {
    try {
      await removeUserOtpToken();
    } catch (err) {
      console.log(err, "error line 949s register.js <<");
    }
  };

  useEffect(() => {
    fetchAllCountries();
  }, []);

  useEffect(() => {
    if (userOtpToken) {
      handleRemoveUserOtpToken();
    }
  }, [userOtpToken]);

  return (
    <LoginRegisterContainer
      isLoading={isLoading}
      error={error}
      success={success}
    >
      {isSuccessRegister ? renderSuccessRegister() : renderForm()}
    </LoginRegisterContainer>
  );
};

export default Register;
