import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import SortModal from "../components/SortModal";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import ModalComponent from "../components/ModalComponent";

// Styles
import "../styles/sessionHistory.css";

// Images & Icons
import empty_session_2_icon from "../assets/icons/empty_session_2_icon.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import add_new_mentor_icon from "../assets/icons/add_new_mentor_icon.png";

// Apis
import { getAllTopics } from "../apis/topics";
import { getAllCourses } from "../apis/courses";
import { deleteCourse } from "../apis/internalApis/courses";

// helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";
import { device } from "../helpers/device";

// sweetalert
import Swal from "sweetalert2";

const InternalCourses = () => {
  const navigate = useNavigate();
  const searchKey = useSelector((state) => state.searchKey);
  const userDetail = useSelector((state) => state.userDetail);

  const [isLoading, setIsLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [courses, setCourses] = useState([]);

  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [isSortModalOpened, setSortModalOpened] = useState(false);

  const [savedCourseIdToDelete, setSavedCourseIdToDelete] = useState(null);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({ query: "(max-width: 1023px)" });

  const sortKeyInitialState = {
    newest: false,
    oldest: false,
  };

  const [sortKey, setSortKey] = useState(sortKeyInitialState);

  const initialDataState = courses
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        id,
        generated_course_id,
        course_name,
        course_type,
        course_sections,
        updatedAt,
      }) => {
        let totalCourseLesson = 0;
        let totalCourseDuration = 0;

        for (let cs = 0; cs < course_sections.length; cs++) {
          const { lessons, total_course_duration_in_seconds } =
            course_sections[cs];
          totalCourseLesson += lessons.length;
          totalCourseDuration += total_course_duration_in_seconds;
        }

        totalCourseDuration = getTimeFromSeconds(totalCourseDuration);
        totalCourseDuration = `${
          totalCourseDuration.hours
            ? `${
                totalCourseDuration.hours > 1
                  ? `${totalCourseDuration.hours} hours`
                  : `${totalCourseDuration.hours} hour`
              }`
            : ``
        } ${
          totalCourseDuration.minutes
            ? `${
                totalCourseDuration.minutes > 1
                  ? `${totalCourseDuration.minutes} mins`
                  : `${totalCourseDuration.minutes} min`
              }`
            : ``
        } ${
          !totalCourseDuration.hours && totalCourseDuration.seconds
            ? `${
                totalCourseDuration.seconds > 1
                  ? `${totalCourseDuration.seconds} secs`
                  : `${totalCourseDuration.seconds} sec`
              }`
            : ``
        }`;

        let totalCourseLessonAndDuration = `${
          totalCourseLesson > 1
            ? `${totalCourseLesson} lessons`
            : `${totalCourseLesson} lesson`
        },${totalCourseDuration}`;

        const date = new Date(updatedAt).toLocaleString();

        return {
          id,
          course_id: generated_course_id,
          course_name,
          course_type,
          course_length: totalCourseLessonAndDuration,
          total_enrollment: "103 students", // need to adjust handson,
          updatedAt: date,
        };
      }
    );

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
  }, []);

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleOnClickRow(d, key, value) {
    navigate(`/internal/courses/${d["id"]}`);
  }

  function handleOnSort(type) {
    setIsLoading(true);
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
    setIsLoading(false);
  }

  const handleFetchTopics = async () => {
    setIsLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
    setIsLoading(false);
  };

  const handleAllCourses = async () => {
    setIsLoading(true);
    const result = await getAllCourses();
    if (result && result.data) {
      let finalCourses = result.data;
      finalCourses =
        finalCourses && Array.isArray(finalCourses) && finalCourses.length > 0
          ? finalCourses.map((course) => {
              const { StudentCourses } = course || {};
              return {
                ...course,
                total_enrollment:
                  (StudentCourses && StudentCourses.length) || 0,
              };
            })
          : [];
      setCourses(finalCourses);
    }
    setIsLoading(false);
  };

  function handleAddCourse() {
    return navigate(`/internal/courses/add`);
  }

  const handleFormatFirstFetchPaginatedData = (courses, searchKey) => {
    let initialDataState = courses
      .sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      })
      .map(
        ({
          id,
          generated_course_id,
          course_name,
          course_type,
          course_sections,
          updatedAt,
          total_enrollment,
        }) => {
          let totalCourseLesson = 0;
          let totalCourseDuration = 0;

          for (let cs = 0; cs < course_sections.length; cs++) {
            const { lessons, total_course_duration_in_seconds } =
              course_sections[cs];
            totalCourseLesson += lessons.length;
            totalCourseDuration += total_course_duration_in_seconds;
          }

          totalCourseDuration = getTimeFromSeconds(totalCourseDuration);
          totalCourseDuration = `${
            totalCourseDuration.hours
              ? `${
                  totalCourseDuration.hours > 1
                    ? `${totalCourseDuration.hours} hours`
                    : `${totalCourseDuration.hours} hour`
                }`
              : ``
          } ${
            totalCourseDuration.minutes
              ? `${
                  totalCourseDuration.minutes > 1
                    ? `${totalCourseDuration.minutes} mins`
                    : `${totalCourseDuration.minutes} min`
                }`
              : ``
          } ${
            !totalCourseDuration.hours && totalCourseDuration.seconds
              ? `${
                  totalCourseDuration.seconds > 1
                    ? `${totalCourseDuration.seconds} secs`
                    : `${totalCourseDuration.seconds} sec`
                }`
              : ``
          }`;

          let totalCourseLessonAndDuration = `${
            totalCourseLesson > 1
              ? `${totalCourseLesson} lessons`
              : `${totalCourseLesson} lesson`
          },${totalCourseDuration}`;

          const date = new Date(updatedAt).toLocaleString();

          return {
            id,
            course_id: generated_course_id,
            course_name,
            course_type,
            course_length: totalCourseLessonAndDuration,
            total_enrollment: `${total_enrollment} ${
              total_enrollment > 1 ? "students" : "student"
            }`,
            updatedAt: date,
          };
        }
      );
    if (searchKey) {
      initialDataState = initialDataState.filter((data) => {
        const { course_id, course_name, course_type } = data || {};
        let lowercaseSearchkey = searchKey.toLowerCase();
        let courseId = course_id.toLowerCase();
        let courseName = course_name.toLowerCase();
        let courseType = course_type.toLowerCase();
        if (
          courseId.includes(lowercaseSearchkey) ||
          courseName.includes(lowercaseSearchkey) ||
          courseType.includes(lowercaseSearchkey)
        ) {
          return data;
        }
      });
    }
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  const onDeleteCourse = (d) => {
    const id = d["id"];
    setSavedCourseIdToDelete(id);
    setIsOpenModalDelete(true);
  };

  function handleCancelDeleteCourse(e) {
    e.preventDefault();
    setIsOpenModalDelete(false);
    setSavedCourseIdToDelete(null);
  }

  const handleDeleteCourse = async () => {
    setIsLoading(true);
    try {
      const response = await deleteCourse(savedCourseIdToDelete);
      if (response && response.data) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          position: "top-end",
          text: response.data.message,
          width: "20em",
          showConfirmButton: false,
          timer: 1500,
        });
        handleAllCourses();
        setIsOpenModalDelete(false);
      }
    } catch (err) {
      console.log(err, "error line 344 internalCourses.js <<");
      setIsLoading(false);
      Swal.fire({
        customClass: { popup: "mentor-popup" },
        position: "top-end",
        icon: "error",
        title: err.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleEditCourse = (d) => {
    const id = d["id"];
    navigate(`/internal/courses/edit/${id}`);
  };

  useEffect(() => {
    handleFetchTopics();
    handleAllCourses();
  }, []);

  useEffect(() => {
    if (courses && Array.isArray(courses) && courses.length > 0) {
      handleFormatFirstFetchPaginatedData(courses);
    }
  }, [courses]);

  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFormatFirstFetchPaginatedData(courses);
    } else {
      handleFormatFirstFetchPaginatedData(courses, searchKey);
    }
  };

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  return (
    <div
      style={{
        marginLeft: smallScreen ? "1em" : "",
        marginRight: smallScreen ? "1em" : "",
      }}
    >
      <HeaderComponent />
      <SidebarInteralComponent />
      <InternalCourseContainer>
        {isLoading ? (
          <SpinnerComponent
            isShown={isLoading}
            styles={{
              right: 0,
              left: 0,
              bottom: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "290px",
            }}
          />
        ) : (
          <MyBookingInnerContainer className="myBookingInnerContainer">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FilterSortButton onClick={handleOnClickSortButton}>
                <img
                  src={sort_icon}
                  alt="sort_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Sort
              </FilterSortButton>
              <AddCourseContainer>
                <AddCourseButton onClick={handleAddCourse}>
                  <AddCourseText>Add new course</AddCourseText>
                  <AddCourseImage
                    src={add_new_mentor_icon}
                    alt="add_new_mentor_icon"
                  />
                </AddCourseButton>
              </AddCourseContainer>
            </div>
            <SortModal
              show={isSortModalOpened.toString()}
              handleClose={() => {
                setSortModalOpened(false);
              }}
              handleOnSort={handleOnSort}
              sortKey={sortKey}
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              itemOffset={itemOffset + 1}
              endOffset={itemOffset + perPage}
              totalItems={initialDataState.length}
              hide={data.length <= perPage}
            >
              {paginatedData &&
              Array.isArray(paginatedData) &&
              paginatedData.length > 0 ? (
                <div>
                  {smallScreen ? (
                    <div>
                      {paginatedData.map((data) => {
                        const {
                          id,
                          course_id,
                          course_name,
                          course_type,
                          course_length,
                          total_enrollment,
                        } = data || {};
                        return (
                          <EachMobileDataMainContainer key={id}>
                            <EachMobileDataContainer>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginBottom: "0.5em",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.5em",
                                    width: "50%",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "10px",
                                    }}
                                  >
                                    Course ID:
                                  </div>
                                  <div style={{ fontSize: "10px" }}>
                                    {course_id}
                                  </div>
                                </div>
                                <div style={{ display: "flex", gap: "1em" }}>
                                  {/* <img src={}/> */}
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#00bb99",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#00bb99",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex", gap: "0.5em" }}>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  Course name:
                                </div>
                                <div style={{ fontSize: "10px" }}>
                                  {course_name}
                                </div>
                              </div>
                              <div style={{ display: "flex", gap: "0.5em" }}>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  Course type:
                                </div>
                                <div style={{ fontSize: "10px" }}>
                                  {course_type}
                                </div>
                              </div>
                              <div style={{ display: "flex", gap: "0.5em" }}>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  Course length:
                                </div>
                                <div style={{ fontSize: "10px" }}>
                                  {course_length}
                                </div>
                              </div>
                              <div style={{ display: "flex", gap: "0.5em" }}>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  Total enrollment:
                                </div>
                                <div style={{ fontSize: "10px" }}>
                                  {total_enrollment}
                                </div>
                              </div>
                            </EachMobileDataContainer>
                          </EachMobileDataMainContainer>
                        );
                      })}
                    </div>
                  ) : (
                    <TableInternalComponent
                      table_data={paginatedData}
                      topics={topics}
                      onClickRow={handleOnClickRow}
                      table_name="Courses"
                      is_editable={true}
                      is_deleteable={
                        userDetail &&
                        userDetail.role &&
                        userDetail.role.id &&
                        +userDetail.role.id === 1
                      }
                      onClickEdit={handleEditCourse}
                      onClickDelete={onDeleteCourse}
                    />
                  )}
                </div>
              ) : (
                <div className="sessionHistoryInnerContainerEmptySession">
                  <img src={empty_session_2_icon} alt="empty_session_2_icon" />
                  <h4 style={{ fontSize: "22px" }}>There is no data.</h4>
                  {courses.length <= 0 && (
                    <AddCourseContainer>
                      <AddCourseButton onClick={handleAddCourse}>
                        <AddCourseText>Add new course</AddCourseText>
                        <AddCourseImage
                          src={add_new_mentor_icon}
                          alt="add_new_mentor_icon"
                        />
                      </AddCourseButton>
                    </AddCourseContainer>
                  )}
                </div>
              )}
            </Pagination>
            <ModalComponent
              show={isOpenModalDelete}
              body={[
                <p style={{ fontWeight: "700" }} key={0}>
                  Are you sure want to delete this course?
                </p>,
              ]}
              buttonPrimary="Yes"
              buttonSecondary="No"
              onPrimaryClick={() => handleDeleteCourse()}
              onSecondaryClick={(e) => handleCancelDeleteCourse(e)}
            />
          </MyBookingInnerContainer>
        )}
      </InternalCourseContainer>
    </div>
  );
};

export default InternalCourses;

const InternalCourseContainer = styled.div`
  @media ${device.tablet} {
    padding-left: 290px;
  }
  @media ${device.laptop} {
    padding-left: 290px;
  }
  @media ${device.laptopM} {
    padding-left: 290px;
  }
  @media ${device.laptopL} {
    padding-left: 290px;
  }
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

const AddCourseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AddCourseButton = styled.button`
  display: flex;
  background: #e5e5e5;
  border-radius: 3px;
  border: transparent;
  padding: 0.5em 1em;
  transition: 0.2s;
  &:hover {
    border: 1px solid #00bb99;
  }
`;

const AddCourseText = styled.span`
  margin-right: 0.5em;
`;

const AddCourseImage = styled.img`
  max-width: 25px;
`;

const MyBookingInnerContainer = styled.div`
  padding: 80px 3vw 90px 3vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const EachMobileDataMainContainer = styled.div`
  border-radius: 10px;
  width: 100%;
  border: 1px solid #00bb99;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const EachMobileDataContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
`;
