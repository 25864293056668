import Axios from "../../helpers/axios";

export const getAllWebinars = () => {
  return Axios.get(`/webinars`);
};

export const getWebinarDetail = (webinar_id) => {
  return Axios.get(`/webinars/${webinar_id}`);
};

export const getAllStudentWebinars = () => {
  return Axios.get(`/webinars/all/student-webinars`);
};

export const createNewStudentWebinar = (webinar_id, body) => {
  return Axios.post(`/webinars/${webinar_id}/create/student-webinars`, body);
};
