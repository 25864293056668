import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import UserForm from "../components/profile/UserForm";
import SidebarInteralComponent from "../components/SidebarInternalComponent";

// Helpers
import { device } from "../helpers/device";

// Apis
import {
  getAllDegrees,
  getAllIndustries,
  getAllOccupations,
} from "../apis/general";

// react-responsive
import { useMediaQuery } from "react-responsive";

const Profile = () => {
  const userDetail = useSelector((state) => state.userDetail);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [isLoading, setLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [occupations, setOccupations] = useState(null);
  const [degrees, setDegrees] = useState(null);

  const handleFetchIndustries = async () => {
    const { data: industries } = await getAllIndustries();
    if (industries) {
      setIndustries(industries);
    }
  };

  const handleFetchOccupations = async () => {
    const { data: occupations } = await getAllOccupations();
    if (occupations) {
      setOccupations(occupations);
    }
  };

  const handleFetchDegrees = async () => {
    const { data: degrees } = await getAllDegrees();
    if (degrees) {
      setDegrees(degrees);
    }
  };

  const handleChangeIsNewUserState = (propIsNewUser) => {
    setIsNewUser(propIsNewUser);
  };

  useEffect(() => {
    handleFetchIndustries();
    handleFetchOccupations();
    handleFetchDegrees();
  }, []);

  useEffect(() => {
    if (userDetail) {
      handleChangeIsNewUserState(userDetail.isNewUser);
    }
  }, [userDetail]);

  return (
    <div>
      {userDetail &&
      userDetail.role &&
      userDetail.role.id &&
      +userDetail.role.id === 3 ? (
        <SidebarComponent />
      ) : (
        <SidebarInteralComponent />
      )}
      <div style={{ paddingLeft: smallScreen || mediumScreen ? "" : "290px" }}>
        <HeaderComponent />
        <MyProfileInnerContainer>
          {userDetail && industries && occupations && degrees && (
            <UserForm
              user={userDetail}
              isNewUser={isNewUser}
              setIsNewUser={setIsNewUser}
              industries={industries}
              occupations={occupations}
              degrees={degrees}
              setLoading={setLoading}
              isLoading={isLoading}
            />
          )}
        </MyProfileInnerContainer>
      </div>
    </div>
  );
};

export default Profile;

const MyProfileInnerContainer = styled.div`
  padding: 80px 8vw 90px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;
