import React from "react";
import moment from "moment";

import "./DateLabel.css";

function DateLabel({ date, style, showMonth }) {
  return (
    <div className="date-label-secondary" style={style}>
      <span className="date-label-secondary-day">
        {moment(date).format("ddd").toUpperCase()}
      </span>
      {showMonth ? (
        <div style={{ display: "flex", gap: "0.25em" }}>
          <span className="date-label-secondary-date">
            {moment(date).format("DD")}
          </span>
          <span className="date-label-secondary-date">
            {moment(date).format("MMM")}
          </span>
        </div>
      ) : (
        <span className="date-label-secondary-date">
          {moment(date).format("DD")}
        </span>
      )}
    </div>
  );
}

export default DateLabel;
