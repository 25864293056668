import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// react big calendar
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

// moment js
import moment from "moment";

// Components
import EdotCalendarToolbar from "./WebinarCalendarToolbar";
import EdotCalendarEvent from "./WebinarCalendarEvent";
import CustomAgendaView from "./CustomAgendaView";

// styles
// import "../../styles/BigCalendar.css";

const BigCalendar = (props) => {
  const navigate = useNavigate();

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [currentView, setCurrentView] = useState(Views.MONTH);

  const localizer = momentLocalizer(moment);

  const { events } = props || {};

  const toggleWebinarDetails = (event) => {
    navigate(`/webinars/${event.id}`);
  };

  const { components, defaultDate, defaultView } = useMemo(
    () => ({
      components: {
        toolbar: (props) => {
          return (
            <EdotCalendarToolbar
              date={props.date}
              onView={props.onView}
              onNavigate={props.onNavigate}
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          );
        },
        month: {
          event: (props) => (
            <EdotCalendarEvent
              event={props.event}
              title={props.title}
              currentView={currentView}
              toggleWebinarDetails={toggleWebinarDetails}
            />
          ),
        },
      },
      defaultDate: new Date(),
      defaultView: Views.MONTH,
    }),
    [currentView]
  );

  return (
    <div className="custom-container">
      <div
        style={{
          marginBottom: smallScreen || mediumScreen ? "50px" : "75px",
          textAlign: "left",
        }}
      >
        <div style={{ fontSize: "30px", color: "#2c65f7", fontWeight: "bold" }}>
          Upcoming Schedule
        </div>
      </div>
      {/* <div className="header-container">
        <h1
          style={{
            fontSize: 30,
            color: "#2c65f7",
            gap: "20px",
          }}
        >
          Upcoming Schedule
        </h1>
      </div> */}

      <div
        className="calendar-container"
        style={{ height: currentView === "month" ? "100vh" : "" }}
      >
        <Calendar
          components={components}
          selectable
          // step={60}
          // timeslots={1}
          defaultDate={defaultDate}
          defaultView={defaultView}
          events={events}
          localizer={localizer}
          popup
          // views={Object.keys(Views).map((k) => Views[k])}
          views={{
            month: true,
            agenda: CustomAgendaView,
          }}
        />
      </div>
    </div>
  );
};

export default BigCalendar;
