import React from "react";
import styled from "styled-components";
import capitalizeFirstLetter from "../../helpers/capitalize_first_letter";

function RadioButton({
  data = typeof Array,
  state = typeof String,
  handleChange = typeof Function,
  name = typeof String,
  disabled = false,
  user_detail,
  flexColumn,
}) {
  return (
    <RadioWrapper disabled={disabled} flexColumn={flexColumn}>
      {data &&
        data.map((item, index) => (
          <RadioItem key={index}>
            <RadioButtonInput
              id={`${name}-${index}`}
              type="radio"
              name={name}
              value={item}
              checked={state === item}
              onChange={handleChange}
              disabled={disabled}
              user_detail={user_detail}
            />
            <RadioButtonLabel />
            <label htmlFor={`${name}-${index}`}>
              {capitalizeFirstLetter(item)}
            </label>
          </RadioItem>
        ))}
    </RadioWrapper>
  );
}

export default RadioButton;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexColumn }) => (flexColumn ? "column" : "row")};
  height: auto;
  width: 100%;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  margin-right: 1em;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButtonInput = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover {
    background: #bebebe;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 6px;
      background: #eeeeee;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: ${({ user_detail }) =>
      user_detail &&
      user_detail.role &&
      user_detail.role.id &&
      +user_detail.role.id === 3
        ? "#2c65f7"
        : "#00bb99"};
    border: ${({ user_detail }) =>
      user_detail &&
      user_detail.role &&
      user_detail.role.id &&
      +user_detail.role.id === 3
        ? "1px solid #2c65f7"
        : "1px solid #00bb99"};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 5px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
`;
