import moment from "moment";

export const humanize = (str) => {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export const textToLowerCaseWithDash = (text) => {
  return text.toLowerCase().replace(/ /g, "-");
};

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

// Return date into day, dd/mm/yyyy
export function dateFormat(date) {
  let day = new Date(date).getDay();
  //   let newDate = new Date(date).toLocaleDateString();
  let newDate = moment(new Date(date)).format("DD/MM/YYYY HH:mm");
  if (day === 0) {
    day = "Sun";
  } else if (day === 1) {
    day = "Mon";
  } else if (day === 2) {
    day = "Tue";
  } else if (day === 3) {
    day = "Wed";
  } else if (day === 4) {
    day = "Thu";
  } else if (day === 5) {
    day = "Fri";
  } else {
    day = "Sat";
  }

  return `${day}, ${newDate}`;
}
