export const createStateOptions = (states) => {
  const newStates = states.map((state) => {
    const { name } = state;
    const objState = {
      label: name,
      value: name,
    };
    return objState;
  });
  if (newStates && Array.isArray(newStates) && newStates.length > 0) {
    return newStates;
  }
};
