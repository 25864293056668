import React from "react";

const InputText = (props) => {
  const {
    value,
    placeholder,
    name,
    fontWeight,
    fontSize,
    setter,
    paddingLeft,
    functionSetter,
    phoneNumberState,
    setterPhoneNumber,
    disabled,
    type,
    onKeyDown,
    className,
    fontColor,
  } = props;

  return (
    <input
      type={type ? type : "text"}
      id="myInputText"
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value } }) =>
        functionSetter
          ? functionSetter(value, phoneNumberState, setterPhoneNumber)
          : setter(value)
      }
      className={className}
      disabled={disabled}
      onKeyDown={onKeyDown ? onKeyDown : null}
      style={{
        paddingLeft: paddingLeft ? paddingLeft : "1em",
        fontStyle: "normal",
        fontWeight: fontWeight ? fontWeight : 400,
        fontSize: fontSize ? fontSize : "16px",
        color: fontColor,
        ...props,
      }}
    />
  );
};

export default InputText;
