import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// react-bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Apis
import { getWebinarDetail } from "../apis/clientApis/webinars";

// Images and Icons
import course_length_icon from "../assets/icons/course_length_icon.svg";

// components
import SpinnerComponent from "../components/SpinnerComponent";
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import LoadingModalMobile from "../components/LoadingModalMobile";

// react-responsive
import { useMediaQuery } from "react-responsive";

// Helpers
import { device } from "../helpers/device";
import { dateFormat } from "../utils/common";
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

const WebinarDetail = () => {
  const navigate = useNavigate();
  const { webinar_id } = useParams();

  const userDetail = useSelector((state) => state.userDetail);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [webinarDetail, setWebinarDetail] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleGetWebinarDetail = async (webinar_id) => {
    setLoading(true);
    const result = await getWebinarDetail(webinar_id);
    if (result && result.data) {
      const { data } = result || {};
      setWebinarDetail(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (webinar_id) {
      handleGetWebinarDetail(webinar_id);
    }
  }, [webinar_id]);

  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <OuterContainer>
          <WebinarInnerContainer>
            {!isLoading && webinarDetail ? (
              <>
                <Row>
                  <Col
                    style={{
                      marginBottom: smallScreen ? "" : "2vh",
                      gap: "10vw",
                      marginLeft: smallScreen ? "" : "20vh",
                      marginRight: smallScreen ? "" : "20vh",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4vh",
                      }}
                    >
                      <img
                        src={webinarDetail?.image_cover_url}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                          borderRadius: "30px",
                        }}
                        alt="courseImage"
                      />
                    </div>
                  </Col>
                </Row>
                <Col style={{ marginBottom: "3em" }}>
                  <TextTitle>
                    {capitalizeFirstLetter(webinarDetail?.title)}
                  </TextTitle>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5em",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={course_length_icon}
                      alt="course length icon"
                      style={{
                        width: "20px",
                        height: "auto",
                      }}
                    />
                    <div style={{ fontSize: "15px" }}>
                      {webinarDetail && webinarDetail.webinar_schedule_date
                        ? dateFormat(webinarDetail.webinar_schedule_date)
                        : "Not available"}
                    </div>
                  </div>
                </Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: smallScreen ? "column" : "row",
                    alignItems: smallScreen ? "start" : "center",
                    gap: smallScreen ? "1em" : "2em",
                    marginBottom: "3em",
                  }}
                >
                  <Label user_detail={userDetail}>Webinar materials</Label>
                  <div
                    style={{
                      // backgroundColor: added_to_users_webinar
                      //   ? "#cccccc"
                      //   : "#2c65f7",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#2c65f7",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      padding: smallScreen ? "1vh 5vw" : "1vh 1vw",
                      cursor: "pointer",
                      fontSize: smallScreen ? "14px" : "16px",
                    }}
                    //   onClick={() =>
                    //     added_to_users_webinar
                    //       ? null
                    //       : handleStudentRegisterWebinar(id)
                    //   }
                  >
                    <div>Available to download</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: smallScreen ? "column" : "row",
                    alignItems: smallScreen ? "start" : "center",
                    gap: smallScreen ? "1em" : "2em",
                  }}
                >
                  <Label user_detail={userDetail}>
                    Certificate of attendance
                  </Label>
                  <div
                    style={{
                      // backgroundColor: added_to_users_webinar
                      //   ? "#cccccc"
                      //   : "#2c65f7",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#2c65f7",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      padding: smallScreen ? "1vh 5vw" : "1vh 1vw",
                      cursor: "pointer",
                      fontSize: smallScreen ? "14px" : "16px",
                    }}
                    //   onClick={() =>
                    //     added_to_users_webinar
                    //       ? null
                    //       : handleStudentRegisterWebinar(id)
                    //   }
                  >
                    <div>Available to download</div>
                  </div>
                </div>
              </>
            ) : null}
          </WebinarInnerContainer>
        </OuterContainer>
      )}
    </div>
  );
};

export default WebinarDetail;

const OuterContainer = styled.div`
  text-align: left;
  @media ${device.laptopM} {
    padding-left: 290px;
  }
  @media ${device.laptopL} {
    padding-left: 290px;
  }
`;

const WebinarInnerContainer = styled.div`
  padding: 80px 8vw 130px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 130px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 130px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const Label = styled.h5`
  font-size: 20px;
  color: ${({ user_detail }) =>
    user_detail &&
    user_detail.role &&
    user_detail.role.id &&
    +user_detail.role.id === 3
      ? "#2c65f7"
      : "#00bb99"};
`;

const TextTitle = styled.div`
  font-size: 25px;
  color: black;
  font-weight: bold;
`;
