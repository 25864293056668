import React from "react";
import {
  CCol,
  CWidgetStatsA,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CDropdownItem,
  CButton,
} from "@coreui/react-pro";
import "@coreui/coreui/dist/css/coreui.min.css";

import { useSelector } from "react-redux";

// react-responsive
import { useMediaQuery } from "react-responsive";

// images & icons
import filter_icon from "../assets/icons/filter_icon.svg";

// Components
// import FilterModal from "./FilterModal";
import FilterDateModal from "./FilterDateModal";

const Widget = (props) => {
  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const userDetail = useSelector((state) => state.userDetail);

  const {
    widgetData,
    handleOnClickWidgetFilterCustomDate,
    handleOnCloseWidgetFilterCustomDate,
    handleOnFilter,
    handleOnClickFilterBy,
  } = props || {};

  return (
    <CCol sm={4}>
      <CWidgetStatsA
        className="mb-4"
        color={widgetData.widgetColor || ""}
        style={{
          backgroundColor: widgetData.widgetColor
            ? ""
            : widgetData.backgroundColor,
          height: "150px",
        }}
        value={
          <div
            style={{ color: "#ffffff", fontSize: "30px", fontWeight: "bold" }}
          >
            {widgetData.value}
          </div>
        }
        title={
          <div
            style={{ color: "#ffffff", fontSize: "18px", fontWeight: "500" }}
          >
            {widgetData.title}
          </div>
        }
        action={
          // <CDropdown alignment="end">
          //   <CIcon icon={cilArrowRight} className="text-white" size="xl" />
          // </CDropdown>
          <CDropdown alignment="end">
            <CDropdownToggle color="transparent" caret={false} className="p-0">
              <CButton
                color="light"
                style={{
                  color: "#000000",
                  fontWeight: "500",
                  border: "1px solid #ffffff",
                }}
              >
                {widgetData && widgetData.filterBy ? (
                  <div style={{ fontSize: "14px" }}>{widgetData.filterBy}</div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={filter_icon}
                      alt="filter_icon"
                      height={15}
                      width={15}
                      style={{ objectFit: "scale-down" }}
                    />
                  </div>
                )}
              </CButton>
            </CDropdownToggle>
            {!widgetData.isOpenCustomFilter ? (
              <CDropdownMenu>
                <CDropdownItem
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOnClickFilterBy(widgetData, "Today")}
                >
                  Today
                </CDropdownItem>
                <CDropdownItem
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOnClickFilterBy(widgetData, "This week")}
                >
                  This week
                </CDropdownItem>
                <CDropdownItem
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleOnClickFilterBy(widgetData, "This month")
                  }
                >
                  This month
                </CDropdownItem>
                <CDropdownItem
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleOnClickWidgetFilterCustomDate(widgetData)
                  }
                >
                  Custom date
                </CDropdownItem>
                <CDropdownItem
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleOnFilter("reset", null, null, widgetData)
                  }
                >
                  Reset Filter
                </CDropdownItem>
              </CDropdownMenu>
            ) : null}
          </CDropdown>
        }
      />
      <FilterDateModal
        show={widgetData.isOpenCustomFilter}
        dateTimeButtonList={[]}
        filterKey={widgetData.filterKey}
        internalDashboard
        user_detail={userDetail}
        smallScreen={smallScreen}
        maxWidth={"350px"}
        handleClose={() => {
          handleOnCloseWidgetFilterCustomDate(widgetData);
        }}
        title={widgetData.title}
        isFilterChanged={widgetData.isFilterChanged}
        handleOnFilter={handleOnFilter}
        data={widgetData}
      />
    </CCol>
  );
};

export default Widget;
