import React from "react";

import "../styles/template.css";

const template = () => {
  return (
    <div className="container">
      <div className="header">
        <div className="headerContainer">
          <div className="headerImageLogoContainer">
            <img
              src="https://storage.googleapis.com/ccs-files/14102024738_cloudxier_logo_dark.png"
              alt="Cloudxier Logo"
            />
          </div>
        </div>
      </div>
      <div className="headerWebinarTitle">
        <div className="headerWebinarTitleParticipationStyle">
          PARTICIPATION
        </div>
        <div className="headerWebinarTitleCertificateStyle">CERTIFICATE</div>
      </div>
      <div className="awardedToStyle">
        <div>This certificate is awarded to</div>
      </div>
      <div className="participantNameStyle">Nama peserta webinar</div>
      <div className="participantNameUnderlineContainer">
        <div className="participantNameUnderlineStyle"></div>
      </div>
      <div className="webinarTitleAndWebinarDateContainer">
        <div>
          As the certificate of attending <b>Webinar title</b>
        </div>
        <div>
          that held on <b>Webinar date</b>
        </div>
      </div>
      <div className="signatureUnderlineContainer">
        <div className="signatureUnderlineStyle"></div>
      </div>

      {/* <div className="details">
        <p>
          In recognition of active engagement and valuable contributions
          demonstrated during three-day international workshop on "Development
          of Human Resource" held on 12-14 March 2023 at Copenhagen, Denmark.
        </p>
        <p>Presented on: 15 March 2023</p>
      </div> */}

      {/* <div className="signature">
        <img src="signature.png" alt="Signature" />
        <p>John Doe</p>
        <p>Workshop Coordinator</p>
      </div> */}

      <div className="footer">
        <p>Tanggal webinar diterbitkan</p>
      </div>
    </div>
  );
};

export default template;
