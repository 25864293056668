import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Collapsible from "react-collapsible";
import { DayPicker } from "react-day-picker";
import { textToLowerCaseWithDash } from "../utils/common";

import {
  addMonths,
  isSameMonth,
  format,
  isAfter,
  isBefore,
  isValid,
  parse,
} from "date-fns";

// Images & Icons
import dropdown_green_icon from "../assets/icons/dropdown_green_icon.svg";

function FilterDateModal({
  show,
  handleClose,
  title,
  subtitle,
  body,
  buttonPrimary,
  buttonSecondary,
  closeButton = true,
  onPrimaryClick,
  onSecondaryClick,
  maxWidth,
  bodyTextAlign = "center",
  buttonDirection = "row",
  primaryColor = "#00BB99",
  secondaryColor = "#AAAAAA",
  primaryTextColor = "#FFFFFF",
  secondaryTextColor = "#FFFFFF",
  internalDashboard,
  user_detail,
  smallScreen,
  handleOnFilter,
  filterKey,
  dateTimeButtonList,
  isFilterChanged,
  data,
}) {
  const today = new Date();
  const nextMonth = addMonths(new Date(), 1);
  const [month, setMonth] = useState(nextMonth);
  const [selectedDateTimeButton, setSelectedDateTimeButton] = useState(null);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const handleFromChange = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());
    if (!isValid(date)) {
      return handleOnFilter(
        "date-time",
        "datetime",
        {
          from: undefined,
          to: undefined,
        },
        data
      );
    }
    if (filterKey.dateTime?.to && isAfter(date, filterKey.dateTime.to)) {
      handleOnFilter(
        "date-time",
        "datetime",
        {
          from: filterKey.dateTime.to,
          to: date,
        },
        data
      );
    } else {
      handleOnFilter(
        "date-time",
        "datetime",
        {
          from: date,
          to: filterKey.dateTime?.to,
        },
        data
      );
      setMonth(date);
    }
  };

  const handleToChange = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());

    if (!isValid(date)) {
      return handleOnFilter(
        "date-time",
        "datetime",
        {
          from: filterKey.dateTime?.from,
          to: undefined,
        },
        data
      );
    }
    if (filterKey.dateTime?.from && isBefore(date, filterKey.dateTime.from)) {
      handleOnFilter(
        "date-time",
        "datetime",
        {
          from: date,
          to: filterKey.dateTime.from,
        },
        data
      );
    } else {
      handleOnFilter(
        "date-time",
        "datetime",
        {
          from: filterKey.dateTime?.from,
          to: date,
        },
        data
      );
      setMonth(date - 1);
    }
  };

  const handleRangeSelect = (range) => {
    handleOnFilter("date-time", "datetime", range, data);
    if (range?.from) {
      setFromValue(format(range.from, "y-MM-dd"));
    } else {
      setFromValue("");
    }
    if (range?.to) {
      setToValue(format(range.to, "y-MM-dd"));
    } else {
      setToValue("");
    }
  };

  function goToDate() {
    setMonth(month);
  }

  function handleChooseDateTime(type) {
    setSelectedDateTimeButton(type);
    switch (type) {
      case "this-week":
        let curr = new Date();
        let first = curr.getDate() - curr.getDay();
        let last = first + 6;

        let from = new Date(curr.setDate(first));
        let to = new Date(curr.setDate(last));

        if (from) {
          setFromValue(format(from, "y-MM-dd"));
        } else {
          setFromValue("");
        }
        if (to) {
          setToValue(format(to, "y-MM-dd"));
        } else {
          setToValue("");
        }

        handleOnFilter("date-time", "datetime", { from, to }, data);
        break;
      case "this-month":
        const now = new Date();
        const date = new Date(now.getFullYear(), now.getMonth(), 1);

        const dates = [];

        while (date.getMonth() === now.getMonth()) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        if (dates[0]) {
          setFromValue(format(dates[0], "y-MM-dd"));
        } else {
          setFromValue("");
        }
        if (dates[dates.length - 1]) {
          setToValue(format(dates[dates.length - 1], "y-MM-dd"));
        } else {
          setToValue("");
        }
        handleOnFilter(
          "date-time",
          "datetime",
          {
            from: dates[0],
            to: dates[dates.length - 1],
          },
          data
        );
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    // Max width render conditional
    let modalDialog = document.getElementsByClassName(
      "modal-dialog modal-dialog-centered"
    )[0];
    if (modalDialog) {
      modalDialog.style.maxWidth = maxWidth;
    }
  });
  
  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton={closeButton}>
          <Modal.Title
            style={{
              textAlign: "center",
              width: "100%",
              paddingTop: "5px",
            }}
          >
            <h5>{title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: bodyTextAlign, marginTop: "-20px" }}>
          <ButtonGroup>
            {Object.keys(filterKey).some(
              (key) =>
                filterKey[key].length > 0 ||
                (filterKey[key].from && filterKey[key].to)
            ) && (
              <Button
                color="#AAAAAA"
                onClick={() => {
                  handleOnFilter("reset", null, null, data);
                  setFromValue("");
                  setToValue("");
                }}
              >
                Reset
              </Button>
            )}
            <Button
              color={
                user_detail && user_detail.is_admin === false
                  ? "#2c65f7"
                  : "#00BB99"
              }
              disabled={!isFilterChanged}
              onClick={(e) => {
                e.stopPropagation();
                isFilterChanged &&
                  handleOnFilter("apply-filter", null, null, data);
              }}
            >
              Apply Filter
            </Button>
          </ButtonGroup>
          <CustomCollapsible
            open={internalDashboard}
            transitionTime={150}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            trigger={
              <Title user_detail={user_detail}>
                <ImageContainer open={isOpen[2]}>
                  <img
                    src={dropdown_green_icon}
                    alt="dropdown_green_icon"
                    width={12}
                    height={8}
                  />
                </ImageContainer>
                Date/Time
              </Title>
            }
          >
            <Content smallScreen={smallScreen}>
              <CustomDayPickerForm>
                <CustomDayPickerInput
                  size={12}
                  placeholder="YYYY-MM-DD"
                  value={fromValue}
                  onChange={handleFromChange}
                />
                -
                <CustomDayPickerInput
                  size={12}
                  placeholder="YYYY-MM-DD"
                  value={toValue}
                  onChange={handleToChange}
                  disabled={!filterKey.dateTime.from}
                />
              </CustomDayPickerForm>
              <DayPickerContainer user_detail={user_detail}>
                <DayPicker
                  mode="range"
                  selected={filterKey.dateTime}
                  onSelect={(value) => {
                    setSelectedDateTimeButton(null);
                    handleOnFilter("date-time", "datetime", value, data);
                    handleRangeSelect(value);
                  }}
                  numberOfMonths={1}
                  pagedNavigation
                  captionLayout="dropdown"
                  fromYear={2015}
                  toYear={2030}
                  month={month}
                  onMonthChange={setMonth}
                  goToDate={goToDate}
                />
              </DayPickerContainer>
              <TopicButtonGroup>
                {dateTimeButtonList.map((button, index) => (
                  <CheckBoxButton onClick={() => setMonth(today)} key={index}>
                    <input
                      type="radio"
                      id={textToLowerCaseWithDash(button)}
                      name={"date-time"}
                      value={textToLowerCaseWithDash(button)}
                      onChange={() => {
                        handleChooseDateTime(textToLowerCaseWithDash(button));
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                    <TopicButton
                      checked={
                        textToLowerCaseWithDash(button) ===
                        selectedDateTimeButton
                      }
                      htmlFor={textToLowerCaseWithDash(button)}
                      style={{
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                      user_detail={user_detail}
                    >
                      {button}
                    </TopicButton>
                  </CheckBoxButton>
                ))}
              </TopicButtonGroup>
            </Content>
          </CustomCollapsible>
        </Modal.Body>
        {/* <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: buttonDirection,
          }}
        >
          {buttonSecondary && (
            <ButtonSecondary
              textColor={secondaryTextColor}
              color={secondaryColor}
              onClick={onSecondaryClick}
            >
              {buttonSecondary}
            </ButtonSecondary>
          )}
          {buttonPrimary && (
            <ButtonPrimary
              textColor={primaryTextColor}
              color={primaryColor}
              onClick={onPrimaryClick}
            >
              {buttonPrimary}
            </ButtonPrimary>
          )}
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default FilterDateModal;

// const ButtonPrimary = styled.button`
//   color: white;
//   background-color: ${({ color }) => color};
//   border-radius: 40px;
//   border: none;
//   font-weight: bold;
//   :hover {
//     background-color: ${({ color }) => color};
//     opacity: 0.8;
//   }
//   color: ${({ textColor }) => textColor};
//   padding: 0.5rem 1rem;
// `;
// const ButtonSecondary = styled.button`
//   background-color: ${({ color }) => color};
//   color: white;
//   border-radius: 40px;
//   border: none;
//   font-weight: bold;
//   :hover {
//     background-color: ${({ color }) => color};
//     opacity: 0.8;
//   }
//   color: ${({ textColor }) => textColor};
//   padding: 0.5rem 1rem;
// `;

const CustomCollapsible = styled(Collapsible)`
  width: 60%;
`;

const Title = styled.h5`
  font-size: 14px;
  font-weight: 700;
  color: ${({ user_detail }) =>
    user_detail && user_detail.is_admin === false ? "#2c65f7" : "#00bb99"};
  display: flex;
  gap: 10px;
  user-select: none;
  :hover {
    opacity: 0.75;
  }
`;

const ImageContainer = styled.div`
  transition: all 150ms ease;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "rotate(0deg)")};
`;

const Content = styled.div`
  margin-left: ${({ smallScreen }) => (smallScreen ? "0px" : "22px")};
  margin-bottom: 10px;
`;

const CustomDayPickerForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const CustomDayPickerInput = styled.input`
  font-size: 13px;
  padding: 5px 10px;
  text-align: center;
`;

const DayPickerContainer = styled.div`
  --rdp-cell-size: 33px;
  --rdp-accent-color: #00bb99;
  --rdp-background-color: #ebfcf9;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  h2 {
    font-size: 14px;
  }
  span {
    font-size: 12px;
  }
  .rdp {
    margin: 12px 0;
  }
  .rdp-head_cell {
    color: ${({ user_detail }) =>
      user_detail && user_detail.is_admin === false ? "#2c65f7" : "#00bb99"};
  }
`;

const TopicButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
`;

const CheckBoxButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  user-select: none;
  margin-bottom: 10px;
`;

const TopicButton = styled.label`
  background-color: ${({ checked, user_detail }) =>
    checked && user_detail && user_detail.is_admin === false
      ? "#2c65f7"
      : checked
      ? "#00BB99"
      : "transparent"};
  color: ${({ checked, user_detail }) =>
    checked
      ? "white"
      : !checked && user_detail && user_detail.is_admin === false
      ? "#2c65f7"
      : "#00BB99"};
  font-size: 12px;
  font-weight: 700;
  padding: 6px 12px;
  border: ${({ user_detail }) =>
    user_detail && user_detail.is_admin === false
      ? "1px solid #2c65f7"
      : "1px solid #00bb99"};
  border-radius: 40px;
  user-select: none;
  transition: all 150ms ease;
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 0;
  padding: 0 25px;
  display: flex;
  gap: 2px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ color }) => (color ? color : "#00BB99")};
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};
  pointer: ${({ disabled }) => (disabled ? "none" : "auto")};
  font-size: 12px;
  font-weight: 400;
  padding: 4px 12px;
  margin-top: -4px;
  user-select: none;
  :hover {
    font-weight: ${({ disabled }) => (disabled ? "400" : "700")};
  }
`;
