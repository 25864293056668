import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// react hook form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// sweetalert
import Swal from "sweetalert2";

// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// Constants
import { EMAIL_REGEX } from "../constants";

// Apis
import { createNewStudentWebinar } from "../apis/clientApis/webinars";
import { getAllCountries } from "../apis/general";

// react-responsive
import { useMediaQuery } from "react-responsive";

// helpers
import { device } from "../helpers/device";
import { createCountriesOptions } from "../helpers/get-country-options";
import { validatePhoneNumber } from "../helpers/validate-phone-number";

// components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import ModalComponent from "../components/ModalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import RadioButton from "../components/input/RadioButton";
import LoadingModalMobile from "../components/LoadingModalMobile";
import InputText from "../components/input/InputText";
import InputDropdown from "../components/input/InputDropdown";

const FormRegisterWebinar = () => {
  const navigate = useNavigate();
  const { webinar_id } = useParams();

  const userDetail = useSelector((state) => state.userDetail);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const [isLoading, setLoading] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [isModal, setModal] = useState(false);

  // const [state, setState] = useState(null);
  // const [isEmptyState, setIsEmptyState] = useState(false);

  // const [city, setCity] = useState(null);
  // const [isEmptyCity, setIsEmptyCity] = useState(false);

  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState("");
  const [isEmptyHowDidYouHearAboutUs, setIsEmptyHowDidYouHearAboutUs] =
    useState(false);
  const [
    isOpenInputTextHowDidYouHearAboutUs,
    setIsOpenInputTextHowDidYouHearAboutUs,
  ] = useState(false);

  // phone number state
  const [isOpenPhoneDropdown, setIsOpenPhoneDropdown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    icon: null,
    phone_code: "",
    country_id: "",
  });
  const [countryOptions, setCountryOptions] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: "all",
    defaultValues: {
      first_name: userDetail?.user_details?.first_name,
      last_name: userDetail?.user_details?.last_name,
      position: userDetail?.user_details?.job_position,
      email_address: userDetail?.email,
      company_name: userDetail?.user_details?.company_name,
      // phone_number: userDetail?.user_details?.phone_number,
      city: userDetail?.user_details?.city,
      state_or_province: userDetail?.user_details?.province,
      address: userDetail?.user_details?.address,
      // country_id: userDetail?.user_details?.country_id,
      postal_code: userDetail?.user_details?.postal_code,
    },
  });

  const formOptions = {
    first_name: {
      required: "First name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    last_name: {
      required: "Last name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    email_address: {
      required: "Email is required",
      pattern: {
        value: EMAIL_REGEX,
        message: "Invalid email address",
      },
    },
    // phone_number: {
    //   required: "Phone number is required",
    //   pattern: {
    //     value: /^\d+$/,
    //     message: "Invalid phone number",
    //   },
    //   maxLength: {
    //     value: 16,
    //     message: "Can't be longer than 16 characters",
    //   },
    // },
    company_name: {
      required: "Company is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    position: {
      required: "Position is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    city: {
      required: "City is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    state_or_province: {
      required: "State / Province is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    postal_code: {
      required: "Postal code is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    how_did_you_hear_about_us_other_value: {
      required: "This field is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
  };

  // Error handler from react-hook-form
  function ErrorHandler(name) {
    return (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <ErrorInput>{message}</ErrorInput>}
      />
    );
  }

  // Handle cancel
  function cancelAdd(e) {
    e.preventDefault();
    setModal(true);
  }

  // Handle primary modal
  function handlePrimary(e) {
    e.preventDefault();
    navigate("/internal/topics");
  }

  // Handle secondary modal
  function handleSecondary(e) {
    e.preventDefault();
    setModal(false);
  }

  const handleChangeDropdown = (value, setter) => {
    setter(value);
  };

  const handleChecOtherInputValidation = () => {
    // let isEmptyCity = false;
    // let isEmptyState = false;
    let isEmptyHowDidYouHearAboutUs = false;
    let isEmptyCountryId = false;
    let isErrorPhoneNumber = false;

    // if (!state) {
    //   isEmptyState = true;
    // }
    // if (!city) {
    //   isEmptyCity = true;
    // }
    if (!howDidYouHearAboutUs) {
      isEmptyHowDidYouHearAboutUs = true;
    }
    if (!phoneNumber.country_id) {
      isEmptyCountryId = true;
      setError("country_id", { message: "Country is required" });
    }
    if (phoneNumber && phoneNumber.number) {
      let isValidPhoneNumber = validatePhoneNumber(phoneNumber.number);
      if (!isValidPhoneNumber) {
        isErrorPhoneNumber = true;
        setError("phone_number", { message: "Invalid phone number" });
      }
    } else {
      isErrorPhoneNumber = true;
      setError("phone_number", { message: "Phone number is required" });
    }

    // setIsEmptyState(isEmptyState);
    // setIsEmptyCity(isEmptyCity);
    setIsEmptyHowDidYouHearAboutUs(isEmptyHowDidYouHearAboutUs);

    if (
      // isEmptyState !== true &&
      // isEmptyCity !== true &&
      isEmptyHowDidYouHearAboutUs !== true &&
      isEmptyCountryId !== true &&
      isErrorPhoneNumber !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Submit form
  async function handleSubmitForm(values) {
    let isValid = handleChecOtherInputValidation();
    if (isValid) {
      // setLoading(true);
      try {
        let body = {
          ...values,
          // city,
          // state,
          state: values["state_or_province"],
          phone_number: phoneNumber.number,
          // country_id: phoneNumber.country_id,
        };

        if (howDidYouHearAboutUs) {
          if (howDidYouHearAboutUs !== "other, please specify") {
            body = {
              ...body,
              how_did_you_hear_about_us: howDidYouHearAboutUs,
            };
          } else {
            body = {
              ...body,
              how_did_you_hear_about_us:
                values.how_did_you_hear_about_us_other_value,
            };
          }
        }
        const response = await createNewStudentWebinar(webinar_id, body);
        if (response && response.data && response.data.id) {
          Swal.fire({
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: `Successfully register to webinar.`,
            width: "20em",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
          navigate(`/webinars`);
        }
      } catch (err) {
        setLoading(false);
        Swal.fire({
          customClass: { popup: "mentor-popup" },
          position: "top-end",
          icon: "error",
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  const handleChangeHowDidYouHearAboutUs = (e) => {
    const value = e.target.value;
    if (value) {
      setHowDidYouHearAboutUs(value);
      if (value === "other, please specify") {
        setIsOpenInputTextHowDidYouHearAboutUs(true);
      } else {
        setIsOpenInputTextHowDidYouHearAboutUs(false);
      }
    }
  };

  const handlePopulateSomeInputs = (userDetail) => {
    const { user_details } = userDetail || {};

    let countryIcon;
    let number;
    let phone_code;
    let country_id;
    let label;
    let code;
    let id;

    // if (user_details && user_details.city) {
    //   setCity(user_details.city);
    // }

    if (user_details && user_details.country && user_details.country.id) {
      id = user_details.country.id;
    }
    if (user_details && user_details.country && user_details.country.name) {
      label = user_details.country.name;
    }
    if (user_details && user_details.country && user_details.country.code) {
      code = user_details.country.code;
      countryIcon = icon[`cif${user_details.country.code}`];
    }
    if (user_details && user_details.phone_number) {
      number = user_details.phone_number;
    }
    if (
      user_details &&
      user_details.country &&
      user_details.country.phone_code
    ) {
      phone_code = user_details.country.phone_code;
    }
    if (user_details && user_details.country && user_details.country.id) {
      country_id = user_details.country.id;
    }

    setPhoneNumber({
      ...phoneNumber,
      icon: countryIcon,
      phone_code,
      number,
      country_id,
      value: { label, value: code, phone_code, id },
    });
    fetchAllCountries();
  };

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    clearErrors("phone_number");

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };

  const fetchAllCountries = async () => {
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data));
      }
    }
  };

  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const {
      value: { code, phone_code, id },
      label = {},
    } = val || {};

    let countryIcon = "";
    if (code) {
      countryIcon = icon[`cif${code}`];
    }
    setterPhoneNumber({
      ...phoneNumberState,
      icon: countryIcon,
      phone_code: phone_code,
      country_id: id,
      value: { label, value: val.value },
    });
    clearErrors("country_id");
    setIsOpenPhoneDropdown(false);
  };

  useEffect(() => {
    if (userDetail) {
      handlePopulateSomeInputs(userDetail);
    }
  }, [userDetail]);

  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      <SpinnerComponent
        isShown={isLoading && !smallScreen && !mediumScreen}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {isLoading && (smallScreen || mediumScreen) ? (
        <LoadingModalMobile />
      ) : (
        <OuterContainer>
          <InnerContainer>
            <Row>
              {!smallScreen ? <PageTitle>Register Webinar</PageTitle> : null}
              <PageSubtitle smallScreen={smallScreen}>
                Fill in the fields below to continue register the Webinar`
              </PageSubtitle>
            </Row>
            <MentorInvisibleLine />
            <FormMentor onSubmit={handleSubmit(handleSubmitForm)}>
              <Row>
                <Col>
                  <FlexRow smallScreen={smallScreen}>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>First name</InputLabel>
                      <InputMentor
                        id="first_name"
                        name="first_name"
                        placeholder="Please fill in your first name"
                        {...register("first_name", formOptions.first_name)}
                      />
                      {ErrorHandler("first_name")}
                    </InputContainer>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>Last name</InputLabel>
                      <InputMentor
                        id="last_name"
                        name="last_name"
                        placeholder="Please fill in your last name"
                        {...register("last_name", formOptions.last_name)}
                      />
                      {ErrorHandler("last_name")}
                    </InputContainer>
                  </FlexRow>
                  <FlexRow smallScreen={smallScreen}>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>Email address</InputLabel>
                      <InputMentor
                        id="email_address"
                        name="email_address"
                        placeholder="Please fill in your email address"
                        {...register(
                          "email_address",
                          formOptions.email_address
                        )}
                      />
                      {ErrorHandler("email_address")}
                    </InputContainer>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>Phone number</InputLabel>
                      {/* <InputMentor
                        id="phone_number"
                        name="phone_number"
                        placeholder="Please fill in your phone number"
                        {...register("phone_number", formOptions.phone_number)}
                      />
                      {ErrorHandler("phone_number")} */}
                      <div className="inputPhoneBox">
                        <div
                          className="inputPhoneCode"
                          onClick={() =>
                            setIsOpenPhoneDropdown(!isOpenPhoneDropdown)
                          }
                          style={{
                            border:
                              errors &&
                              errors.country_id &&
                              !phoneNumber.country_id
                                ? "2px solid #EB4335"
                                : `2px solid ${
                                    userDetail && userDetail.is_admin === false
                                      ? "#2c65f7"
                                      : "#00BB99"
                                  }`,
                          }}
                        >
                          {phoneNumber && phoneNumber.icon ? (
                            <div style={{ width: "25px" }}>
                              <CIcon icon={phoneNumber.icon} size="sm" />
                            </div>
                          ) : (
                            "Country"
                          )}
                        </div>
                        <InputText
                          placeholder={"Fill in phone number"}
                          background={"transparent"}
                          name={"phoneNumberMyProfile"}
                          border={
                            errors && errors.phone_number
                              ? "2px solid #EB4335"
                              : `2px solid ${
                                  userDetail && userDetail.is_admin === false
                                    ? "#2c65f7"
                                    : "#00BB99"
                                }`
                          }
                          functionSetter={handleOnChangePhoneNumber}
                          phoneNumberState={phoneNumber}
                          setterPhoneNumber={setPhoneNumber}
                          className={"userFormInputStyle"}
                          value={
                            phoneNumber &&
                            phoneNumber.number &&
                            phoneNumber.phone_code
                              ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                              : phoneNumber &&
                                phoneNumber.phone_code &&
                                !phoneNumber.number
                              ? `(${phoneNumber.phone_code}) `
                              : phoneNumber &&
                                phoneNumber.number &&
                                !phoneNumber.phone_code
                              ? phoneNumber.number
                              : ""
                          }
                        />
                      </div>
                      <small
                        className="text-danger"
                        style={{
                          userSelect: "none",
                          display: "flex",
                          textAlign: "start",
                        }}
                      >
                        {!phoneNumber.country_id &&
                        !phoneNumber.number &&
                        errors?.country_id &&
                        errors.country_id.message &&
                        errors?.phone_number &&
                        errors.phone_number.message
                          ? "Country and Phone Number is required"
                          : (!phoneNumber.country_id &&
                              errors?.country_id &&
                              errors.country_id.message) ||
                            (errors?.phone_number &&
                              errors.phone_number.message)}
                      </small>
                      <div
                        className="inputContainer"
                        style={{ marginTop: "0" }}
                      >
                        <div>
                          {isOpenPhoneDropdown ? (
                            <InputDropdown
                              options={countryOptions}
                              styles={{
                                marginTop: "1em",
                                border: `2px solid ${
                                  userDetail && userDetail.is_admin === false
                                    ? "#2c65f7"
                                    : "#00BB99"
                                }`,
                                background: "transparent",
                                borderRadius: "10px",
                                height: "48px",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              state={phoneNumber}
                              setter={setPhoneNumber}
                              functionSetter={handleChangePhoneNumberDropdown}
                            />
                          ) : null}
                        </div>
                      </div>
                    </InputContainer>
                  </FlexRow>
                  <FlexRow smallScreen={smallScreen}>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>Company</InputLabel>
                      <InputMentor
                        id="company_name"
                        name="company_name"
                        placeholder=" Please fill in your company"
                        {...register("company_name", formOptions.company_name)}
                      />
                      {ErrorHandler("company_name")}
                    </InputContainer>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>Position</InputLabel>
                      <InputMentor
                        id="position"
                        name="position"
                        placeholder="Please fill in your position"
                        {...register("position", formOptions.position)}
                      />
                      {ErrorHandler("position")}
                    </InputContainer>
                  </FlexRow>
                  <FlexRow smallScreen={smallScreen}>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>City</InputLabel>
                      <InputMentor
                        id="city"
                        name="city"
                        placeholder=" Please fill in your zip code"
                        {...register("city", formOptions.city)}
                      />
                      {ErrorHandler("city")}
                      {/* <CustomDropdown
                        value={city}
                        placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                        options={[{ name: "Jakarta" }, { name: "Medan" }]}
                        selectedKey="name"
                        user_detail={userDetail}
                        action={(value) => handleChangeDropdown(value, setCity)}
                      />
                      {isEmptyCity && !city && (
                        <ErrorInput>City is required</ErrorInput>
                      )} */}
                    </InputContainer>
                    <InputContainer smallScreen={smallScreen}>
                      <InputLabel>State / Provice</InputLabel>
                      <InputMentor
                        id="state_or_province"
                        name="state_or_province"
                        placeholder=" Please fill in your zip code"
                        {...register(
                          "state_or_province",
                          formOptions.state_or_province
                        )}
                      />
                      {ErrorHandler("state_or_province")}
                      {/* <CustomDropdown
                        value={state}
                        placeholder="&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                        options={[{ name: "Indonesia" }]}
                        user_detail={userDetail}
                        selectedKey="name"
                        action={(value) =>
                          handleChangeDropdown(value, setState)
                        }
                      />
                      {isEmptyState && !state && (
                        <ErrorInput>State is required</ErrorInput>
                      )} */}
                    </InputContainer>
                  </FlexRow>
                  <FlexRow smallScreen={smallScreen}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <InputContainer smallScreen={smallScreen}>
                        <InputLabel>Postal Code</InputLabel>
                        <InputMentor
                          id="postal_code"
                          name="postal_code"
                          placeholder=" Please fill in your zip code"
                          {...register("postal_code", formOptions.postal_code)}
                        />
                        {ErrorHandler("postal_code")}
                      </InputContainer>
                      <InputContainer smallScreen={smallScreen}>
                        <InputLabel>Address</InputLabel>
                        <TextAreaMentor
                          placeholder="e.g: Sudirman street block 88 unit S, Central Jakarta"
                          {...register("address", {
                            required: "Please input your address",
                          })}
                        />
                        {ErrorHandler("address")}
                      </InputContainer>
                    </div>
                    <div style={{ width: "100%" }}>
                      <InputContainer smallScreen={smallScreen}>
                        <InputLabel>How did you hear about us?</InputLabel>
                        <RadioButton
                          name="how_did_you_hear_about_us"
                          data={[
                            "social media",
                            "friends / relatives",
                            "advertisement",
                            "other, please specify",
                          ]}
                          flexColumn
                          state={howDidYouHearAboutUs}
                          handleChange={handleChangeHowDidYouHearAboutUs}
                          user_detail={userDetail}
                        />
                        {isEmptyHowDidYouHearAboutUs &&
                          !howDidYouHearAboutUs && (
                            <ErrorInput>This field is required</ErrorInput>
                          )}
                      </InputContainer>
                      {isOpenInputTextHowDidYouHearAboutUs ? (
                        <InputContainer smallScreen={smallScreen}>
                          <InputLabel>Other, please specify</InputLabel>
                          <InputMentor
                            id="how_did_you_hear_about_us_other_value"
                            name="how_did_you_hear_about_us_other_value"
                            placeholder="Other, please specify"
                            {...register(
                              "how_did_you_hear_about_us_other_value",
                              formOptions.how_did_you_hear_about_us_other_value
                            )}
                          />
                          {ErrorHandler(
                            "how_did_you_hear_about_us_other_value"
                          )}
                        </InputContainer>
                      ) : null}
                    </div>
                  </FlexRow>
                </Col>
              </Row>
              <Col>
                <ButtonHandlerContainer smallScreen={smallScreen}>
                  <ButtonHandler onClick={(e) => cancelAdd(e)} color="#AAAAAA">
                    Cancel
                  </ButtonHandler>
                  <ButtonHandler type="submit" color="#2c65f7">
                    Submit
                  </ButtonHandler>
                </ButtonHandlerContainer>
              </Col>
              <ModalComponent
                show={isModal}
                body={[
                  <p style={{ fontWeight: "700" }} key={0}>
                    Are you sure want to cancel?
                  </p>,
                ]}
                buttonPrimary="Yes"
                buttonSecondary="No"
                onPrimaryClick={(e) => handlePrimary(e)}
                onSecondaryClick={(e) => handleSecondary(e)}
              />
            </FormMentor>
          </InnerContainer>
        </OuterContainer>
      )}
    </div>
  );
};

export default FormRegisterWebinar;

const OuterContainer = styled.div`
  text-align: left;
  @media ${device.laptopM} {
    padding-left: 350px;
  }
  @media ${device.laptopL} {
    padding-left: 350px;
  }
`;

const InnerContainer = styled.div`
  padding: 80px 8vw 90px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #2c65f7;
`;

const PageSubtitle = styled.p`
  margin-top: ${({ smallScreen }) => (smallScreen ? "2vh" : "")};
  margin-bottom: 2em;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -20px;
  padding: 2px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: ${({ smallScreen }) => (smallScreen ? "column" : "row")};
`;

const FormMentor = styled.form``;

const InputContainer = styled.div`
  width: -webkit-fill-available; // Important
  margin-right: ${({ smallScreen }) => (smallScreen ? "" : "2em")};
  margin-top: 1em;
  flex-grow: 1; // Important
`;

const InputLabel = styled.p`
  font-weight: 700;
  margin-bottom: 0.3em;
`;

const InputMentor = styled.input`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #2c65f7;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
`;

const ButtonHandlerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ smallScreen }) => (smallScreen ? "3vh" : "142px")};
  margin-bottom: ${({ smallScreen }) => (smallScreen ? "1vh" : "")};
`;

const ButtonHandler = styled.button`
  font-size: 14px;
  padding: 0.3em 2em;
  color: white;
  border: none;
  background: ${({ color }) => (color ? color : "none")};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  margin-left: 1em;
`;

const ErrorInput = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 0;
`;

const TextAreaMentor = styled.textarea`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #2c65f7;
  border-radius: 10px;
  min-height: 192px;
  width: 100%;
  padding: 0.5em;
`;
