import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

// react hook form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// sweetalert
import Swal from "sweetalert2";

// components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import ModalComponent from "../components/ModalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import CustomDropdown from "../components/input/CustomDropdown";

// Apis
import { getDetailTopic } from "../apis/topics";
import {
  createNewCoachingTopic,
  updateCoachingTopic,
} from "../apis/internalApis/topics";

const FormInternalTopic = () => {
  const navigate = useNavigate();
  const { topic_id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModal, setModal] = useState(false);
  const [topicType, setTopicType] = useState(null);
  const [invalidTopicType, setInvalidTopicType] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "all" });

  const formOptions = {
    title: {
      required: "Please input coaching topic title",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    description: {
      required: "Please input coaching topic description",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
  };

  // Error handler from react-hook-form
  function ErrorHandler(name) {
    return (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <ErrorInput>{message}</ErrorInput>}
      />
    );
  }

  // Handle cancel
  function cancelAdd(e) {
    e.preventDefault();
    setModal(true);
  }

  // Handle primary modal
  function handlePrimary(e) {
    e.preventDefault();
    navigate("/internal/topics");
  }

  // Handle secondary modal
  function handleSecondary(e) {
    if (e) {
      e.preventDefault();
    }
    setModal(false);
  }

  // Submit form
  async function handleSubmitForm(values) {
    setLoading(true);
    try {
      const isValid = handleCheckOtherInput();
      if (isValid) {
        const body = {
          ...values,
          is_entrepreneurship: topicType === "Entrepreneurship" ? true : false,
        };

        const response = topic_id
          ? await updateCoachingTopic(body, topic_id)
          : await createNewCoachingTopic(body);
        if (response && response.data && response.data.id) {
          Swal.fire({
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: `Coaching topic has been ${topic_id ? "updated" : "added"}`,
            width: "20em",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
          navigate(`/internal/topics`);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err, "error line 127 formInternalTopic.js <<");
      setLoading(false);
      Swal.fire({
        customClass: { popup: "mentor-popup" },
        position: "top-end",
        icon: "error",
        title: err.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  const handleFetchDetailInternalTopic = async (topic_id) => {
    setLoading(true);
    const result = await getDetailTopic(topic_id);
    if (result && result.data) {
      const { data: { title, description, is_entrepreneurship } = {} } =
        result || {};
      setValue("title", title);
      setValue("description", description);
      setTopicType(
        is_entrepreneurship === true
          ? "Entrepreneurship"
          : is_entrepreneurship === false
          ? "Non-Entrepreneurship"
          : ""
      );
    }
    setLoading(false);
  };

  const handleChangeDropdown = (value) => {
    setTopicType(value);
  };

  const handleCheckOtherInput = () => {
    let invalidType = false;

    if (!topicType) {
      invalidType = true;
    }

    setInvalidTopicType(invalidType);

    if (invalidType !== true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (topic_id) {
      handleFetchDetailInternalTopic(topic_id);
    }
  }, [topic_id]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <OuterContainer>
        {isLoading ? (
          <SpinnerComponent
            isShown={isLoading}
            styles={{
              right: 0,
              left: 0,
              bottom: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "290px",
            }}
          />
        ) : (
          <InnerContainer>
            <Row>
              <PageTitle>
                {!topic_id ? "Add new coaching topic" : "Edit coaching topic"}
              </PageTitle>
              <PageSubtitle>
                {`Fill in the fields below to ${
                  !topic_id ? "add new coaching topic" : "edit coaching topic"
                } to Combined Clinics
              Sustainability`}
              </PageSubtitle>
            </Row>
            <MentorInvisibleLine />
            <FormMentor onSubmit={handleSubmit(handleSubmitForm)}>
              <Row>
                <Col>
                  <FlexRow>
                    <InputContainer>
                      <InputLabel>Type</InputLabel>
                      <CustomDropdown
                        value={topicType}
                        placeholder="Choose one topic type"
                        options={[
                          { name: "Entrepreneurship" },
                          { name: "Non-Entrepreneurship" },
                        ]}
                        selectedKey="name"
                        action={(value) => handleChangeDropdown(value)}
                      />
                      {invalidTopicType && !topicType ? (
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "start",
                          }}
                        >
                          Please choose coaching topic type
                        </small>
                      ) : null}
                    </InputContainer>
                    <InputContainer>
                      <InputLabel>Title</InputLabel>
                      <InputMentor
                        id="title"
                        name="title"
                        placeholder="Input coaching topic title here"
                        {...register("title", formOptions.title)}
                      />
                      {ErrorHandler("title")}
                    </InputContainer>
                  </FlexRow>
                  <InputContainer>
                    <InputLabel>Description</InputLabel>
                    <TextAreaMentor
                      id="description"
                      name="description"
                      placeholder="Input coaching topic description here"
                      {...register("description", formOptions.description)}
                    />
                    {ErrorHandler("description")}
                  </InputContainer>
                </Col>
              </Row>
              <Col>
                <ButtonHandlerContainer>
                  <ButtonHandler onClick={(e) => cancelAdd(e)} color="#AAAAAA">
                    Cancel
                  </ButtonHandler>
                  <ButtonHandler type="submit" color="#00BB99">
                    Submit
                  </ButtonHandler>
                </ButtonHandlerContainer>
              </Col>
              <ModalComponent
                show={isModal}
                body={[
                  <p style={{ fontWeight: "700" }} key={0}>
                    Are you sure want to cancel?
                  </p>,
                ]}
                buttonPrimary="Yes"
                buttonSecondary="No"
                onPrimaryClick={(e) => handlePrimary(e)}
                onSecondaryClick={(e) => handleSecondary(e)}
                handleClose={() => handleSecondary()}
              />
            </FormMentor>
          </InnerContainer>
        )}
      </OuterContainer>
    </div>
  );
};

export default FormInternalTopic;

const OuterContainer = styled.div`
  padding-left: 290px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
`;

const PageSubtitle = styled.p`
  margin-bottom: 2em;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -20px;
  padding: 2px;
`;

const FlexRow = styled.div`
  display: flex;
`;

const FormMentor = styled.form``;

const InputContainer = styled.div`
  width: -webkit-fill-available; // Important
  margin-right: 2em;
  margin-top: 1em;
  flex-grow: 1; // Important
`;

const InputLabel = styled.p`
  font-weight: 700;
  margin-bottom: 0.3em;
`;

const InputMentor = styled.input`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
`;

const TextAreaMentor = styled.textarea`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  min-height: 235px;
  width: 100%;
  padding: 0.5em;
`;

const ButtonHandlerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 142px;
`;

const ButtonHandler = styled.button`
  font-size: 14px;
  padding: 0.3em 2em;
  color: white;
  border: none;
  background: ${({ color }) => (color ? color : "none")};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  margin-left: 1em;
`;

const ErrorInput = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 0;
`;
