import React from "react";

// Bootstrap
import Spinner from "react-bootstrap/Spinner";

const SpinnerComponent = ({ isShown, styles, className }) => {
  if (isShown) {
    return (
      <div
        style={{
          position: "fixed",
          display: styles && styles.display ? styles.display : "block",
          zIndex: 9999,
          ...styles,
        }}
        className={className}
      >
        <Spinner animation="border" role="status" />
      </div>
    );
  } else {
    return null;
  }
};

export default SpinnerComponent;
