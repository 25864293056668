import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";

// components
import GreenButton from "../components/button/GreenButton";

// lib
import fontSize from "../lib/styling/font_size";

// Style
import "../styles/headerComponent.css";

// Images & Icons
import search_icon from "../assets/icons/search_icon.png";
import notification_icon from "../assets/icons/notification_icon.png";
import account_round_icon from "../assets/icons/account_round_icon.png";
import ccs_logo_light from "../assets/icons/ccs_logo_light.png";
import mobile_close_white_icon from "../assets/icons/mobile_close_white_icon.svg";
import mobile_white_search_icon from "../assets/icons/mobile_white_search_icon.svg";
import unread_notification_mobile_view_for_student_icon from "../assets/icons/unread_notification_mobile_view_for_student_icon.svg";
import all_notification_read_mobile_view_for_student_white_icon from "../assets/icons/all_notification_read_mobile_view_for_student_white_icon.svg";
import mobile_account_white_icon from "../assets/icons/mobile_account_white_icon.svg";
import arrow_left_white from "../assets/icons/arrow_left_white.svg";

// Apis
import { logoutUser } from "../apis/authentications";
import {
  getAllUsersNotifications,
  updateUsersNotificationIsReadStatus,
} from "../apis/usersNotifications";

// reducers
import {
  SET_USERS_NOTIFICATIONS,
  SET_USER_DETAIL,
  SET_SEARCH_KEY,
} from "../store/actions";

// Cookies
import Cookies from "js-cookie";

// sweetalert
import Swal from "sweetalert2";

// components
import ModalNotification from "./ModalNotification";

// helpers
import { device } from "../helpers/device";
import ModalProfile from "./ModalProfile";
import ModalComponent from "./ModalComponent";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

function HeaderComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const { session_id, course_id, webinar_id } = useParams();
  const location = useLocation();

  const isInternal = useSelector((state) => state.isInternal);
  const userDetail = useSelector((state) => state.userDetail);
  const userRole = useSelector((state) => state.userRole);
  const usersNotifications = useSelector((state) => state.usersNotifications);

  const [isOpenNotificationPopUpModal, setIsOpenNotificationPopUpModal] =
    useState(false);
  const [isOpenSearchBarMobile, setIsOpenSearchBarMobile] = useState(false);
  const [isOpenProfilePopUpModal, setIsOpenProfilePopUpModal] = useState(false);
  const [isThereUnreadNotification, setIsThereUnreadNotification] =
    useState(false);
  const [isModalLogout, setIsModalLogout] = useState(false);

  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const handleSignout = async () => {
    const result = await logoutUser();
    if (result && result.data) {
      dispatch(SET_USER_DETAIL(null));
      navigate("/login");
    }
  };

  const handleOnClickAccountProfileIcon = (
    isInternal,
    userRole,
    userDetail
  ) => {
    if (!isInternal) {
      navigate("/profile");
    } else {
      if (
        userDetail &&
        userDetail.role &&
        userDetail.role.id &&
        +userDetail.role.id === 4
      ) {
        navigate(`/internal/mentors/${userDetail.id}`);
      } else {
        navigate(`/internal/users/${userDetail.id}`);
      }
    }
  };

  const handleOnClickBtnBookSession = (userDetail) => {
    if (userDetail && userDetail.id) {
      const { is_validated, is_new_user, user_details } = userDetail || {};
      if (is_validated) {
        if (
          !is_new_user &&
          user_details &&
          user_details.first_name &&
          user_details.last_name &&
          user_details.gender &&
          user_details.phone_number
        ) {
          navigate("/coaching/book");
        } else {
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            position: "top-end",
            icon: "error",
            title: "Please fill your profile first",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          position: "top-end",
          icon: "error",
          title: "Please verify your account first",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        position: "top-end",
        icon: "error",
        title: "Please login first",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleFetchUsersNotifications = async () => {
    const result = await getAllUsersNotifications();
    let userNotifications = result.data;
    dispatch(SET_USERS_NOTIFICATIONS(userNotifications));
    if (
      userNotifications &&
      Array.isArray(userNotifications) &&
      userNotifications.length > 0
    ) {
      setIsThereUnreadNotification(
        usersNotifications.some(
          (notification) => notification.is_read === false
        )
      );
      forceUpdate();
    }
  };

  const handleOnClickNotificationBtn = async (isOpenNotificationPopUp) => {
    let isAllNotificationHaveNotBeenRead = usersNotifications.some(
      (usersNotification) => usersNotification.is_read === false
    );
    if (isOpenNotificationPopUp && isAllNotificationHaveNotBeenRead) {
      // update all user's notification into all read
      await updateUsersNotificationIsReadStatus();
      setIsThereUnreadNotification(isAllNotificationHaveNotBeenRead);
    } else {
      await handleFetchUsersNotifications();
    }
    if (smallScreen || mediumScreen) {
      navigate("/notifications");
    } else {
      setIsOpenNotificationPopUpModal(!isOpenNotificationPopUp);
    }
  };

  const handleOnSearch = (key) => {
    dispatch(SET_SEARCH_KEY(key));
  };

  useEffect(() => {
    handleFetchUsersNotifications();
  }, []);

  return (
    <div>
      <HeaderComponentMainContainer bg_color={smallScreen ? "#2c65f7" : ""}>
        {(session_id || course_id || webinar_id) && smallScreen ? (
          <div className="headerDetailContentContainer">
            <BackNavigation onClick={() => navigate(-1)}>
              <img
                src={arrow_left_white}
                alt="arrow_left_white"
                height={20}
                width={20}
                style={{
                  marginTop: "0.25em",
                  marginBottom: "-0.25em",
                  // objectFit: "cover",
                  // objectPosition: "left bottom",
                }}
              />
            </BackNavigation>
            <div className="headerDetailContentTitle">
              {session_id
                ? "Coaching Session Details"
                : course_id
                ? "Course Details"
                : webinar_id && location.pathname.includes("register")
                ? "Register Webinar"
                : webinar_id && location.pathname.includes("register") === false
                ? "Webinar Details"
                : ""}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: !smallScreen && !mediumScreen ? "100%" : "100%",
            }}
          >
            {smallScreen && !isOpenSearchBarMobile ? (
              <div
                style={{ cursor: "pointer", display: "flex" }}
                onClick={() => navigate("/")}
              >
                <img
                  src={ccs_logo_light}
                  alt="ccs_logo_light"
                  width="150px"
                  height="40px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                  marginLeft: "1em",
                }}
              >
                <HeaderComponentSearchContainer
                  className="headerComponentSearchContainer"
                  style={{
                    border:
                      userDetail &&
                      userDetail.role &&
                      userDetail.role.id &&
                      +userDetail.role.id === 3
                        ? "1px solid #2c65f7"
                        : "1px solid #00bb99",
                  }}
                >
                  <HeaderComponentSearchContainerImg
                    src={search_icon}
                    alt="search_icon"
                    style={{
                      width: smallScreen ? "17.5px" : "20px",
                      height: "auto",
                    }}
                  />
                  <HeaderComponentSearchContainerInput
                    placeholder="Search..."
                    onChange={({ target: { value } }) => handleOnSearch(value)}
                  />
                </HeaderComponentSearchContainer>
                {smallScreen ? (
                  <div>
                    <img
                      src={mobile_close_white_icon}
                      alt="close modal"
                      onClick={() => setIsOpenSearchBarMobile(false)}
                      style={{
                        cursor: "pointer",
                        width: "18px",
                        height: "auto",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginRight:
                  !smallScreen && !mediumScreen
                    ? "2em"
                    : !smallScreen && mediumScreen
                    ? "1em"
                    : "",
                // height: "40px",
                gap: smallScreen ? "10px" : "",
              }}
            >
              {userDetail &&
              userDetail.role &&
              userDetail.role.id &&
              +userDetail.role.id === 3 &&
              !smallScreen ? (
                <GreenButton
                  width={smallScreen ? "100px" : "200px"}
                  height={smallScreen ? "30px" : "auto"}
                  textSize={smallScreen ? fontSize.title4 : fontSize.title2}
                  text="Book a session"
                  action={() => handleOnClickBtnBookSession(userDetail)}
                  cursor="pointer"
                  bgcolor={"#2c65f7"}
                />
              ) : null}
              {smallScreen && !isOpenSearchBarMobile ? (
                <HeaderComponentSearchContainerImg
                  src={smallScreen ? mobile_white_search_icon : search_icon}
                  alt="search_icon"
                  style={{
                    marginTop: "3px",
                    width: "21px",
                    height: "21px",
                  }}
                  onClick={() => setIsOpenSearchBarMobile(true)}
                />
              ) : null}
              {!isOpenSearchBarMobile ? (
                <img
                  className="headerComponentNotificationIcon"
                  src={
                    smallScreen && isThereUnreadNotification
                      ? unread_notification_mobile_view_for_student_icon
                      : smallScreen && !isThereUnreadNotification
                      ? all_notification_read_mobile_view_for_student_white_icon
                      : notification_icon
                  }
                  alt="notification_icon"
                  style={{
                    width: smallScreen ? "25px" : "35px",
                    height: smallScreen ? "25px" : "35px",
                    objectFit: "contain",
                    cursor: "pointer",
                    margin: smallScreen ? "0 8px" : "0 20px",
                  }}
                  onClick={() =>
                    handleOnClickNotificationBtn(isOpenNotificationPopUpModal)
                  }
                />
              ) : null}
              <div style={{ textAlign: "center" }}>
                {/* temporary disable for internal because no content provided */}
                {!isOpenSearchBarMobile ? (
                  <div
                    onClick={() =>
                      smallScreen
                        ? setIsOpenProfilePopUpModal(!isOpenProfilePopUpModal)
                        : handleOnClickAccountProfileIcon(
                            isInternal,
                            userRole,
                            userDetail
                          )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={
                        smallScreen
                          ? mobile_account_white_icon
                          : account_round_icon
                      }
                      className="headerComponentAccountIcon"
                      alt="account_round_icon"
                      style={{
                        width: smallScreen ? "25px" : "35px",
                        height: smallScreen ? "25px" : "35px",
                      }}
                    />
                  </div>
                ) : null}
                {!smallScreen ? (
                  <div
                    onClick={() => setIsModalLogout(true)}
                    className="headerComponentAccountActionText"
                    style={{
                      fontSize: fontSize.title4,
                      cursor: "pointer",
                      fontWeight: smallScreen ? "bold" : "",
                    }}
                  >
                    Log out
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </HeaderComponentMainContainer>
      {isOpenNotificationPopUpModal ? (
        <ModalNotification
          usersNotifications={usersNotifications}
          navigate={navigate}
        />
      ) : null}
      {isOpenProfilePopUpModal ? (
        <ModalProfile
          onClickMyProfile={() =>
            handleOnClickAccountProfileIcon(isInternal, userRole, userDetail)
          }
          onClickLogOut={() => setIsModalLogout(false)}
        />
      ) : null}
      <ModalComponent
        show={isModalLogout}
        body={[
          <p style={{ fontWeight: "700" }} key={0}>
            Are you sure want to logout?
          </p>,
        ]}
        buttonPrimary="Yes"
        buttonSecondary="No"
        onPrimaryClick={() => handleSignout()}
        onSecondaryClick={() => setIsModalLogout(false)}
      />
    </div>
  );
}

export default HeaderComponent;

const HeaderComponentMainContainer = styled.div`
  background-color: ${({ bg_color }) => (bg_color ? bg_color : "white")};
  position: fixed;
  padding: 1em 1.5em;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  @media ${device.laptopM} {
    margin-left: 290px;
  }
  @media ${device.laptopL} {
    margin-left: 290px;
  }
`;

const HeaderComponentSearchContainer = styled.div`
  background-color: white;
  border-radius: 25px;
  padding: 10px 1em;
  display: flex;
  align-items: center;
  width: 78vw;
  height: 40px;
  @media ${device.tablet} {
    width: 30vw;
    height: 40px;
  }
  @media ${device.laptop} {
    width: 30vw;
    height: 40px;
  }
  @media ${device.laptopM} {
    width: 30vw;
    height: 40px;
  }
  @media ${device.laptopL} {
    width: 30vw;
    height: 40px;
  }
`;

const HeaderComponentSearchContainerImg = styled.img`
  @media ${device.tablet} {
    width: 20px;
    height: 20px;
  }
  @media ${device.laptop} {
    width: 20px;
    height: 20px;
  }
  @media ${device.laptopM} {
    width: 20px;
    height: 20px;
  }
  @media ${device.laptopL} {
    width: 20px;
    height: 20px;
  }
`;

const HeaderComponentSearchContainerInput = styled.input`
  margin-left: 0.5em;
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  @media ${device.tablet} {
    margin-left: 0.5em;
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
  }
  @media ${device.laptop} {
    margin-left: 0.5em;
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
  }
  @media ${device.laptopM} {
    margin-left: 0.5em;
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
  }
  @media ${device.laptopL} {
    margin-left: 0.5em;
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
  }
`;

const BackNavigation = styled.p`
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  text-align: left;
`;
