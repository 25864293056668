import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// // Styled components
// import styled from "styled-components";

// Font size helper
import fontSize from "../lib/styling/font_size";

// Styles
import "../styles/sidebarComponent.css";

// reducers
import { SET_SEARCH_KEY } from "../store/actions";

// Images & Icons
import ccs_logo_dark from "../assets/icons/ccs_logo_dark.png";
import dashboard_nav_icon from "../assets/icons/dashboard_nav_icon.png";
import topics_nav_icon from "../assets/icons/topics_nav_icon.png";
import users_nav_icon from "../assets/icons/users_nav_icon.png";
// import mentors_nav_icon from "../assets/icons/mentors_nav_icon.png";
import my_booking_nav_icon from "../assets/icons/my_booking_nav_icon.svg";
import course_icon from "../assets/icons/course_icon.svg";
import subscription_nav_icon from "../assets/icons/subscription_nav_icon.svg";

function SidebarInteralComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userRole = useSelector((state) => state.userRole);

  const handleNavigate = (url) => {
    dispatch(SET_SEARCH_KEY(""));
    navigate(url);
  };

  return (
    <div
      className="sidebarComponentMainContainer"
      style={{ backgroundColor: "#d7ffff" }}
    >
      <div
        style={{ cursor: "pointer", display: "flex" }}
        onClick={() => handleNavigate("/internal/dashboard")}
      >
        <img
          src={ccs_logo_dark}
          alt="ccs_logo_dark"
          width="200px"
          height="100px"
          style={{ objectFit: "contain" }}
        />
      </div>
      <div style={{ padding: "30px 0" }}>
        <div
          onClick={() => handleNavigate("/internal/dashboard")}
          className={
            location.pathname === "/internal/dashboard"
              ? "sidebarComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={dashboard_nav_icon}
              alt="dashboard_nav_icon"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div style={{ fontWeight: "500", fontSize: fontSize.title2 }}>
              Dashboard
            </div>
          </div>
        </div>
        {userRole && userRole !== "Mentor" ? (
          <div
            onClick={() => handleNavigate("/internal/users")}
            className={
              location.pathname.includes("/internal/users") ||
              location.pathname.includes("/internal/mentors")
                ? "sidebarComponentEachMenuMainContainerFalse"
                : "sidebarComponentEachMenuMainContainerTrue"
            }
          >
            <div className="sidebarComponentEachMenuContainer">
              <img
                src={users_nav_icon}
                alt="users_nav_icon"
                width="30px"
                height="30px"
                style={{ objectFit: "contain", marginRight: "15px" }}
              />
              <div style={{ fontWeight: "500", fontSize: fontSize.title2 }}>
                Users
              </div>
            </div>
          </div>
        ) : null}
        <div
          onClick={() => handleNavigate("/internal/bookings")}
          className={
            location.pathname.includes("/internal/bookings")
              ? "sidebarComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={my_booking_nav_icon}
              alt="my_booking_nav_icon"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div style={{ fontWeight: "500", fontSize: fontSize.title2 }}>
              Bookings
            </div>
          </div>
        </div>
        {/* {userRole && userRole !== "Mentor" ? (
          <div
            onClick={() => handleNavigate("/internal/mentors")}
            className={
              location.pathname.includes("/internal/mentors")
                ? "sidebarComponentEachMenuMainContainerFalse"
                : "sidebarComponentEachMenuMainContainerTrue"
            }
          >
            <div className="sidebarComponentEachMenuContainer">
              <img
                src={mentors_nav_icon}
                width="30px"
                height="30px"
                style={{ objectFit: "contain", marginRight: "15px" }}
              />
              <div style={{ fontWeight: "500", fontSize: fontSize.title2 }}>
                Mentors
              </div>
            </div>
          </div>
        ) : null} */}
        {userRole && userRole !== "Mentor" ? (
          <div
            onClick={() => handleNavigate("/internal/topics")}
            className={
              location.pathname.includes("/internal/topics")
                ? "sidebarComponentEachMenuMainContainerFalse"
                : "sidebarComponentEachMenuMainContainerTrue"
            }
          >
            <div className="sidebarComponentEachMenuContainer">
              <img
                src={topics_nav_icon}
                alt="topics_nav_icon"
                width="30px"
                height="30px"
                style={{ objectFit: "contain", marginRight: "15px" }}
              />
              <div style={{ fontWeight: "500", fontSize: fontSize.title2 }}>
                Topics
              </div>
            </div>
          </div>
        ) : null}
        <div
          onClick={() => handleNavigate("/internal/courses")}
          className={
            location.pathname.includes("/internal/courses")
              ? "sidebarComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={course_icon}
              alt="course_icon"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div style={{ fontWeight: "500", fontSize: fontSize.title2 }}>
              Courses
            </div>
          </div>
        </div>
        {userRole && userRole !== "Mentor" ? (
          <div
            onClick={() => handleNavigate("/internal/webinars")}
            className={
              location.pathname.includes("/internal/webinars")
                ? "sidebarComponentEachMenuMainContainerFalse"
                : "sidebarComponentEachMenuMainContainerTrue"
            }
          >
            <div className="sidebarComponentEachMenuContainer">
              <img
                src={subscription_nav_icon}
                alt="subscription_nav_icon"
                width="30px"
                height="30px"
                style={{ objectFit: "contain", marginRight: "15px" }}
              />
              <div style={{ fontWeight: "500", fontSize: fontSize.title2 }}>
                Webinars
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SidebarInteralComponent;

// const Container = styled.div`
//   left: 0;
//   top: 0;
//   bottom: 0;
//   position: fixed;
//   width: 350px;
//   background-color: #d7ffff;
//   padding: 15px 30px;
//   filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
//     drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
//   z-index: 1002;
// `;
