import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// Apis
import {
  changeUserVerifiedStatus,
  getAllUsers,
} from "../apis/internalApis/users";
import { deleteUser } from "../apis/authentications";

// images & icons
import sort_icon from "../assets/icons/sort_icon.svg";
import filter_icon from "../assets/icons/filter_icon.svg";

// sweetalert
import Swal from "sweetalert2";

// moment.js
import moment from "moment";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import FilterModal from "../components/FilterModal";
import SortModal from "../components/SortModal";
import ModalComponent from "../components/ModalComponent";

const InternalUserList = () => {
  const navigate = useNavigate();

  const searchKey = useSelector((state) => state.searchKey);
  const userDetail = useSelector((state) => state.userDetail);

  const [users, setUsers] = useState([]);

  const [savedUserIdToDelete, setSavedUserIdToDelete] = useState(null);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const initialDataState =
    users && users.length > 0
      ? users
          .map(
            ({
              id,
              user_details,
              role,
              createdAt,
              email,
              is_validated,
              last_sign_in,
              user_ccs_id,
            }) => {
              let username = "";
              let roleName = "";
              if (user_details) {
                username =
                  user_details.first_name + " " + user_details.last_name;
              }
              if (role && role.name) {
                roleName = role.name;
              }
              const date = new Date(createdAt).toLocaleString();
              return {
                id,
                user_ID: user_ccs_id,
                username,
                roleName,
                email_address: email,
                registered_date: date,
                is_verified: is_validated,
                last_sign_in: last_sign_in
                  ? new Date(last_sign_in).toLocaleString()
                  : null,
              };
            }
          )
          .sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
      : [];

  const filterKeyInitialState = {
    role: [],
    status: [],
    dateTime: {},
  };

  const statusList = ["Verified", "Not Verified"];

  const [isLoading, setLoading] = useState(false);

  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isSortModalOpened, setSortModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);
  const [filterKey, setFilterKey] = useState(filterKeyInitialState);
  const [sortKey, setSortKey] = useState({ newest: false, oldest: false });

  const [roleList, setRoleList] = useState([]);

  const handleFetchUserList = async () => {
    setLoading(true);
    const result = await getAllUsers();
    if (result && result.data) {
      setUsers(result.data);
    }
    setLoading(false);
  };

  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFormatFirstFetchPaginatedData(users);
    } else {
      handleFormatFirstFetchPaginatedData(users, searchKey);
    }
  };

  const handleFormatFirstFetchPaginatedData = (users, searchKey) => {
    let initialDataState = users
      .map(
        ({
          id,
          user_details,
          role,
          createdAt,
          email,
          is_validated,
          last_sign_in,
          user_ccs_id,
        }) => {
          let username = "";
          let roleName = "";
          if (user_details) {
            username = user_details.first_name + " " + user_details.last_name;
          }
          if (role && role.name) {
            roleName = role.name;
          }
          const date = new Date(createdAt).toLocaleString();
          return {
            id,
            user_ID: user_ccs_id,
            username,
            roleName,
            email_address: email,
            registered_date: date,
            is_verified: is_validated,
            last_sign_in: last_sign_in
              ? new Date(last_sign_in).toLocaleString()
              : null,
          };
        }
      )
      .sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    if (searchKey) {
      initialDataState = initialDataState.filter((data) => {
        const { email_address, username, roleName, user_ID } = data || {};
        let lowercaseSearchkey = searchKey.toLowerCase();
        let emailSearch = email_address.toLowerCase();
        let usernameSearch = username.toLowerCase();
        let roleNameSearch = roleName.toLowerCase();
        let userCcsIdSearch = user_ID.toLowerCase();
        if (
          emailSearch.includes(lowercaseSearchkey) ||
          usernameSearch.includes(lowercaseSearchkey) ||
          roleNameSearch.includes(lowercaseSearchkey) ||
          userCcsIdSearch.includes(lowercaseSearchkey)
        ) {
          return data;
        }
      });
    }
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  // Change router to view mentor details
  function handleViewDetails(d) {
    if (d && d.roleName && d.roleName === "Mentor") {
      navigate(`/internal/mentors/${d["id"]}`);
    } else {
      navigate(`/internal/users/${d["id"]}`);
    }
  }

  const handleOnToggleUserVerifiedStatus = async (data) => {
    const { id, is_verified } = data || {};

    const result = await changeUserVerifiedStatus(id, {
      is_validated: is_verified,
    });
    if (result && result.data && result.data.success && result.data.message) {
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        imageUrl:
          "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
        position: "top-end",
        text: result.data.message,
        width: "20em",
        heightAuto: "0.5em",
        showConfirmButton: false,
        timer: 1500,
      });
      handleFetchUserList();
      handleFormatFirstFetchPaginatedData(users);
    }
  };

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        let isValid = true;
        if (filterKey.role.length > 0) {
          isValid = filterKey.role.includes(d.roleName);
        }
        if (filterKey.status.length > 0) {
          if (
            filterKey.status.includes("Verified") &&
            filterKey.status.includes("Not Verified") === false
          ) {
            isValid = d.is_verified === true;
          } else if (
            filterKey.status.includes("Verified") === false &&
            filterKey.status.includes("Not Verified")
          ) {
            isValid = d.is_verified === false;
          }
        }
        if (filterKey.dateTime.from && filterKey.dateTime.to) {
          let date = moment(new Date(d.registered_date));
          let from = moment(new Date(filterKey.dateTime.from));
          let to = moment(new Date(filterKey.dateTime.to)).add(1, "days");

          isValid = moment(date).isBetween(from, to);
        }
        return isValid;
      });
      setData(filteredData);
    } else {
      setData(initialDataState);
    }
    // setFilterChanged(false);
  }

  function handleOnFilter(type, name, value) {
    setFilterChanged(true);
    switch (type) {
      case "role":
        if (value) {
          setFilterKey((prev) => ({ ...prev, role: [...prev.role, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            role: prev.role.filter((d) => d !== name),
          }));
        }
        break;
      case "status":
        if (value) {
          setFilterKey((prev) => ({ ...prev, status: [...prev.status, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            status: prev.status.filter((d) => d !== name),
          }));
        }
        break;
      case "date-time":
        if (value) {
          setFilterKey((prev) => ({ ...prev, dateTime: value }));
        }
        break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
  }

  function handleOnSort(type) {
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.registered_date) - new Date(a.registered_date);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.registered_date) - new Date(b.registered_date);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
  }

  const onDeleteUser = (d) => {
    const id = d["id"];
    setSavedUserIdToDelete(id);
    setIsOpenModalDelete(true);
  };

  function handleCancelDeleteUser(e) {
    if (e) {
      e.preventDefault();
    }
    setIsOpenModalDelete(false);
    setSavedUserIdToDelete(null);
  }

  const handleOnDeleteUser = async () => {
    setLoading(true);
    try {
      const response = await deleteUser(savedUserIdToDelete);

      if (response && response.data && response.data.success) {
        Swal.fire({
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: response.data.message,
          width: "20em",
          showConfirmButton: false,
          timer: 1500,
        });
        handleFetchUserList();
        setIsOpenModalDelete(false);
      }
    } catch (err) {
      console.log(err, "error line 377 internalUserList.js <<");
      Swal.fire({
        customClass: { popup: "mentor-popup" },
        position: "top-end",
        icon: "error",
        title: err.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchUserList();
  }, []);

  useEffect(() => {
    if (users && Array.isArray(users) && users.length > 0) {
      handleFormatFirstFetchPaginatedData(users);
    }
  }, [users]);

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    if (userDetail && userDetail.id) {
      const { role: { name } = {} } = userDetail || {};
      if (name === "Admin") {
        setRoleList(["Mentor", "Student"]);
      } else {
        setRoleList(["Admin", "Mentor", "Student"]);
      }
    }
  }, [userDetail]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <SpinnerComponent
        isShown={isLoading}
        styles={{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "290px",
        }}
      />
      {!isLoading && (
        <div style={{ paddingLeft: "290px" }}>
          <div className="myBookingInnerContainer">
            <ButtonContainer>
              <FilterSortButton onClick={handleOnClickSortButton}>
                <img
                  src={sort_icon}
                  alt="sort_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Sort
              </FilterSortButton>
              <FilterSortButton onClick={handleOnClickFilterButton}>
                <img
                  src={filter_icon}
                  alt="filter_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Filter
              </FilterSortButton>
            </ButtonContainer>
            <FilterModal
              show={isFilterModalOpened.toString()}
              handleClose={() => {
                setFilterModalOpened(false);
                setFilterChanged(false);
              }}
              statusList={statusList}
              roleList={roleList}
              filterKey={filterKey}
              handleOnFilter={handleOnFilter}
              dateTimeButtonList={["This Week", "This Month"]}
              isFilterChanged={isFilterChanged}
              user_detail={userDetail}
              customDateTime={"Registered Date/Time"}
              filterStatus
              filterDateTime
              filterRole
            />
            <SortModal
              show={isSortModalOpened.toString()}
              handleClose={() => {
                setSortModalOpened(false);
              }}
              handleOnSort={handleOnSort}
              sortKey={sortKey}
              user_detail={userDetail}
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              itemOffset={itemOffset + 1}
              endOffset={itemOffset + perPage}
              totalItems={initialDataState.length}
              hide={data.length <= perPage}
            >
              <TableInternalComponent
                table_data={paginatedData}
                table_name="Users"
                onClickRow={handleViewDetails}
                handleOnToggle={handleOnToggleUserVerifiedStatus}
                is_deleteable={
                  userDetail &&
                  userDetail.role &&
                  userDetail.role.id &&
                  +userDetail.role.id === 1
                }
                onClickDelete={onDeleteUser}
              />
            </Pagination>
            <ModalComponent
              show={isOpenModalDelete}
              body={[
                <p style={{ fontWeight: "700" }} key={0}>
                  Are you sure want to delete this user?
                </p>,
              ]}
              buttonPrimary="Yes"
              buttonSecondary="No"
              onPrimaryClick={() => handleOnDeleteUser()}
              onSecondaryClick={(e) => handleCancelDeleteUser(e)}
              handleClose={() => handleCancelDeleteUser()}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InternalUserList;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;
