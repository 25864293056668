import DatePicker from "react-date-picker/dist/entry.nostyle";
import styled from "styled-components";
import calendar_icon from "../../assets/icons/calendar_icon.png";
import dropdown_icon from "../../assets/icons/dropdown_icon.svg";
import "../../styles/DatePicker.css";
import "../../styles/Calendar.css";

function ReactDatePicker({
  date,
  handleDate,
  disabled = false,
  format = "d/M/yyyy",
  fromprofile,
  isFilled = false,
  error = false,
  isOpen,
  user_detail,
  formwebinar,
}) {
  return (
    <DateContainer
      fromprofile={fromprofile}
      disabled={disabled}
      date={date}
      error={error}
      user_detail={user_detail}
    >
      {fromprofile ? (
        isFilled || disabled ? null : (
          <DateIcon src={calendar_icon} alt="date-picker" />
        )
      ) : (
        <DateIcon src={calendar_icon} alt="date-picker" />
      )}

      <DatePicker
        format={format ? format : undefined}
        closeCalendar={false}
        isOpen={isOpen}
        value={date}
        onChange={handleDate}
        disabled={disabled}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        calendarIcon={false}
        clearIcon={false}
      />
      {!formwebinar ? (
        <Icon src={dropdown_icon} alt="date-picker-dropdown" />
      ) : null}
      {fromprofile && !formwebinar ? (
        isFilled || disabled ? null : (
          <Icon src={dropdown_icon} alt="date-picker-dropdown" />
        )
      ) : !formwebinar ? (
        <Icon src={dropdown_icon} alt="date-picker-dropdown" />
      ) : null}
    </DateContainer>
  );
}

export default ReactDatePicker;

const DateContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__wrapper {
    border: ${({ fromprofile, error, user_detail }) =>
      fromprofile && error
        ? "2px solid #EB4335"
        : `2px solid ${
            user_detail &&
            user_detail.role &&
            user_detail.role.id &&
            +user_detail.role.id === 3
              ? "#2c65f7"
              : "#00BB99"
          }`};
  }
  .react-date-picker--enabled {
    background-color: ${({ fromprofile, date, user_detail }) =>
      fromprofile &&
      date &&
      `${
        user_detail &&
        user_detail.role &&
        user_detail.role.id &&
        +user_detail.role.id === 3
          ? "#e7eeff"
          : "#D7FFFF"
      }`};
  }
  .react-date-picker--disabled {
    opacity: ${({ fromprofile }) => fromprofile && 1};
    background-color: ${({ fromprofile, user_detail }) =>
      fromprofile &&
      `${
        user_detail &&
        user_detail.role &&
        user_detail.role.id &&
        +user_detail.role.id === 3
          ? "#e7eeff"
          : "#D7FFFF"
      }`};
  }
  .react-date-picker__inputGroup {
    padding-left: ${({ fromprofile, disabled }) =>
      fromprofile && disabled ? "0" : "25px"};
  }
  .react-date-picker__inputGroup__input:invalid {
    background-color: ${({ fromprofile }) => fromprofile && "transparent"};
  }
  .react-date-picker__inputGroup__divider {
    color: ${({ fromprofile }) => fromprofile && "black"};
  }
  .react-date-picker__inputGroup__leadingZero {
    color: ${({ fromprofile }) => fromprofile && "black"};
  }
`;

const DateIcon = styled.img`
  position: absolute;
  left: 10px;
  bottom: 15px;
  object-fit: contain;
  z-index: 1;
`;

const Icon = styled.img`
  position: absolute;
  right: 10px;
  top: 20px;
`;
