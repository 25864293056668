import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: `checkbox` })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: relative;
  white-space: nowrap;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  margin-top: 1px;
  width: 16px;
  height: 16px;
  background: ${({ checked }) => (checked ? `black` : `white`)};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid black;

  ${Icon} {
    visibility: ${({ checked }) => (checked ? `visible` : `hidden`)};
  }
`;

const Checkbox = ({ checked, ...props }) => (
  <CheckboxContainer checked={checked} {...props}>
    {/* <HiddenCheckbox checked={checked} {...props} /> */}
    <StyledCheckbox checked={checked} {...props}>
      <Icon viewBox="0 5 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
