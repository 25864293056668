import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// react hook form
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// helpers
import capitalizeFirstLetter from "../helpers/capitalize_first_letter";

// sweetalert
import Swal from "sweetalert2";

// components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import ModalComponent from "../components/ModalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import ReactDatePicker from "../components/input/DatePicker";

// images & icons
import upload_file_icon from "../assets/icons/upload_file_icon.svg";

// Apis
import { getWebinarDetail } from "../apis/clientApis/webinars";
import {
  createNewWebinarSchedule,
  updateWebinarSchedule,
} from "../apis/internalApis/webinars";

const FormInternalWebinar = () => {
  const navigate = useNavigate();
  const { webinar_id } = useParams();

  const userDetail = useSelector((state) => state.userDetail);

  const [isLoading, setLoading] = useState(false);
  const [isModal, setModal] = useState(false);
  const [webinarImageToView, setWebinarImageToView] = useState(null);
  const [webinarImageToSave, setWebinarImageToSave] = useState(null);

  const [invalidWebinarImage, setInvalidWebinarImage] = useState(false);
  const [invalidWebinarImageMsg, setInvalidWebinarImageMsg] = useState("");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "all" });

  const formOptions = {
    title: {
      required: "Please input webinar schedule title",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    schedule_date: { required: "Please input webinar schedule date" },
  };

  // Error handler from react-hook-form
  function ErrorHandler(name) {
    return (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <ErrorInput>{message}</ErrorInput>}
      />
    );
  }

  // Handle cancel
  function cancelAdd(e) {
    e.preventDefault();
    setModal(true);
  }

  // Handle primary modal
  function handlePrimary(e) {
    e.preventDefault();
    navigate("/internal/webinars");
  }

  // Handle secondary modal
  function handleSecondary(e) {
    if (e) {
      e.preventDefault();
    }
    setModal(false);
  }

  // Submit form
  async function handleSubmitForm(values) {
    setLoading(true);
    try {
      const isValid = checkFormValidation();
      if (isValid) {
        const formData = new FormData();
        formData.append("title", capitalizeFirstLetter(values.title));
        formData.append("schedule_date", values.schedule_date);
        if (webinarImageToSave) {
          formData.append("file", webinarImageToSave);
        }

        const response = webinar_id
          ? await updateWebinarSchedule(formData, webinar_id)
          : await createNewWebinarSchedule(formData);
        if (response && response.data && response.data.id) {
          Swal.fire({
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: `Webinar schedule has been ${
              webinar_id ? "updated" : "added"
            }`,
            width: "20em",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
          navigate(`/internal/webinars`);
        }
      }
    } catch (err) {
      console.log(err, "error line 136 formInternalWebinar.js <<");
      setLoading(false);
      Swal.fire({
        customClass: { popup: "mentor-popup" },
        position: "top-end",
        icon: "error",
        title: err.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  const handleFetchDetailInternalWebinar = async (webinar_id) => {
    setLoading(true);
    const result = await getWebinarDetail(webinar_id);
    if (result && result.data) {
      const { data: { title, webinar_schedule_date, image_cover_url } = {} } =
        result || {};
      setValue("title", capitalizeFirstLetter(title));
      setValue("schedule_date", new Date(webinar_schedule_date));
      setWebinarImageToView({
        fileName: "",
        url: image_cover_url,
      });
    }
    setLoading(false);
  };

  const handleOnInputChange = (value, setter) => {
    // handle input file
    let maxFileSize;
    if (value && value[0]) {
      const { size } = value[0] || {};
      if (setter === "webinarImage") {
        maxFileSize = 3 * 1024 * 1024;
        if (size > maxFileSize) {
          setInvalidWebinarImage(true);
          setInvalidWebinarImageMsg("File too large (Max: 3 MB)");
        } else {
          setInvalidWebinarImage(false);
          setInvalidWebinarImageMsg("");
          setWebinarImageToSave(value[0]);
          setWebinarImageToView({
            fileName: value[0].name,
            url: URL.createObjectURL(value[0]),
          });
        }
      }
    }
  };

  const checkFormValidation = () => {
    let invalidWebinarImage = false;
    let invalidWebinarImageMsg = "";

    if (!webinar_id) {
      // validation for create new webinar schedule page
      if (!webinarImageToView || !webinarImageToSave) {
        invalidWebinarImage = true;
        invalidWebinarImageMsg = "This field is required";
      }
    } else {
      // validation for edit webinar schedule page
      if (
        webinarImageToView &&
        !webinarImageToView["url"] &&
        !webinarImageToSave
      ) {
        invalidWebinarImage = true;
        invalidWebinarImageMsg = "This field is required";
      }
    }

    setInvalidWebinarImage(invalidWebinarImage);
    setInvalidWebinarImageMsg(invalidWebinarImageMsg);

    if (invalidWebinarImage !== true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (webinar_id) {
      handleFetchDetailInternalWebinar(webinar_id);
    }
  }, [webinar_id]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <OuterContainer>
        {isLoading ? (
          <SpinnerComponent
            isShown={isLoading}
            styles={{
              right: 0,
              left: 0,
              bottom: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "290px",
            }}
          />
        ) : (
          <InnerContainer>
            <Row>
              <PageTitle>
                {!webinar_id
                  ? "Add new webinar schedule"
                  : "Edit webinar schedule"}
              </PageTitle>
              <PageSubtitle>
                {`Fill in the fields below to ${
                  !webinar_id
                    ? "add new webinar schedule"
                    : "edit webinar schedule"
                } to Combined Clinics
              Sustainability`}
              </PageSubtitle>
            </Row>
            <MentorInvisibleLine />
            <FormMentor onSubmit={handleSubmit(handleSubmitForm)}>
              <Row>
                <Col>
                  <FlexRow>
                    <InputContainer>
                      <InputLabel>Title</InputLabel>
                      <InputMentor
                        id="title"
                        name="title"
                        placeholder="Input webinar schedule title here"
                        {...register("title", formOptions.title)}
                      />
                      {ErrorHandler("title")}
                    </InputContainer>
                    <InputContainer>
                      <Col>
                        <div className="userFormInputGroup">
                          <Form.Group>
                            <InputLabel>Schedule date</InputLabel>
                            <Controller
                              control={control}
                              name="schedule_date"
                              rules={formOptions.schedule_date}
                              render={({ field: { onChange, value } }) => (
                                <ReactDatePicker
                                  date={value}
                                  handleDate={onChange}
                                  format="dd/MM/yyyy"
                                  formwebinar={true}
                                  error={errors?.schedule_date}
                                  user_detail={userDetail}
                                />
                              )}
                            />
                            <small
                              className="text-danger"
                              style={{ userSelect: "none" }}
                            >
                              {errors?.schedule_date &&
                                errors.schedule_date.message}
                            </small>
                          </Form.Group>
                        </div>
                      </Col>
                    </InputContainer>
                  </FlexRow>
                  <Col>
                    <div className="userFormInputGroup">
                      <Form.Group>
                        <div className="labelForDescription">
                          <InputLabel>Webinar Image</InputLabel>
                          <div className="secondLabel">
                            This will be set as a cover image of the webinar
                          </div>
                        </div>
                        <div
                          className={
                            !webinarImageToView
                              ? "inputImageContainer"
                              : "inputImageContainerNoPointer"
                          }
                          onClick={() =>
                            !webinarImageToView
                              ? document
                                  .querySelector(`.input-field-webinar-image`)
                                  .click()
                              : null
                          }
                          style={{
                            border:
                              invalidWebinarImage && invalidWebinarImageMsg
                                ? "2px solid #EB4335"
                                : "2px solid #00bb99",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className={`input-field-webinar-image`}
                            hidden
                            onChange={({ target: { files } }) =>
                              handleOnInputChange(files, "webinarImage")
                            }
                          />
                          <div className="imageToViewContainer">
                            <img
                              src={
                                webinarImageToView && webinarImageToView.url
                                  ? webinarImageToView.url
                                  : upload_file_icon
                              }
                              style={
                                webinarImageToView && webinarImageToView.url
                                  ? { maxWidth: 250, maxHeight: 175 }
                                  : { maxWidth: 35, maxHeight: 35 }
                              }
                              alt="webinarImage"
                            ></img>
                            {webinarImageToView &&
                            webinarImageToView.fileName ? (
                              <div className="imageFileNameFontStyle">
                                {webinarImageToView.fileName}
                              </div>
                            ) : null}
                          </div>
                          {webinarImageToView && (
                            <div
                              className="btnChangeImage"
                              onClick={() =>
                                document
                                  .querySelector(`.input-field-webinar-image`)
                                  .click()
                              }
                            >
                              <img
                                src={upload_file_icon}
                                alt="upload_file_icon"
                                width={17}
                                height={17}
                              ></img>
                              <div className="changeImageLabel">
                                Replace image
                              </div>
                            </div>
                          )}
                          {webinarImageToView ? null : (
                            <div className="labelImage">
                              {"Click to upload "}
                            </div>
                          )}
                          {webinarImageToView ? null : (
                            <div className="placeholderImage">
                              {"Maximum file size 3 MB per image"}
                            </div>
                          )}
                          {webinarImageToView ? null : (
                            <div
                              className="placeholderImage"
                              style={{ paddingTop: "3em" }}
                            >
                              {"Supported file type: .jpg, .gif, or .png"}
                            </div>
                          )}
                        </div>
                        {invalidWebinarImage && invalidWebinarImageMsg ? (
                          <div
                            style={{
                              color: "#EB4335",
                              fontSize: 12,
                              marginTop: "0.5em",
                            }}
                          >
                            {invalidWebinarImageMsg}
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                  </Col>
                </Col>
              </Row>
              <Col>
                <ButtonHandlerContainer>
                  <ButtonHandler onClick={(e) => cancelAdd(e)} color="#AAAAAA">
                    Cancel
                  </ButtonHandler>
                  <ButtonHandler type="submit" color="#00BB99">
                    Submit
                  </ButtonHandler>
                </ButtonHandlerContainer>
              </Col>
              <ModalComponent
                show={isModal}
                body={[
                  <p style={{ fontWeight: "700" }} key={0}>
                    Are you sure want to cancel?
                  </p>,
                ]}
                buttonPrimary="Yes"
                buttonSecondary="No"
                onPrimaryClick={(e) => handlePrimary(e)}
                onSecondaryClick={(e) => handleSecondary(e)}
                handleClose={() => handleSecondary()}
              />
            </FormMentor>
          </InnerContainer>
        )}
      </OuterContainer>
    </div>
  );
};

export default FormInternalWebinar;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
`;

const PageSubtitle = styled.p`
  margin-bottom: 2em;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -20px;
  padding: 2px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 2em;
`;

const FormMentor = styled.form``;

const InputContainer = styled.div`
  width: -webkit-fill-available; // Important
  margin-top: 1em;
  flex-grow: 1; // Important
`;

const InputLabel = styled.p`
  font-weight: 700;
  margin-bottom: 0.3em;
`;

const InputMentor = styled.input`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
`;

const ButtonHandlerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonHandler = styled.button`
  font-size: 14px;
  padding: 0.3em 2em;
  color: white;
  border: none;
  background: ${({ color }) => (color ? color : "none")};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  margin-left: 1em;
`;

const ErrorInput = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 0;
`;
