import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

// Apis
import {
  fetchAllDashboardChartData,
  fetchAllDashboardCountData,
} from "../apis/internalApis/dashboard";

// Styles
import "../styles/home.css";

// Helpers
import { device } from "../helpers/device";

// react-responsive
import { useMediaQuery } from "react-responsive";

import { CRow } from "@coreui/react-pro";

import moment from "moment";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import BarChart from "../components/BarChart";
import Widget from "../components/Widget";

const InternalDashboard = () => {
  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1200px)",
  });

  const filterKeyInitialState = { dateTime: {} };

  const userDetail = useSelector((state) => state.userDetail);

  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);
  const [filterKey, setFilterKey] = useState(filterKeyInitialState);

  const [chartBarDataSessionBooked, setChartBarDataSessionBooked] = useState({
    labels: [],
    data: [],
  });
  const [chartBarDataMemberRegistered, setChartBarDataMemberRegistered] =
    useState({
      labels: [],
      data: [],
    });

  const [widgets, setWidgets] = useState([]);

  const getAllDashboardChartData = async () => {
    let finalDays = [];
    for (let l = -6; l < 1; l++) {
      let date = moment().add(l, "days");
      let day = date.format("DD");
      let month = date.format("MMM");
      if (l === 0) {
        finalDays.push(`Today`);
      } else {
        finalDays.push(`${day} ${month}`);
      }
    }
    let response = await fetchAllDashboardChartData();

    if (response && response.data) {
      const { data: { session_booked, member_registered } = {} } = response;
      setChartBarDataSessionBooked({
        ...chartBarDataSessionBooked,
        labels: finalDays,
        data: session_booked,
      });
      setChartBarDataMemberRegistered({
        ...chartBarDataMemberRegistered,
        labels: finalDays,
        data: member_registered,
      });
    }
  };

  const getAllDashboardCountData = async () => {
    const result = await fetchAllDashboardCountData();
    if (result && result.data) {
      let finalWidgets = [];
      const { data } = result || {};
      for (const key in data) {
        if (key === "total_members") {
          finalWidgets.push({
            title: "Total members",
            widgetColor: "info",
            value: data[key],
            isOpenCustomFilter: false,
            filterKey: { dateTime: {} },
            filterBy: "",
          });
        }
        if (key === "total_mentors") {
          finalWidgets.push({
            title: "Total mentors",
            backgroundColor: "#36A2DD",
            value: data[key],
            isOpenCustomFilter: false,
            filterKey: { dateTime: {} },
            filterBy: "",
          });
        }
        if (key === "total_courses") {
          finalWidgets.push({
            title: "Total course posted",
            backgroundColor: "#0080aa",
            value: data[key],
            isOpenCustomFilter: false,
            filterKey: { dateTime: {} },
            filterBy: "",
          });
        }
        if (key === "total_webinars") {
          finalWidgets.push({
            title: "Total webinar posted",
            backgroundColor: "#004aad",
            value: data[key],
            isOpenCustomFilter: false,
            filterKey: { dateTime: {} },
            filterBy: "",
          });
        }
        if (key === "total_bookings") {
          finalWidgets.push({
            title: "Total bookings",
            backgroundColor: "#007bff",
            value: data[key],
            isOpenCustomFilter: false,
            filterKey: { dateTime: {} },
            filterBy: "",
          });
        }
        if (key === "total_topics") {
          finalWidgets.push({
            title: "Total booking topic",
            backgroundColor: "#0055ff",
            value: data[key],
            isOpenCustomFilter: false,
            filterKey: { dateTime: {} },
            filterBy: "",
          });
        }
      }
      setWidgets(finalWidgets);
    }
  };

  const handleOnFilter = async (type, name, value, widgetData) => {
    let finalWidgets = [];
    
    for (let l = 0; l < widgets.length; l++) {
      const widget = widgets[l];
      
      let response;
      
      if (widget.title === widgetData.title) {
        widgets[l].isFilterChanged = true;

        switch (type) {
          case "date-time":
            if (value) {
              widgets[l].filterKey.dateTime = value;
            }
            break;
          case "reset":
            widgets[l].filterKey.dateTime = {};
            response = await handleApplyFilter(widgets[l], {});
            widgets[l].isFilterChanged = false;
            widgets[l].isOpenCustomFilter = false;
            widgets[l].value = response[response.key];
            widgets[l].filterBy = "";
            break;
          case "apply-filter":
            response = await handleApplyFilter(
              widgets[l],
              widgets[l].filterKey.dateTime
            );
            widgets[l].isFilterChanged = false;
            widgets[l].isOpenCustomFilter = false;
            widgets[l].value = response[response.key];
            widgets[l].filterBy = `${moment(
              widgets[l].filterKey.dateTime.from
            ).format("DD MMM YYYY")} - ${moment(
              widgets[l].filterKey.dateTime.to
            ).format("DD MMM YYYY")}`;
            break;
          default:
            break;
        }
      }
      finalWidgets.push(widgets[l]);
    }
    setWidgets(finalWidgets);
  };

  const handleOnClickWidgetFilterCustomDate = (widgetData) => {
    let finalWidgets = widgets;
    finalWidgets = finalWidgets.map((widget) => {
      if (widget.title === widgetData.title) {
        widget.isOpenCustomFilter = true;
      }
      return widget;
    });
    setWidgets(finalWidgets);
  };

  const handleOnCloseWidgetFilterCustomDate = (widgetData) => {
    let finalWidgets = widgets;
    finalWidgets = finalWidgets.map((widget) => {
      if (widget.title === widgetData.title) {
        widget.isOpenCustomFilter = false;
        widget.isFilterChanged = false;
      }
      return widget;
    });
    setWidgets(finalWidgets);
  };

  const handleApplyFilter = async (widgetData, objFilter) => {
    let key =
      widgetData && widgetData.title === "Total members"
        ? "total_members"
        : widgetData.title === "Total mentors"
        ? "total_mentors"
        : widgetData.title === "Total course posted"
        ? "total_courses"
        : widgetData.title === "Total webinar posted"
        ? "total_webinars"
        : widgetData.title === "Total bookings"
        ? "total_bookings"
        : widgetData.title === "Total booking topic"
        ? "total_topics"
        : "";

    const response = await fetchAllDashboardCountData({
      ...objFilter,
      key,
    });
    if (response && response.data) {
      const { data } = response;
      return { ...data, key };
    }
  };

  const handleOnClickFilterBy = async (widgetData, range) => {
    let finalWidgets = [];

    for (let l = 0; l < widgets.length; l++) {
      const widget = widgets[l];
      let response;
      if (widget.title === widgetData.title) {
        response = await handleApplyFilter(widgets[l], { range });
        widgets[l].isFilterChanged = false;
        widgets[l].isOpenCustomFilter = false;
        widgets[l].value = response[response.key];
        widgets[l].filterBy = range;
      }
      finalWidgets.push(widgets[l]);
    }

    setWidgets(finalWidgets);
  };

  useEffect(() => {
    getAllDashboardChartData();
    getAllDashboardCountData();
  }, []);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <div style={{ paddingLeft: smallScreen || mediumScreen ? "" : "290px" }}>
        <DashboardInnerContainer>
          <h1
            className="homePageTitle"
            style={{
              fontWeight: smallScreen || mediumScreen ? "bold" : "",
              color: "#00bb99",
              marginBottom: "1em",
            }}
          >
            Hello, welcome back!
          </h1>
          <CRow>
            {widgets && Array.isArray(widgets) && widgets.length > 0
              ? widgets.map((widget, idx) => {
                  return (
                    <Widget
                      key={idx}
                      widgetData={widget}
                      handleOnClickWidgetFilterCustomDate={
                        handleOnClickWidgetFilterCustomDate
                      }
                      handleOnCloseWidgetFilterCustomDate={
                        handleOnCloseWidgetFilterCustomDate
                      }
                      handleOnFilter={handleOnFilter}
                      handleOnClickFilterBy={handleOnClickFilterBy}
                    />
                  );
                })
              : null}
          </CRow>
          <CRow>
            <BarChart
              title={"Day to day session booked"}
              label={"Session Booked"}
              chartBarData={chartBarDataSessionBooked}
            ></BarChart>
            <BarChart
              title={"Day to day member registered"}
              label={"Member Registered"}
              chartBarData={chartBarDataMemberRegistered}
            ></BarChart>
          </CRow>
        </DashboardInnerContainer>
      </div>
    </div>
  );
};

export default InternalDashboard;

const DashboardInnerContainer = styled.div`
  padding: 80px 8vw 90px 8vw;
  text-align: left;
  @media ${device.tablet} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptop} {
    padding: 130px 3vw 90px 3vw;
  }
  @media ${device.laptopM} {
    padding: 130px 3vw 30px 3vw;
  }
  @media ${device.laptopL} {
    padding: 130px 3vw 30px 3vw;
  }
`;
