import React, { useEffect, useState } from "react";

import "../styles/mobileBottomNavBar.css";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Images & Icons
import dashboard_nav_icon from "../assets/icons/dashboard_nav_icon.png";
import topics_nav_icon from "../assets/icons/topics_nav_icon.png";
import users_nav_icon from "../assets/icons/users_nav_icon.png";
import mentors_nav_icon from "../assets/icons/mentors_nav_icon.png";
import my_booking_nav_icon from "../assets/icons/my_booking_nav_icon.svg";
import course_icon from "../assets/icons/course_icon.svg";
import home_nav_icon from "../assets/icons/home_nav_icon.svg";
import session_history_nav_icon from "../assets/icons/session_history_nav_icon.svg";
import home_nav_icon_white from "../assets/icons/home_nav_icon_white.svg";
import my_booking_nav_icon_white from "../assets/icons/my_booking_nav_icon_white.svg";
import session_history_nav_icon_white from "../assets/icons/session_history_nav_icon_white.svg";
import course_icon_white from "../assets/icons/course_icon_white.svg";
import subscription_nav_icon_white from "../assets/icons/subscription_nav_icon_white.svg";
import subscription_nav_icon from "../assets/icons/subscription_nav_icon.svg";

const MobileBottomNavigationBar = ({ userDetail }) => {
  const location = useLocation();

  const [tabs, setTabs] = useState([]);

  const handleSetMenus = (userDetail) => {
    const { role } = userDetail || {};

    if (role && role.id && +role.id !== 3) {
      setTabs([
        {
          route: "/internal/dashboard",
          icon: dashboard_nav_icon,
          label: "Dashboard",
        },
        {
          route: "/internal/users",
          icon: users_nav_icon,
          label: "Users",
        },
        {
          route: "/internal/bookings",
          icon: my_booking_nav_icon,
          label: "Bookings",
        },
        // {
        //   route: "/internal/mentors",
        //   icon: mentors_nav_icon,
        //   label: "Mentors",
        // },
        {
          route: "/internal/topics",
          icon: topics_nav_icon,
          label: "Topics",
        },
        {
          route: "/internal/courses",
          icon: course_icon,
          label: "Courses",
        },
      ]);
    } else {
      setTabs([
        {
          route: "/",
          icon: location.pathname === "/" ? home_nav_icon_white : home_nav_icon,
          label: "Home",
        },
        {
          route: "/webinars",
          icon: location.pathname.includes("/webinars")
            ? subscription_nav_icon_white
            : subscription_nav_icon,
          label: "Webinars",
        },
        {
          route: "/bookings",
          icon: location.pathname.includes("/bookings")
            ? my_booking_nav_icon_white
            : my_booking_nav_icon,
          label: "Booking",
        },
        {
          route: "/session",
          icon: location.pathname.includes("/session")
            ? session_history_nav_icon_white
            : session_history_nav_icon,
          label: "History",
        },
        {
          route: "/courses",
          icon: location.pathname.includes("/courses")
            ? course_icon_white
            : course_icon,
          label: "Courses",
        },
        // {
        //   route: "/internal/courses",
        //   icon: course_icon,
        //   label: "Courses",
        // },
      ]);
    }
  };

  useEffect(() => {
    if ((userDetail, location)) {
      handleSetMenus(userDetail);
    }
  }, [userDetail, location]);

  return (
    <div>
      {/* Bottom Tab Navigator*/}
      <nav
        className="navbar fixed-bottom navbar-light d-block d-xl-none bottom-tab-nav"
        role="navigation"
      >
        <Nav>
          <div
            // className="d-flex flex-row justify-content-center w-20"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginLeft: "1vw",
              marginRight: "1vw ",
            }}
          >
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  className="nav-link bottom-nav-link student-bottom-nav-link"
                  activeClassName="active"
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <img
                      src={tab.icon}
                      style={{
                        width: "45px",
                        height: "35px",
                        objectFit: "contain",
                      }}
                    />
                    <div className="bottom-tab-label">{tab.label}</div>
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default MobileBottomNavigationBar;
