import Axios from "../../helpers/axios";

export const createNewWebinarSchedule = (body) => {
  return Axios.post(`/webinars/create`, body);
};

export const updateWebinarSchedule = (body, webinar_id) => {
  return Axios.put(`/webinars/edit/${webinar_id}`, body);
};

export const deleteWebinarSchedule = (webinar_id) => {
  return Axios.delete(`/webinars/delete/${webinar_id}`);
};

export const uploadWebinarAttachments = (body, webinar_id) => {
  return Axios.patch(`/webinars/upload/attachments/${webinar_id}`, body);
};

export const sendWebinarCertificate = (body, webinar_id) => {
  return Axios.post(`/webinars/send/certificate/${webinar_id}`, body);
};
