import React from 'react'

function ArrowRightIcon({
   width = "9",
   height = "16",
   fill = "#444444"
}) {
   return (
      <svg
         width={width}
         height={height}
         viewBox="0 0 9 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M2.29904 7.47587e-05C2.46929 -0.000504107 2.63751 0.0370707 2.79133 0.110037C2.94515 0.183004 3.08067 0.289509 3.18793 0.421726L8.69217 7.2593C8.85979 7.46321 8.95142 7.71898 8.95142 7.98294C8.95142 8.2469 8.85979 8.50268 8.69217 8.70659L2.99419 15.5442C2.80076 15.7769 2.5228 15.9232 2.22146 15.951C1.92012 15.9788 1.62009 15.8857 1.38736 15.6923C1.15464 15.4989 1.00829 15.2209 0.980505 14.9196C0.952722 14.6182 1.04578 14.3182 1.23922 14.0855L6.33321 7.97724L1.41016 1.86901C1.2708 1.70174 1.18228 1.49804 1.15507 1.28204C1.12786 1.06603 1.16309 0.84674 1.2566 0.650129C1.35011 0.453516 1.49799 0.287807 1.68273 0.172605C1.86747 0.0574029 2.08134 -0.00246871 2.29904 7.47587e-05Z"
            fill={fill}
         />
      </svg>
   )
}

export default ArrowRightIcon